import React, { useState, useMemo, useCallback, useEffect } from "react";
import "./DiagnosticDrawer.scss";
import Button from "../../../../../atoms/Button/Button";
import Input from "../../../../../atoms/Input/Input";
import { SearchOutlined } from "@ant-design/icons";
import useApi from "../../../../../hooks/useApi";
import API from "../../../../../services/rest/api";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import moment from "moment";
import { toast } from "react-toastify";
import reportIcon from "../../../../../assets/icons/report.png";
import { useGoals } from "../../../../../hooks/useGoals";
import { useScoreProgress } from "../../../../../hooks/useScoreProgress";
import { Modal } from "antd";

interface RecordCard {
  id: string;
  name: string;
  uploadDate: string;
  imageUrl?: string;
  setNoActionDiagnostic: any;
  fetchGoalsHandler?: any;
}

const DiagnosticDrawer: React.FC<any> = ({
  patientId,
  setShowAssessmentSideDrawer,
  setNoActionDiagnostic,
  fetchGoalsHandler,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [records, setRecords] = useState<RecordCard[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [fileSizeBSOpen, setFileSizeBSOpen] = useState<boolean>(false);
  const [invalidReportBSOpen, setInvalidReportBSOpen] =
    useState<boolean>(false);
  const [selectedLabReport, setSelectedLabReport] = useState<File | null>(null);
  const [analysisError, setAnalysisError] = useState<boolean>(false);
  const [recordUploadSuccess, setRecordUploadSuccess] =
    useState<boolean>(false);
  const [isAnalyseReportLoading, setIsAnalyseReportLoading] =
    useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const {
    mutate: getRecords,
    isLoading,
    response: recordsResponse,
    error: recordsError,
  } = useApi(API.GetRecords);

  // const { fetchGoals: fetchGoalsHandler } = useGoals(
  //   "diagnostics",
  //   patientId || "",
  //   true
  // );
  const { getScoringHistoryData } = useScoreProgress();

  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");

  //   useEffect(() => {
  //     if (recordsResponse?.code?.toString() === "1") {
  //       setRecords(recordsResponse?.data || []);
  //     }
  //   }, [recordsResponse]);

  // Mock data - replace with actual data source
  //   const records: RecordCard[] = [
  //     { id: "1", name: "Prescription", uploadDate: "xx/xx/xxxx" },
  //     { id: "2", name: "Lab test", uploadDate: "xx/xx/xxxx" },
  //     { id: "3", name: "SSP", uploadDate: "xx/xx/xxxx" },
  //     { id: "4", name: "Record Name", uploadDate: "xx/xx/xxxx" },
  //     { id: "5", name: "Record Name", uploadDate: "xx/xx/xxxx" },
  //     { id: "6", name: "Record Name", uploadDate: "xx/xx/xxxx" },
  //     { id: "7", name: "Record Name", uploadDate: "xx/xx/xxxx" },
  //     { id: "8", name: "Record Name", uploadDate: "xx/xx/xxxx" },
  //   ];

  // Debounced search handler
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 500),
    []
  );

  useEffect(() => {
    getRecords({
      patient_id: patientId,
      page: page,
      per_page: 10,
      filter: "Lab Tests",
      search: searchQuery,
      meta_info: {
        isScoreCaptured: "false",
      },
    });
  }, [patientId, searchQuery, page]);

  useEffect(() => {
    if (recordsResponse?.code?.toString() === "1") {
      if (page === 1) {
        setRecords(recordsResponse?.data || []);
      } else {
        setRecords((prev) => [...prev, ...(recordsResponse?.data || [])]);
      }
      // Update hasMore based on whether we received fewer items than requested
      setHasMore((recordsResponse?.data || []).length === 10);
      setIsLoadingMore(false);
    } else if (recordsResponse?.code?.toString() === "2") {
      setIsLoadingMore(false);
      setRecords([]);
      toast.error("No records found");
    }
  }, [recordsResponse, page]);

  // Filter records based on search query
  const filteredRecords = useMemo(() => {
    return records.filter((record: any) =>
      record.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [records, searchQuery]);

  const onPressUpload = () => {
    fileInputRef.current?.click();
  };

  const onPressRetry = () => {
    setAnalysisError(false);
    setInvalidReportBSOpen(false);
    setRecordUploadSuccess(false);
    setSelectedLabReport(null);
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      fileInputRef?.current?.click();
    }, 50);
  };

  const onPressSaveSecurely = async (file: File) => {
    if (!file) return;
    setSelectedLabReport(file);
    setShowConfirmDialog(true);
  };

  const postAnalyseReport = async (file?: File | null, record?: any) => {
    try {
      setIsAnalyseReportLoading(true);
      const authToken = localStorage.getItem("token");

      const payload: FormData = new FormData();

      let fileData: any = {
        filename: `Lab Report ${moment().format("DDMMYYYYHHmmss")}`,
        disease: patientInfo?.disease || "",
        patientId: patientId,
        source: patientInfo?.patient_meta_info?.source || "",
      };
      if (!file && record) {
        fileData = {
          filename: record?.name,
          patientId: patientId,
          patientRecordId: record?.patient_records_id,
          disease: patientInfo?.disease || "",
          source: patientInfo?.patient_meta_info?.source || "",
          patientRecordUrl: record?.document_url?.[0],
        };
      }
      payload.append("file", file ?? "");
      payload.append("payload", JSON.stringify(fileData));
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }${"/api/v8/healthcoach/upload_diagnostic_report"}`,
        {
          method: "POST",
          headers: {
            token: `${authToken}`,
            health_secret: "SfUyfAztruqg92sbm30rEIyHLNV7f5",
          },
          body: payload,
        }
      );
      const data = await response.json();

      if (data?.code?.toString() === "1") {
        setNoActionDiagnostic(true);
        setRecordUploadSuccess(true);
        toast.success(
          data?.message || "Analysing the report. You will be notified shortly!"
        );
        setShowAssessmentSideDrawer(false);
        if (record?.record_meta_info?.analysedReportId) {
          fetchGoalsHandler();
          getScoringHistoryData();
        }
      } else if (data?.code?.toString() === "0") {
        toast.error(data?.message || "Report upload failed");
        setAnalysisError(true);
        setShowAssessmentSideDrawer(false);
      }
    } catch (error) {
      toast.error("Report upload failed. Please try again later!");
    } finally {
      setIsAnalyseReportLoading(false);
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (!file) return;

  //   // Create URL for preview
  //   const fileUrl = URL.createObjectURL(file);
  //   setUploadedFile(fileUrl);

  //   const formData = new FormData();
  //   formData.append("file", file);

  //   // fetch("/api/upload", {
  //   //   method: "POST",
  //   //   body: formData,
  //   // })
  //   //   .then((response) => response.json())
  //   //   .then((data) => {
  //   //     console.log("File uploaded successfully:", data);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("Error uploading file:", error);
  //   //   });
  // };

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (
        scrollHeight - scrollTop <= clientHeight * 1.5 &&
        !isLoadingMore &&
        hasMore
      ) {
        setIsLoadingMore(true);
        setPage((prev) => prev + 1);
      }
    },
    [hasMore, isLoadingMore]
  );

  const onClickFileSizeCloseBS = () => {
    setInvalidReportBSOpen(false);
  };

  const onClickInvalidReportCloseBS = () => {
    setInvalidReportBSOpen(false);
  };

  const handleAnalysis = (record: any) => {
    setSelectedRecord(record);
    setShowConfirmDialog(true);
  };

  const handleConfirmAnalysis = () => {
    if (selectedRecord || selectedLabReport) {
      selectedLabReport
        ? postAnalyseReport(selectedLabReport)
        : postAnalyseReport(null, selectedRecord);
      setShowConfirmDialog(false);
    }
  };

  return (
    <div className="diagnostic-drawer">
      {!recordUploadSuccess ? (
        !selectedLabReport ? (
          <>
            <Button
              variant="primary"
              className="upload-button"
              onClick={onPressUpload}
            >
              Upload New File
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(event) => {
                if (!event?.target?.files?.[0]) {
                  return;
                }

                //File Size check - should not exceed 10MBs
                if (
                  (event?.target?.files?.[0]?.size ?? 0) / (1024 * 1024) >
                  10
                ) {
                  toast.warn(
                    "The file size exceeded 10MB. Please upload a file smaller than 10MB"
                  );
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                  return;
                } else {
                  setSelectedLabReport(event?.target?.files?.[0]);
                  onPressSaveSecurely(event?.target?.files?.[0]);
                }
              }}
              accept="application/pdf"
              style={{ display: "none" }}
            />
          </>
        ) : (
          <div className="uploaded-preview">
            <div className="pdf-preview">
              <img
                src={reportIcon}
                alt="PDF Icon"
                style={{ width: "48px", cursor: "pointer" }}
                onClick={() =>
                  window.open(URL.createObjectURL(selectedLabReport), "_blank")
                }
              />
              <div className="pdf-details">
                <div className="pdf-name">{selectedLabReport.name}</div>
                {/* <div className="pdf-meta">
                  {(selectedLabReport.size / (1024 * 1024)).toFixed(2)} MB •
                  {new Date(
                    selectedLabReport.lastModified
                  ).toLocaleDateString()}
                </div> */}
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <BottomSheet
            open={invalidReportBSOpen}
            onDismiss={() => setInvalidReportBSOpen(false)}
          >
            <div>
              This does not seem like a Lab Test Report
              <div onClick={onClickInvalidReportCloseBS}>X</div>
            </div>
            <div>
              The document you uploaded is not a lab report. Please upload an
              appropriate report
            </div>
            <Button variant="primary" disabled={false} onClick={onPressRetry} />
          </BottomSheet>
          <BottomSheet
            open={fileSizeBSOpen}
            onDismiss={() => setFileSizeBSOpen(false)}
          >
            <div>
              Exceeded File Size
              <div onClick={onClickFileSizeCloseBS}>X</div>
            </div>
            The file size exceeded 10MB. Please upload a file smaller than 10MB
            <Button
              variant="primary"
              disabled={false}
              onClick={() => {
                setSelectedLabReport(null);
                setFileSizeBSOpen(false);
                setAnalysisError(false);
                setTimeout(() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                  fileInputRef?.current?.click();
                }, 50);
              }}
            />
          </BottomSheet>
        </>
      )}

      {isAnalyseReportLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <div className="loading-text">Uploading...</div>
        </div>
      )}

      <div className="divider">OR</div>

      <div className="search-section">
        <Input
          type="text"
          name="search"
          id="search"
          placeholder="Search by file name"
          onChange={(e: any) => debouncedSearch(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="records-grid" onScroll={handleScroll}>
        {filteredRecords.map((record: any) => {
          const isInvalidReport =
            record?.record_meta_info?.error === "Invalid report";
          return (
            <div
              key={record?.patient_records_id?.toString()}
              className={`record-card ${isInvalidReport ? "disabled" : ""}`}
            >
              <div
                className="record-content"
                onClick={() => {
                  if (!isInvalidReport) {
                    handleAnalysis(record);
                  }
                }}
              >
                <div className="image-placeholder">
                  <img src={reportIcon} alt="" />
                </div>
                <div className="record-info">
                  <div className="record-name">{record?.name}</div>
                  <div className="upload-date">
                    Report Created On{" "}
                    {moment(record?.created_at).format("DD-MM-YYYY")}
                  </div>
                  {isInvalidReport && (
                    <div className="error-message">Invalid report</div>
                  )}
                </div>
              </div>
              <div
                className="download-button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (record?.document_url?.[0]) {
                    window.open(record.document_url[0], "_blank");
                  }
                }}
              >
                Download
              </div>
            </div>
          );
        })}
        {!isLoadingMore && !filteredRecords?.length && (
          <div className="no-records-message">No Lab Records</div>
        )}
        {isLoadingMore && <div className="loading">Loading Lab Reports...</div>}
      </div>

      <Modal
        title="Confirm Report Selection"
        open={showConfirmDialog}
        onCancel={() => setShowConfirmDialog(false)}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "16px",
            }}
          >
            <Button
              key="cancel"
              variant="secondary"
              onClick={() => {
                setSelectedLabReport(null);
                setSelectedRecord(null);
                setShowConfirmDialog(false);
              }}
            >
              No
            </Button>
            <Button
              key="submit"
              variant="primary"
              onClick={handleConfirmAnalysis}
            >
              Yes
            </Button>
          </div>,
        ]}
        centered
      >
        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
          Please check the following information before selecting the report
        </p>
        {selectedRecord && (
          <div>
            <p>
              <strong>File Name:</strong> {selectedRecord.name}
            </p>
            <p>
              <strong>File Upload Date:</strong>{" "}
              {moment(selectedRecord.created_at).format("DD-MM-YYYY")}
            </p>
            <p>
              <strong>Report Status:</strong>{" "}
              {selectedRecord?.record_meta_info?.error === "Invalid report"
                ? "Invalid Report"
                : "Valid Report"}
            </p>
          </div>
        )}
        {selectedLabReport && (
          <div>
            <p>
              <strong>File Name:</strong> {selectedLabReport?.name}
            </p>
          </div>
        )}
        Kindly note that this report will affect the metabolic score of the
        user.{" "}
        <span style={{ fontWeight: "bold" }}>This can not be undone.</span>
      </Modal>
    </div>
  );
};

// Debounce utility function
function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export default DiagnosticDrawer;
