import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Assessment, Goal, SubGoal } from '../../types/goal';
import moment from 'moment';

interface GoalState {
  data: {
    mainGoal: {
      goals: { [key: string]: Goal };
      assessments: { [key: string]: Assessment };
      totalAssessment: number;
      assessmentTrend: any[];
      // previewAssessment?: Assessment;
    };
    customGoals: {
      goals: { [key: string]: Goal };
      assessments: { [key: string]: Assessment };
      totalAssessment: number;
      assessmentTrend: any[];
      // previewAssessment?: Assessment;
    };
  };
  preAssessments: any[];
}

const initialState: GoalState = {
  data: {
    mainGoal: {
      goals: {},
      assessments: {},
      totalAssessment: 0,
      assessmentTrend: []
    },
    customGoals: {
      goals: {},
      assessments: {},
      totalAssessment: 0,
      assessmentTrend: []
    }
  },
  preAssessments: []
};

const goalSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    addAssessment: (state, action) => {
      const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
      let goals = JSON.parse(JSON.stringify(state.data[goalType].goals));
      
      // Find max assessment ID
      let maxId = Object.keys(state.data[goalType].assessments)
        .map(id => parseInt(id.split('_')[1]))
        .reduce((max, current) => Math.max(max, current), 0);
      if(!maxId) {
        maxId = 0;
      }
      const newId = `'assessment'_${maxId + 1}`;

      const mostRecentAssessment = Object.values(state.data[goalType].assessments)
        .find(assessment => !assessment.isNew && assessment?.action?.toLowerCase() !== 'skipped');

      
      const newAssessment: Assessment = {
        id: newId,
        isNew: true,
        name: 'New Assessment',
      };
      // Create assessment trend structure
      const assessmentTrend = Object.keys(goals).map(goalId => {
        const goal = goals[goalId];
        return {
          goal_id: goalId,
          subGoals: goal.subGoals?.map((subGoal: SubGoal) => ({
            sub_goal_id: subGoal.id,
            [`${newId}`]: {
              value: mostRecentAssessment && action.payload.isPrimary  ? (subGoal[mostRecentAssessment.id] as any)?.value : '',
              isNew: true,
              delta: {
                type: '',
              }
            }
          })) || []
        };
      });

      // Update assessment trend in state
      state.data[goalType].assessmentTrend = assessmentTrend;

      // Rest of the existing addAssessment logic
      state.data[goalType].assessments = {
        [newId]: newAssessment,
        ...Object.fromEntries(
          Object.entries(state.data[goalType].assessments).map(([id, assessment]) => [
            id,
            { ...assessment, isNew: false }
          ])
        )
      };

      Object.keys(goals).forEach(goalId => {
        const goal = goals[goalId];
        if (goal.subGoals) {
          const updatedSubGoals = goal.subGoals.map((subGoal: SubGoal) => ({
            ...subGoal,
            [newId]: { value: mostRecentAssessment && action.payload.type !== 'diagnostics'  ? (subGoal[mostRecentAssessment.id] as any)?.value : null, date: '' }
          }));
          
          state.data[goalType].goals[goalId] = {
            ...goal,
            // status: 'pending',
            subGoals: updatedSubGoals,
            assessmentIds: [newId, ...(goal.assessmentIds || [])]
          };
        } else {
          state.data[goalType].goals[goalId] = {
            ...goal,
            // status: 'pending',
            assessmentIds: [newId, ...(goal.assessmentIds || [])]
          };
        }
      });
      state.data[goalType].totalAssessment = state.data[goalType].totalAssessment + 1;
    },
    totalAssessmentCount: (state, action: PayloadAction<{count: number, isPrimary: boolean}>) => {
        const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
        state.data[goalType].totalAssessment = action.payload.count;
    },
    updateSubGoalAssessment: (state, action: PayloadAction<{
      value: string;
      goalId: string;
      subGoalId: string;
      assessmentId: string;
      isPrimary: boolean;
    }>) => {
      const { value, goalId, subGoalId, assessmentId, isPrimary } = action.payload;
      const goalType = isPrimary ? 'mainGoal' : 'customGoals';
      const goal = state.data[goalType].goals[goalId];
      
      if (goal && goal.subGoals) {
        // Find current value from assessmentTrend
        const goalTrend = state.data[goalType].assessmentTrend.find(g => g.goal_id === goalId);
        const subGoalTrend = goalTrend?.subGoals.find((sg: { sub_goal_id: string | number }) => 
          sg.sub_goal_id.toString() === subGoalId.toString()
        );
        const currentValue = subGoalTrend?.[`${assessmentId}`]?.value;

        const updatedSubGoals = goal.subGoals.map(subGoal => {
          if (subGoal.id.toString() === subGoalId) {
            const currentValueIndex = Array.isArray(subGoal.answers?.options) 
              ? subGoal.answers.options.indexOf(currentValue)
              : -1;
            // Find new value index
            const newValueIndex = Array.isArray(subGoal.answers?.options)
              ? subGoal.answers.options.indexOf(value)
              : -1;
            
            // Determine trend
            const trend = newValueIndex !== -1 && currentValueIndex !== -1 ? newValueIndex === currentValueIndex ? ''
              : newValueIndex < currentValueIndex ? '+' : '-'
              : undefined;
            
            return {
              ...subGoal,
              [assessmentId]: {
                value: value,
                date: '',
                delta: {
                  type: trend,
                }
              }
            };
          }
          return subGoal;
        });
        
        state.data[goalType].goals[goalId] = {
          ...goal,
          subGoals: updatedSubGoals as any
        };
      }
    },
    cancelAssessment: (state, action: PayloadAction<{ isPrimary: boolean }>) => {
      const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
      // Find assessment to be removed
      const assessmentToRemove = Object.values(state.data[goalType].assessments)
        .find(assessment => assessment.isNew)?.id;

      // Remove assessment
      state.data[goalType].assessments = Object.fromEntries(
        Object.entries(state.data[goalType].assessments)
          .filter(([_, assessment]) => !assessment.isNew)
      );

      // Remove assessment reference and data from goals
      if (assessmentToRemove) {
        Object.keys(state.data[goalType].goals).forEach(goalId => {
          const goal = state.data[goalType].goals[goalId];
          
          // Update subgoals to remove assessment data
          if (goal.subGoals) {
            const updatedSubGoals = goal.subGoals.map(subGoal => {
              const { [assessmentToRemove]: removed, ...rest } = subGoal;
              return rest;
            });
            
            state.data[goalType].goals[goalId] = {
              ...goal,
              subGoals: updatedSubGoals as any,
              // status: 'pending',
              assessmentIds: goal.assessmentIds?.filter(id => id !== assessmentToRemove)
            };
          } else {
            state.data[goalType].goals[goalId] = {
              ...goal,
              // status: 'pending',
              assessmentIds: goal.assessmentIds?.filter(id => id !== assessmentToRemove)
            };
          }
        });
      }
      state.data[goalType].totalAssessment = state.data[goalType].totalAssessment - 1;
    },
    markAsSkip: (state, action: PayloadAction<{
      assessmentId: string;
      isPrimary: boolean;
    }>) => {
      const { assessmentId, isPrimary } = action.payload;
      const goalType = isPrimary ? 'mainGoal' : 'customGoals';
      
      // Update the assessment state
      if (state.data[goalType].assessments[assessmentId]?.isNew) {
        state.data[goalType].assessments[assessmentId] = {
          ...state.data[goalType].assessments[assessmentId],
          isSkip: true
        };
      }
      
      // Update goals and subgoals (existing logic)
      Object.keys(state.data[goalType].goals).forEach(goalId => {
        const goal = state.data[goalType].goals[goalId];
        if (goal.subGoals) {
          const updatedSubGoals = goal.subGoals.map(subGoal => ({
            ...subGoal,
            [assessmentId]: { value: '', date: '', isSkip: true, delta: { type: '' } }
          }));
          
          state.data[goalType].goals[goalId] = {
            ...goal,
            subGoals: updatedSubGoals as any
          };
        }
      });
      state.data[goalType].assessments[assessmentId] = {
        ...state.data[goalType].assessments[assessmentId],
        date: moment(new Date()).format("YYYY-MM-DD")
      };
    },
    markAsComplete: (state, action: PayloadAction<{
      goalId: string;
      isPrimary: boolean;
    }>) => {
      const { goalId, isPrimary } = action.payload;
      const goalType = isPrimary ? 'mainGoal' : 'customGoals';
      const goal = state.data[goalType].goals[goalId];
      // Find the new assessment
      const newAssessment = Object.values(state.data[goalType].assessments)
        .find(assessment => assessment.isNew);
      if (goal.subGoals && newAssessment) {
        const goalTrend = state.data[goalType].assessmentTrend.find(g => g.goal_id.toString() === goalId.toString());
        const updatedSubGoals = goal.subGoals.map(subGoal => {
          const options = subGoal.answers.options;
          const subGoalTrend = goalTrend?.subGoals.find((sg: { sub_goal_id: string | number }) => 
            sg.sub_goal_id.toString() === subGoal.id.toString()
          );
          const currentValue = subGoalTrend?.[`${newAssessment.id}`]?.value;
          const currentValueIndex = options.indexOf(currentValue);
          return (
            {
            ...subGoal,
            [newAssessment.id]: { 
              value: Array.isArray(subGoal.answers.options) ? subGoal.answers.options[0] || '' : '', 
              date: '',
              delta: {
                type: currentValueIndex == 0 ? '' : '+',
              }
            }
          }
        )
        });
        
        state.data[goalType].goals[goalId] = {
          ...goal,
          status: 'completed',
          subGoals: updatedSubGoals as any
        };
      } else {
        state.data[goalType].goals[goalId].status = 'completed';
      }
    },
    publishAssessment: (state, action: PayloadAction<{ isPrimary: boolean, activeTab: string, patientId: string }>) => {
      const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
      // Find the assessment marked as new
      const newAssessment = Object.values(state.data[goalType].assessments)
        .find(assessment => assessment.isNew);
      
      if (newAssessment) {

        state.data[goalType].assessmentTrend = []
        // Update the assessment to mark it as not new
        state.data[goalType].assessments[newAssessment.id] = {
          ...newAssessment,
          isNew: false,
          date: moment(new Date()).format("YYYY-MM-DD")
        };
      }
    },
    addGoal: (state, action: PayloadAction<{
      name: string;
      type: string;
      tag: string;
      role: string;
      isPrimary: boolean;
    }>) => {
      const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
      const newId = `goal-${Object.keys(state.data[goalType].goals).length + 1}`;
      const newGoal: Goal = {
        id: newId,
        name: action.payload.name,
        type: action.payload.type,
        status: 'pending',
        isPrimary: 0,
        assessmentIds: [],
        tag: action.payload.tag,
        role: action.payload.role,
      };

      state.data[goalType].goals[newId] = newGoal;
    },
    removeGoal: (state, action: PayloadAction<{ goalId: string; isPrimary: boolean }>) => {
      const { goalId, isPrimary } = action.payload;
      const goalType = isPrimary ? 'mainGoal' : 'customGoals';
      delete state.data[goalType].goals[goalId];
    },
    addGoals: (state, action: PayloadAction<{
      goals: { [key: string]: Goal };
      assessments: { [key: string]: Assessment };
      isPrimary: boolean;
    }>) => {
      const goalType = action.payload.isPrimary ? 'mainGoal' : 'customGoals';
      state.data[goalType].goals = action.payload.goals;
      state.data[goalType].assessments = action.payload.assessments;
    },
    setPreAssessments: (state, action: PayloadAction<{
      preAssessments: any[];
    }>) => {
      
      state.preAssessments = action.payload.preAssessments;
    },
  },
});

export const { addAssessment, markAsSkip, markAsComplete, publishAssessment,  cancelAssessment, addGoal, removeGoal, addGoals, updateSubGoalAssessment, totalAssessmentCount, setPreAssessments } = goalSlice.actions;
export default goalSlice.reducer;