import React from "react";
import ScoreProgress from "../../../molecules/Progress/ScoreProgress/ScoreProgress";
import ProgressTabs from "../../../molecules/Progress/ProgressTabs/ProgressTabs";
import { useLocation } from "react-router-dom";

const tabs = ["Exercise", "Diet", "Success", "Diagnostics"];

const MainGoalsPage = () => {
  return (
    <div>
      <ScoreProgress />
      {/* <AssessmentGoal tabs={tabs} /> */}
      <ProgressTabs tabs={tabs} isPrimary={true} />
    </div>
  );
};

export default MainGoalsPage;
