import React from "react";

import RedirectComponent from "../../../../atoms/RedirectComponent/RedirectComponent";

const IncentiveSpirometerPage: React.FC<any> = () => {
  return (
    <div>
      <RedirectComponent moduleName="spirometer" />
    </div>
  );
};

export default IncentiveSpirometerPage;
