// src/store/slices/healthCoachSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { healthCoach } from "../../types/index";

// Initial state for the health coach slice
const initialState: healthCoach.HealthCoachStateType = {
  healthCoachDetails: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

// Define the health coach slice
const healthCoachSlice = createSlice({
  name: "healthCoach",
  initialState,
  reducers: {
    getHealthCoachDetailsRequest: (
      state: healthCoach.HealthCoachStateType,
      action: PayloadAction<any>
    ) => {
      state.healthCoachDetails.isLoading = true;
      state.healthCoachDetails.data = null;
      state.healthCoachDetails.errors = "";
    },
    getHealthCoachDetailsSuccess: (
      state: healthCoach.HealthCoachStateType,
      action: PayloadAction<any>
    ) => {
      state.healthCoachDetails.isLoading = false;
      state.healthCoachDetails.data = action.payload.data;
      state.healthCoachDetails.errors = null;
    },
    getHealthCoachDetailsError: (
      state: healthCoach.HealthCoachStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.healthCoachDetails.isLoading = false;
      state.healthCoachDetails.data = null;
      state.healthCoachDetails.errors = error;
    },
    resetHealthCoachDetails: (state: healthCoach.HealthCoachStateType) => {
      state.healthCoachDetails.isLoading = false;
      state.healthCoachDetails.data = null;
      state.healthCoachDetails.errors = null;
    },
  },
});

// Export actions and reducer
export const {
  getHealthCoachDetailsRequest,
  getHealthCoachDetailsSuccess,
  getHealthCoachDetailsError,
  resetHealthCoachDetails,
} = healthCoachSlice.actions;

export default healthCoachSlice.reducer;
