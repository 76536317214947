import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/PatientSlice";

import { constants } from "../../constants/common";
import { common } from "../../types";

import API from "../../services/rest/api";

const GetPatientDetailsRequest = function* GetPatientDetailsRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      API.GetPatientDetails,
      payload
    );
    if(Number(response.code) === 1) {
      yield put(actions.patientDetailsSuccess(response));
      resolve(response);
    } else if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(
      actions.getPatientDetailsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    // reject(error);
  }
};

export default function* patientSaga() {
  yield all([
    takeEvery(actions.getPatientDetailsRequest, GetPatientDetailsRequest),
  ]);
}
