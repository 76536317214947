import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

import goalReducer from "./slices/GoalSlice";
import patientReducer from "./slices/PatientSlice";
import healthCoachReducer from "./slices/HealthCoachSlice";
import dietReducer from "./slices/DietSlice";
import assessmentReducer from "./slices/AssessmentSlice";
import scoringReducer from "./slices/scoringSlice";
// Initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    goals: goalReducer,
    patient: patientReducer,
    healthCoach: healthCoachReducer,
    diet: dietReducer,
    assessment: assessmentReducer,
    scoring: scoringReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disable redux-thunk, since we are using redux-saga
      immutableCheck: process.env.NODE_ENV !== "production", // Disable immutable checks in production
      serializableCheck: process.env.NODE_ENV !== "production", // Disable serializability checks in production
    }).concat(sagaMiddleware), // Add saga middleware
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
