import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addGoals, totalAssessmentCount } from '../store/slices/GoalSlice';
import { Assessment, Goal, SubGoal } from '../types/goal';
import useApi from './useApi';
import API from '../services/rest/api';
import processGoalData from '../helpers/goal';
import { toast } from 'react-toastify';
import { RootState } from '../store/store';

 
export const useGoals = (activeTab: string, patientId: string, isPrimary: boolean) => {
  const dispatch = useDispatch();


  const { goals } = useSelector(
    (state: RootState) =>
      state.goals.data[isPrimary ? "mainGoal" : "customGoals"]
  );

  const {
    mutate: fetchAssessmentGoals,
    response: assessmentGoalsResponse,
    error: assessmentGoalsError,
    isLoading: isAssessmentGoalsLoading
  } = useApi(API.getAssessmentGoals)

 
useEffect(() => {
  
  if(assessmentGoalsResponse?.code?.toString() === "1") {
    const processedData = processGoalData(
      assessmentGoalsResponse?.data as any[], 
      isPrimary
    );
    
    dispatch(addGoals({
      goals: processedData.data[isPrimary ? "mainGoal" : "customGoals"].goals,
      assessments: processedData.data[isPrimary ? "mainGoal" : "customGoals"].assessments,
      isPrimary: isPrimary || false
    }));
    dispatch(totalAssessmentCount({
      count: assessmentGoalsResponse?.total_data,
      isPrimary: isPrimary || false
    }));
  } else if(assessmentGoalsResponse?.code?.toString() === "0") {
    toast.error(assessmentGoalsResponse?.message || "Failed to fetch goals");
  }

}, [assessmentGoalsResponse, assessmentGoalsError]);
 
  useEffect(() => { 
    if(patientId && activeTab) {
      // if(Object.keys(goals).length === 0) {
        fetchGoals();
      // }
    }
  }, [dispatch, activeTab, patientId, isPrimary]);


  const fetchGoals = async () => {
    const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");
    const payload = {
      "page": 1,
      "per_page": 2,
      "tag": activeTab,
      "disease": patientInfo?.disease || "",
      "patient_id": patientId,
      is_primary: isPrimary ? 1 : 0
    }
    try {
      fetchAssessmentGoals(payload);
    } catch (error) {
      console.error('Error fetching goals:', error);
    }
  };

  return { isLoading: isAssessmentGoalsLoading, fetchGoals };
};