import React, { useEffect, useState } from "react";
import "./RedirectComponent.scss";

import Button from "../Button/Button";

interface RedirectProps {
  moduleName:
    | "exercise"
    | "sleep"
    | "water"
    | "vitals" //  "vitals" - marker history
    | "steps"
    | "spirometer";
}

const RedirectComponent: React.FC<RedirectProps> = ({ moduleName }) => {
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      redirect();
    }, 5000);

    return () => {
      clearInterval(countdown);
      clearTimeout(timeout);
    };
  }, []);

  const onRetry = () => redirect();

  const redirect = () => {
    window.parent.postMessage(
      {
        messageType: `REDIRECT_TO_${moduleName.toUpperCase()}`,
      },
      "*"
    );
  };

  return (
    <div className="redirect-component">
      <div className="banner-text">
        You are being redirected to {moduleName === "vitals" ? `Vitals/Marker History` : moduleName} Module in {timer} seconds.
      </div>
      <Button variant="outlined" onClick={onRetry}>
        Retry
      </Button>
    </div>
  );
};

export default RedirectComponent;
