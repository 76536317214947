import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactSelectWrapper from "../../../../atoms/Select/ReactSelect/ReactSelectWrapper";
import DateRangePicker from "../../../../atoms/DatePicker/DateRangePicker";
import PatientReport from "../../../PatientReport/PatientReport";

import { RootState } from "../../../../store/store";
import { getDietPlansRequest } from "../../../../store/slices/DietSlice";

import "./DietReportPage.scss";

const DietReportPage: React.FC<any> = () => {
  const { dietPlans } = useSelector((state: RootState) => state.diet);
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const { healthCoachDetails } = useSelector(
    (state: RootState) => state.healthCoach
  );
  const dispatch = useDispatch();

  const [dietPlansList, setDietPlansList] = useState<any[]>([]);
  const [selectedDietPlan, setSelectedDietPlan] = useState<any>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<any>(null);

  useEffect(() => {
    if (patientDetails?.data?.patient_id) {
      getDietPlans();
    }
  }, [patientDetails]);

  useEffect(() => {
    if (dietPlans?.data) {
      let dietPlansList = dietPlans?.data
        .filter((d: any) => d.status === "approved")
        .map((d: any) => ({
          ...d,
          label: d?.plan_name,
          value: d?.diet_plans_id,
        }));

      setDietPlansList(dietPlansList);
    }
  }, [dietPlans]);

  const getDietPlans = () => {
    const payload = {
      // diet_type: dietType,
      patient_id: patientDetails?.data?.patient_id,
    };

    new Promise((resolve, reject) => {
      dispatch(
        getDietPlansRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  const handleChange = (selectedDietPlan: any) => {
    console.log(selectedDietPlan);
    setSelectedDietPlan(selectedDietPlan);
    setSelectedDateRange({
      start_date: selectedDietPlan?.start_date,
      end_date: selectedDietPlan?.end_date,
    });
  };

  return (
    <div className="diet-reports-page-container">
      <h2 className="page-title">Generate Diet report</h2>

      <div className="generate-report-wrapper">
        <ReactSelectWrapper
          label="Diet plans"
          options={dietPlansList}
          onChange={handleChange}
          placeholder="Select Diet Plan"
          value={selectedDietPlan}
        />

        <DateRangePicker label="Select Date Range" value={selectedDateRange} />

        {/* <Button
          variant="primary"
          onClick={() => console.log("Generate")}
          fullWidth
        >
          Generate
        </Button> */}
      </div>

      <div className="reports-container">
        <PatientReport
          patient_id={patientDetails?.data?.patient_id}
          health_coach_id={healthCoachDetails?.data?.health_coach_id}
          userInfo={healthCoachDetails?.data}
          reportPeiodDates={selectedDateRange}
          selectedDietPlan={selectedDietPlan}
        />
      </div>
    </div>
  );
};

export default DietReportPage;
