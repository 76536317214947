import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/DietSlice";

import { constants } from "../../constants/common";
import { common } from "../../types";

import API from "../../services/rest/api";

const GetDietPlansRequest = function* GetDietPlansRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      API.GetDietPlan,
      payload
    );
    if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else if (Number(response.code) === 0) {
      throw new Error(response.message);
    }
    yield put(actions.getDietPlansSuccess(response));
    resolve(response);
  } catch (error) {
    yield put(
      actions.getDietPlansError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    reject(error);
  }
};

export default function* dietSaga() {
  yield all([takeEvery(actions.getDietPlansRequest, GetDietPlansRequest)]);
}
