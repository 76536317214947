import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Score {
  profile: number;
  metabolism: number;
  wellness: number;
  habit: number;
}

interface UpdatedBy {
  health_coach_id: string;
  health_coach_name: string;
  health_coach_email: string;
  health_coach_role: string;
}

interface ProgressCard {
  assessment_id: number;
  score: Score;
  updated_at: string;
  updated_by: UpdatedBy;
}

interface ScoringState {
  scoringHistory: ProgressCard[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalScores: number;
}

const initialState: ScoringState = {
  scoringHistory: [],
  totalScores: 0,
  isLoading: false,
  error: null,
  currentPage: 1,
};

const scoringSlice = createSlice({
  name: 'scoring',
  initialState,
  reducers: {
    setScoring: (state, action: PayloadAction<any>) => {
      const page = action?.payload?.page;
      const newScores = action?.payload?.data?.scoreInfo;
      
      if (page === 1) {
        // Reset scoring history for first page
        state.scoringHistory = newScores;
      } else {
        // Append new scores for subsequent pages
        const existingIds = new Set(state.scoringHistory.map(score => score.assessment_id));
        const filteredNewScores = newScores?.filter((score: ProgressCard) => !existingIds.has(score.assessment_id));
        state.scoringHistory = [...state.scoringHistory, ...filteredNewScores];
      }
      
      state.totalScores = action?.payload?.data?.totalRecords;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetScoring: (state) => {
      state.scoringHistory = [];
      state.currentPage = 1;
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  setScoring,
  setLoading,
  resetScoring,
} = scoringSlice.actions;

export default scoringSlice.reducer; 