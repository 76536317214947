import { useCallback, useState } from "react";
import "./ProgressTabs.scss";
import Physio from "../../../organisms/Progress/Physio/Physio";
import Nutritionist from "../../../organisms/Progress/Nutritionist/Nutritionist";
import SuccessCoach from "../../../organisms/Progress/SuccessCoach/SuccessCoach";
import Diagnostics from "../../../organisms/Progress/Diagnostics/Diagnostics";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface ProgressTabsProps {
  tabs: string[];
  isPrimary: boolean;
}

export const ProgressTabs = ({ tabs, isPrimary }: ProgressTabsProps) => {
  const [activeTab, setActiveTab] = useState("Exercise"); // Add state management
  // const userInfo = localStorage.getItem("userInfo");
  const { healthCoachDetails } = useSelector(
    (state: RootState) => state.healthCoach
  );
  // Add click handler
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const isEditMode = useCallback(
    (type: string) => {
      // const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
      if (
        healthCoachDetails?.data?.chief_hc === "Y" ||
        healthCoachDetails?.data?.role?.toLowerCase() === type.toLowerCase()
      ) {
        return "edit";
      }
      return "view";
    },
    [healthCoachDetails?.data?.chief_hc, healthCoachDetails?.data?.role]
  );

  const renderActiveComponent = useCallback(() => {
    switch (activeTab) {
      case "Exercise":
        return (
          <Physio mode={isEditMode("Physiotherapist")} isPrimary={isPrimary} />
        );
      case "Diet":
        return (
          <Nutritionist
            mode={isEditMode("Nutritionist")}
            isPrimary={isPrimary}
          />
        );
      case "Success":
        return (
          <SuccessCoach
            mode={isEditMode("Psychologist")}
            isPrimary={isPrimary}
          />
        );
      case "Diagnostics":
        return <Diagnostics mode={"edit"} isPrimary={isPrimary} />;
      default:
        return null;
    }
  }, [
    activeTab,
    healthCoachDetails?.data?.chief_hc,
    healthCoachDetails?.data?.role,
  ]);

  return (
    <>
      <div className="sticky-tabs-container">
        <div className="category-tabs">
          {tabs.map((tab: string) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="tab-content">{renderActiveComponent()}</div>
    </>
  );
};

export default ProgressTabs;
