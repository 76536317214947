import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import "./DietPlansList.scss";
import { useDispatch, useSelector } from "react-redux";

import Select from "../../../atoms/Select/Select";
import Button from "../../../atoms/Button/Button";
import Badge from "../../../atoms/Badge/Badge";
import DietPlanPdf from "../../DietPlanPdf/DietPlanPdf";
import { Modal, Tooltip } from "antd";

import {
  DIET_STATUS,
  DIET_STATUS_DROPDOWN,
  DIET_STATUS_DROPDOWN_DEFAULT,
} from "../../../constants/diet";

import { DietPlan } from "../../../types/diet";
import { getDietPlansRequest } from "../../../store/slices/DietSlice";
import { formatDateTime } from "../../../utils/date-and-time";
import { RootState } from "../../../store/store";
import { AiOutlineCopy } from "react-icons/ai";
import { RiPencilFill } from "react-icons/ri";

interface Props {
  handleAddDietClick: () => void;
  handleEditDietClick: (dietPlan: DietPlan) => void;
  handleCopyDietClick: (dietPlan: DietPlan) => void;
}

const DietPlansList = forwardRef<any, Props>(({ handleAddDietClick, handleEditDietClick, handleCopyDietClick }, ref) => {
  const { dietPlans } = useSelector((state: RootState) => state.diet);
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const { healthCoachDetails } = useSelector(
    (state: RootState) => state.healthCoach
  );
  const dispatch = useDispatch();
  const [dietType, setDiettype] = useState(DIET_STATUS_DROPDOWN_DEFAULT);

  const [dietPlansVisible, setDietPlansVisible] = useState<any>([]);
  const [showViewAllBtn, setShowViewAllBtn] = useState(false);
  const [toggleAllPlans, setToggleAllPlans] = useState(false);
  const [selectedDietData, setSelectedDietData] = useState<any>(null);
  const [showDietPdfModal, setShowDietPdfModal] = useState(false);

  const handleChange = (value: any) => {
    setDiettype(value);
    setToggleAllPlans(false);
  };

  useEffect(() => {
    if (dietType && patientDetails?.data?.patient_id) {
      getDietPlans();
    }
  }, [dietType, patientDetails]);

  useEffect(() => {
    if (dietPlans?.data) {
      setDietPlansVisible(dietPlans?.data ? dietPlans.data.slice(0, 2) : []);
      setShowViewAllBtn(dietPlans?.data?.length > 2);
    }
  }, [dietPlans?.data]);

  const getDietPlans = () => {
    const payload = {
      diet_type: dietType,
      patient_id: patientDetails?.data?.patient_id,
    };

    new Promise((resolve, reject) => {
      dispatch(
        getDietPlansRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  useImperativeHandle(ref, () => ({
    refresh: getDietPlans,
  }));

  const getStatus = (status: string | null) => {
    switch (status) {
      case DIET_STATUS.PENDING:
        return "Pending";
      case DIET_STATUS.APPROVED:
        return "Approved";
      case DIET_STATUS.DRAFT:
        return "Draft";
      default:
        return "Rejected";
    }
  };

  const toogleAllPlansFn = (flag: any) => {
    setToggleAllPlans(flag);
    if (flag) {
      setDietPlansVisible(dietPlans?.data);
    } else {
      setDietPlansVisible(dietPlans?.data ? dietPlans.data.slice(0, 2) : []);
    }
  };

  //function to Copy Diet
  const copyDietPlan = async (data: any) => {
    // diet_plan_id: data?.diet_plans_id
    const dietPlan = {
      ...data,
      diet_plan_meal_rel: data?.diet_plan_meal_rel?.map((meal: any) => {
        return {
          ...meal,
          meal_options: meal.meal_options.map((option: any) => {
            return {
              ...option,
              option_data: option.option_data.filter(
                (item: any) =>
                  // item.hide_meal === "N" &&
                  item.is_food_item_added_by_patient === "N" ||
                  !item.hasOwnProperty("is_food_item_added_by_patient")
              ),
            };
          }),
        };
      }),
    };
    handleCopyDietClick(dietPlan);
  };

  return (
    <div className="diet-list-container">
      <div className="diet-filter-container">
        <Select
          label="Filter by Status"
          options={DIET_STATUS_DROPDOWN}
          onChange={handleChange}
          value={dietType}
        />

        <Button onClick={handleAddDietClick}>Create Diet Plan</Button>
      </div>

      <ul className="diet-plans-list">
        {dietPlansVisible?.length === 0 ? (
          <li>
            <button className="diet-plan-card">No plans available</button>
          </li>
        ) : (
          dietPlansVisible?.map((dietPlan: DietPlan) => {
            return (
              <li key={dietPlan.diet_plans_id}>
                <button className="diet-plan-card">
                  <div className="diet-plan-details">
                    <div className="plan-name-wrapper">
                      <h3 className="plan-name">{dietPlan?.plan_name}</h3>
                      <Badge
                        className="plan-status-badge"
                        variant={
                          dietPlan?.status === DIET_STATUS.REJECTED
                            ? "danger"
                            : dietPlan?.status === DIET_STATUS.APPROVED
                            ? "success"
                            : "warning"
                        }
                      >
                        {getStatus(dietPlan?.status)}
                      </Badge>
                    </div>
                    <div>
                      <b>Duration: </b>
                      {dietPlan.start_date
                        ? formatDateTime({
                            timestamp: dietPlan.start_date,
                            showTime: false,
                          })
                        : "N/A"}{" "}
                      -{" "}
                      {dietPlan.end_date
                        ? formatDateTime({
                            timestamp: dietPlan.end_date,
                            showTime: false,
                          })
                        : "N/A"}
                    </div>
                  </div>
                  <div className="diet-plan-actions">
                    {dietPlan?.status !== DIET_STATUS.PENDING && <Button
                      className="view-pdf-btn"
                      variant="outlined"
                      onClick={() => {
                        const dietPlanTemp = {
                          ...dietPlan,
                          diet_plan_meal_rel: dietPlan?.diet_plan_meal_rel?.map(
                            (meal: any) => {
                              return {
                                ...meal,
                                meal_options: meal?.meal_options?.map(
                                  (option: any) => {
                                    return {
                                      ...option,
                                      option_data: option?.option_data?.filter(
                                        (item: any) =>
                                          // item.hide_meal === "N" &&
                                          item?.is_food_item_added_by_patient ===
                                            "N" ||
                                          !item?.hasOwnProperty(
                                            "is_food_item_added_by_patient"
                                          )
                                      ),
                                    };
                                  }
                                ),
                              };
                            }
                          ),
                        };
                        setSelectedDietData(dietPlanTemp);
                        setShowDietPdfModal(true);
                      }}
                    >
                      View PDF
                    </Button>}

                    <Tooltip placement="topLeft" title={"Click to Copy Diet"}>
                      <AiOutlineCopy
                        style={{
                          color: "#760fb2",
                          cursor: "pointer",
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => copyDietPlan(dietPlan)}
                      />
                    </Tooltip>
                    {(dietPlan?.status === DIET_STATUS.PENDING ||
                      dietPlan?.status === DIET_STATUS.DRAFT) && (
                      <Tooltip placement="topLeft" title={"Click to Change Diet"}>
                        <RiPencilFill
                          style={{
                            color: "#760fb2",
                            cursor: "pointer",
                            height: "20px",
                            width: "20px",
                          }}
                          onClick={() => handleEditDietClick(dietPlan)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </button>
              </li>
            );
          })
        )}
      </ul>

      {showViewAllBtn && (
        <button
          className="view-all-btn"
          onClick={() => toogleAllPlansFn(!toggleAllPlans)}
        >
          {!toggleAllPlans ? "View All" : "View less"}
        </button>
      )}

      <Modal
        centered
        open={showDietPdfModal && selectedDietData}
        footer={null}
        onCancel={() => setShowDietPdfModal(false)}
        width={"90%"}
        style={{ height: "500px", marginTop: "20px" }}
        zIndex={10000}
      >
        <div>
          <DietPlanPdf
            dietData={selectedDietData}
            userDetails={healthCoachDetails?.data}
          />
        </div>
      </Modal>
    </div>
  );
});

export default DietPlansList;
