import React, { useEffect, useState } from "react";
import "./PreviewDrawerContent.scss";
import useApi from "../../../../../hooks/useApi";
import API from "../../../../../services/rest/api";
import processGoalData from "../../../../../helpers/goal";
import { toast } from "react-toastify";
import {
  addGoals,
  setPreAssessments,
  totalAssessmentCount,
} from "../../../../../store/slices/GoalSlice";
import Table from "../../../../../atoms/Table/Table";
import Loader from "../../../../../atoms/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

interface PreviewDrawerContentProps {
  // Add any props you need here
  patientId: string;
  activeTab: string;
}

const PreviewDrawerContent: React.FC<PreviewDrawerContentProps> = ({
  patientId,
  activeTab,
}) => {
  const {
    mutate: fetchPreAssessments,
    response: preAssessmentsResponse,
    error: preAssessmentsError,
    isLoading: isPreAssessmentsLoading,
  } = useApi(API.GetPreviewAssessments);
  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");

  const dispatch = useDispatch();
  const { preAssessments } = useSelector((state: RootState) => state.goals);

  useEffect(() => {
    if (preAssessmentsResponse?.code?.toString() === "1") {
      dispatch(
        setPreAssessments({ preAssessments: preAssessmentsResponse?.data })
      );
    } else if (preAssessmentsResponse?.code?.toString() === "0") {
      toast.error(preAssessmentsResponse?.message || "Failed to fetch goals");
    }
  }, [preAssessmentsResponse, preAssessmentsError]);

  useEffect(() => {
    if (preAssessments.length === 0) {
      fetchPreAssessments({
        patient_id: patientId,
        disease: patientInfo?.disease || "",
      });
    }
  }, []);

  return (
    <div className="preview-columns">
      {isPreAssessmentsLoading ? (
        <Loader noBackground={true} />
      ) : (
        <Table
          data={preAssessments}
          columns={[
            {
              label: "Question",
              accessor: "question",
            },
            {
              label: "Answer",
              accessor: "answer",
            },
          ]}
          border={true}
        />
      )}
    </div>
  );
};

export default PreviewDrawerContent;
