import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DietTemplatesList from "../../../organisms/HomeScreen/DietTemplatesList/DietTemplatesList";
import Button from "../../../atoms/Button/Button";
import SideDrawer from "../../../atoms/SideDrawer/SideDrawer";
import AddDietContainer from "../../../organisms/AddDietPlan/AddDietPlan";
import AssessmentSideDrawer from "../AssessmentSideDrawer/AssessmentSideDrawer";

import { LeftOutlined } from "@ant-design/icons";
import { RootState } from "../../../store/store";
import { DietPlan } from "../../../types/diet";

import "./CreateDietPlan.scss";

type ScreenType =
  | "CHOOSE_TEMPLATE"
  | "CREATE_NEW_DIET_PLAN"
  | "CREATE_DIET_PLAN_FROM_TEMPLATE"
  | "EDIT_DIET_PLAN"
  | "COPY_DIET_PLAN";

interface Props {
  onCancel?: () => void;
  onSuccess?: () => void;
  selectedDietPlan: DietPlan;
  isEdit: boolean;
  isCopy: boolean;
}

const CreateDietPlan: React.FC<Props> = ({
  onCancel,
  onSuccess,
  selectedDietPlan,
  isEdit = false,
  isCopy = false,
}) => {
  const [screenType, setScreenType] = useState<ScreenType>("CHOOSE_TEMPLATE");
  const [showAssessmentSideDrawer, setShowAssessmentSideDrawer] =
    useState(false);

  const { healthCoachDetails } = useSelector(
    (state: RootState) => state.healthCoach
  );

  useEffect(() => {
    if (isEdit) {
      setScreenType("EDIT_DIET_PLAN");
    } else if (isCopy) {
      setScreenType("COPY_DIET_PLAN");
    } else {
      setScreenType("CHOOSE_TEMPLATE");
    }
  }, [isEdit]);

  const showCreateNewDietPlanScreen = () =>
    setScreenType("CREATE_NEW_DIET_PLAN");

  const onSelectTemplateBtnClick = () =>
    setScreenType("CREATE_DIET_PLAN_FROM_TEMPLATE");

  return (
    <div className="create-diet-plan-container">
      <div>
        <h2 className="title">
          {screenType === "CHOOSE_TEMPLATE"
            ? "Choose from Template"
            : screenType === "CREATE_NEW_DIET_PLAN"
            ? "Create New Diet Plan"
            : screenType === "EDIT_DIET_PLAN"
            ? "Edit Diet Plan"
            : screenType === "COPY_DIET_PLAN"
            ? "Copy Diet Plan"
            : "Create Diet Plan from Template"}
        </h2>
      </div>
      {screenType === "CHOOSE_TEMPLATE" ||
      screenType === "CREATE_DIET_PLAN_FROM_TEMPLATE" ? (
        <div className="create-new-diet-plan-container">
          <div className="create-diet-plan-btn-wrapper">
            {screenType === "CREATE_DIET_PLAN_FROM_TEMPLATE" ? (
              <Button
                className="view-assessment-btn"
                variant="outlined"
                onClick={() => setShowAssessmentSideDrawer(true)}
              >
                View Assessment
              </Button>
            ) : (
              <Button
                className="create-diet-btn"
                variant="outlined"
                onClick={showCreateNewDietPlanScreen}
              >
                Create New Diet Plan
              </Button>
            )}
          </div>

          {screenType === "CREATE_DIET_PLAN_FROM_TEMPLATE" && (
            <Button
              className="back-btn"
              variant="secondary"
              onClick={() => setScreenType("CHOOSE_TEMPLATE")}
            >
              <LeftOutlined />
              <p className="back-btn-text">Back</p>
            </Button>
          )}

          <DietTemplatesList
            userDetails={healthCoachDetails?.data}
            onSelectTemplateBtnClick={onSelectTemplateBtnClick}
            screenType={screenType}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </div>
      ) : screenType === "CREATE_NEW_DIET_PLAN" ||
        screenType === "EDIT_DIET_PLAN" ||
        screenType === "COPY_DIET_PLAN" ? (
        <div className="create-new-diet-plan-container">
          <Button
            className="back-btn"
            variant="secondary"
            onClick={() => {
              if (isEdit) {
                onCancel && onCancel();
              } else {
                setScreenType("CHOOSE_TEMPLATE");
              }
            }}
          >
            <LeftOutlined />
            <p className="back-btn-text">Back</p>
          </Button>
          <div className="create-diet-plan-btn-wrapper">
            <Button
              className="view-assessment-btn"
              variant="outlined"
              onClick={() => setShowAssessmentSideDrawer(true)}
            >
              View Assessment
            </Button>
          </div>
          <AddDietContainer
            showTitle={false}
            editDietPlanData={isEdit && selectedDietPlan}
            copyDietPlanData={isCopy && selectedDietPlan}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </div>
      ) : null}

      {showAssessmentSideDrawer && (
        <SideDrawer
          isOpen={showAssessmentSideDrawer}
          title="Assessment"
          onClose={() => setShowAssessmentSideDrawer(false)}
          position="right"
        >
          <AssessmentSideDrawer />
        </SideDrawer>
      )}
    </div>
  );
};

export default CreateDietPlan;
