import React, { useEffect, useState } from "react";
import "./PreviewModal.scss";
import { Modal } from "antd";
import Button from "../../../../../atoms/Button/Button";
import Table from "../../../../../atoms/Table/Table";
import HealthProgress from "../HealthProgress/HealthProgress";
import { Goal, HealthProgressData } from "../../../../../types/goal";
import useApi from "../../../../../hooks/useApi";
import API from "../../../../../services/rest/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { Assessment } from "../../../../../types/assessment";
import moment from "moment";
import Loader from "../../../../../atoms/Loader";

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  goals: Record<string, Goal>;
  previewColumns: any[];
  handlePublish: () => void;
  assessments: any;
  patientId: string;
  isPublishing?: boolean;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  goals,
  previewColumns,
  handlePublish,
  assessments,
  patientId,
  isPublishing,
}) => {
  const {
    mutate: getAssessmentPreview,
    response: getAssessmentPreviewResponse,
    error: getAssessmentPreviewError,
    isLoading: isGetAssessmentPreviewLoading,
  } = useApi(API.GetAssessmentPreview);
  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");

  const [assessmentPreview, setAssessmentPreview] = useState<any>([]);
  const [latestAssessment, setLatestAssessment] = useState<any>([]);

  const scoringHistory = useSelector(
    (state: RootState) => state.scoring.scoringHistory
  );

  useEffect(() => {
    if (getAssessmentPreviewResponse?.code?.toString() === "1") {
      const previousAssessment = getPreviousAssessmentFromScoringHistory();
      if (latestAssessment?.length > 0) {
        const assessmentPreviewData = [
          previousAssessment,
          {
            id: latestAssessment[0]?.id,
            data: getAssessmentPreviewResponse?.data,
          },
        ];
        setAssessmentPreview(assessmentPreviewData);
      }
    } else if (getAssessmentPreviewResponse?.code?.toString() === "0") {
      toast.error(
        getAssessmentPreviewResponse?.message ||
          "Failed to get assessment preview"
      );
    }
  }, [getAssessmentPreviewResponse]);

  const getPreviousAssessmentFromScoringHistory = () => {
    return scoringHistory[0];
  };

  useEffect(() => {
    fetchAssessmentPreview();
  }, []);

  const getLatestAssessment = () => {
    const newAssessment = Object.values(assessments).find(
      (assessment: any) => assessment.isNew
    );
    setLatestAssessment(newAssessment ? [newAssessment] : []);
    return newAssessment ? [newAssessment] : [];
  };

  const fetchAssessmentPreview = () => {
    const latestAssessment = getLatestAssessment();
    let newId = "";
    if (latestAssessment.length > 0) {
      const completeId = (latestAssessment[0] as { id: string }).id;
      newId = completeId.split("_").pop() || "";
      const formattedData = Object.values(goals)
        // .filter((goal) => goal.status !== "completed")
        .map((goal) => ({
          goal_id: goal.id,
          sub_goals:
            goal.subGoals?.map((subGoal) => ({
              id: subGoal.id,
              name: subGoal.name,
              value:
                (subGoal[(latestAssessment[0] as { id: string }).id] as any)
                  ?.value || "",
              delta: {
                type:
                  (subGoal[(latestAssessment[0] as { id: string }).id] as any)
                    ?.delta?.type || "",
              },
            })) || [],
        }));

      const assessment = {
        id: Number(newId),
        data: formattedData,
      };

      const payload = {
        patient_id: patientId,
        disease: patientInfo?.disease || "",
        assessment: assessment,
      };
      try {
        getAssessmentPreview(payload);
      } catch (error) {
        console.log("error---", error);
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      footer={null}
      onCancel={onClose}
      width={950}
    >
      <div className="preview-modal">
        <h2>Preview Assessment</h2>
        {isGetAssessmentPreviewLoading ? (
          <div className="loader-container">
            <Loader noBackground={true} />
          </div>
        ) : (
          <>
            {assessmentPreview?.length > 0 ? (
              <div className="health-progress-grid">
                <HealthProgress
                  data={{
                    id:
                      assessmentPreview[0]?.assessment_id === 0
                        ? "Pre Assessment"
                        : "Assessment " + assessmentPreview[0]?.assessment_id,
                    minScore: assessmentPreview[0]?.min_score,
                    maxScore: assessmentPreview[0]?.max_score,
                    metabolism: assessmentPreview[0]?.score?.metabolism,
                    wellness: assessmentPreview[0]?.score?.wellness,
                    habit: assessmentPreview[0]?.score?.habit,
                    profile: assessmentPreview[0]?.score?.profile,
                    date: assessmentPreview[0]?.updated_at
                      ? moment(assessmentPreview[0].updated_at).format(
                          "DD/MM/YYYY"
                        )
                      : "",
                    dataIndex: 0,
                  }}
                />
                <HealthProgress
                  data={{
                    dataIndex: 1,
                    id: `Assessment ${
                      assessmentPreview[1]?.id?.split("_")[1] || ""
                    }`,
                    minScore: assessmentPreview[1]?.data?.metabolism?.min_score,
                    maxScore: assessmentPreview[1]?.data?.metabolism?.max_score,
                    metabolism: assessmentPreview[1]?.data?.metabolism?.score,
                    wellness: assessmentPreview[1]?.data?.wellness?.score,
                    habit: assessmentPreview[1]?.data?.habit?.score,
                    profile: assessmentPreview[1]?.data?.profile?.score,
                    wellnessTrend: assessmentPreview[1]?.data?.wellness?.delta,
                    habitTrend: assessmentPreview[1]?.data?.habit?.delta,
                    profileTrend: assessmentPreview[1]?.data?.profile?.delta,
                    date: moment().format("DD/MM/YYYY"),
                  }}
                />
              </div>
            ) : (
              <div className="no-data-message">
                No assessment preview data available
              </div>
            )}
            <Table
              data={Object.values(goals)}
              columns={previewColumns.map((col) => ({
                ...col,
                accessor: col.key,
              }))}
              border={true}
            />
            <div className="modal-actions">
              <Button
                variant="primary"
                onClick={handlePublish}
                disabled={isPublishing}
              >
                Publish
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
