import { FC, useRef, useState } from "react";
import MyTatvaLogo from '../../assets/icons/MyTatva_logo.svg'
import './PatientReportView.scss';
import { BloodPressureIcon, BmiIcons, BodyCompositionIcon, CarbIcon, DecreasedIcon, FatIcon, FoodDiaryIcon, HealthCoachIcon, HipIcons, IncreasedIcon, LDLIcon, LungsIcon, OtherLifeIcon, ProfileIcon, ProteinIcon, PusleIcon, SGPTIcons, SPO2Icon, TemperatureIcon, VitalsIcon, WaistIcons, WaistToHipIcons, WeightIcons } from "../../assets/icons";
import { Progress, Spin } from "antd";
import moment from "moment";
import html2pdf from "html2pdf.js";
import LungIcon from '../../assets/icons/lung.png'
import WalkIcon from '../../assets/icons/six_min_walk.png'
import LiverIcon from '../../assets/icons/liver.png'
import KidneyIcon from '../../assets/icons/kidney.png'
import CardioIcon from '../../assets/icons/cardiology.png'
import CaloriesBurnIcon from '../../assets/icons/calories_burned.png'
import AccessbilityIcon from '../../assets/icons/accessibility.png'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { getDecryptedData, getEncryptedText } from "../../utils/common";
import { containerAccount } from "../../utils/constants";
import API from "../../services/rest/api";
import Button from "../../atoms/Button/Button";
import { toast } from "react-toastify";

type PatientReportViewProps = {
    patient_id?: any,
    selectedReportData?: any,
    userInfo?: any,
    patientDetails?: any,
    reportPeiodDates?: any,
    setReportModal?: any,
    fetchDietData?: any,
    selectedDietPlan?: any,
    setSelectedDietPlan?: any,
    setReportPeriodDates?: any
}

const PatientReportView: FC<PatientReportViewProps> = ({ selectedReportData, userInfo, patientDetails, reportPeiodDates, setReportModal, fetchDietData, selectedDietPlan, setSelectedDietPlan, setReportPeriodDates }) => {

    const targetRef = useRef<any>(null);
    const [reportLoading, setReportLoading] = useState<any>(false);

    // const GeneratePDF = async () => {
    //     if (targetRef && targetRef.current) {
    //         try {
    //             const element = targetRef.current;

    //             const pdfOptions = {
    //                 filename: 'page.pdf',
    //                 image: { type: 'jpeg', quality: 0.98 },
    //                 html2canvas: { scale: 2 },
    //                 jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    //             };

    //             const paddingStyles = `
    //                 <style>
    //                     .pdf-padding-top {
    //                         margin-top: 20px; /* Adjust the top padding */
    //                     }
    //                     .pdf-padding-bottom {
    //                         margin-bottom: 20px; /* Adjust the bottom padding */
    //                     }
    //                 </style>
    //             `;

    //             // Generate PDF with padding elements
    //             await html2pdf().from(element).set({
    //                 ...pdfOptions,
    //                 margin: [0, 0], // Ensure zero margins
    //                 html2canvas: { scale: 2, logging: false },
    //                 jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //                 html2pdf: {
    //                     html2canvas: { scale: 2, logging: false },
    //                     margin: [0, 0], // Ensure zero margins
    //                     filename: 'page.pdf',
    //                     image: { type: 'jpeg', quality: 1 },
    //                     output: {
    //                         format: 'pdf',
    //                         options: {
    //                             margins: { top: 20, bottom: 20 } // Padding for the PDF page
    //                         }
    //                     }
    //                 },
    //                 hooks: {
    //                     beforeRender: (pdf: any) => {
    //                         pdf.internal.insertHtml(paddingStyles, 'afterbegin');
    //                     }
    //                 }
    //             }).outputPdf('blob') // Generate PDF as a Blob directly

    //                 .then((pdfBlob: any) => {
    //                     html2pdf().from(element).set(pdfOptions).save();
    //                     const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    //                     console.log('PDF generated:', pdfBlobUrl);

    //                     // Open the Blob URL in a new tab (if not blank)
    //                     // if (pdfBlob.size > 0) {
    //                     //     window.open(pdfBlobUrl, '_blank');
    //                     // } else {
    //                     //     console.error('Generated PDF is blank.');
    //                     // }
    //                 });

    //         } catch (error) {
    //             console.error('Error generating PDF:', error);
    //         }
    //     }
    // };

    // Previous implementation of the GeneratePDF function...
    // const GeneratePDF = async () => {
    //     setReportLoading(true)
    //     if (targetRef && targetRef.current) {
    //         try {
    //             const element = targetRef.current;

    //             const pdfOptions = {
    //                 filename: 'page.pdf',
    //                 image: { type: 'jpeg', quality: 0.98 },
    //                 html2canvas: { scale: 2 },
    //                 jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //                 margin: [4, 0, 0, 0]
    //             };

    //             const paddingStyles = `
    //                 <style>
    //                     .pdf-padding-top {
    //                         margin-top: 20px; /* Adjust the top padding */
    //                     }
    //                     .pdf-padding-bottom {
    //                         margin-bottom: 20px; /* Adjust the bottom padding */
    //                     }
    //                 </style>
    //             `;

    //             // Generate PDF with padding elements
    //             await html2pdf().from(element).set({
    //                 ...pdfOptions,
    //                 // margin: [0, 0], // Ensure zero margins
    //                 html2canvas: { scale: 2, logging: false },
    //                 jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //                 html2pdf: {
    //                     html2canvas: { scale: 2, logging: false },
    //                     // margin: [0, 0], // Ensure zero margins
    //                     filename: 'page.pdf',
    //                     image: { type: 'jpeg', quality: 1 },
    //                     output: {
    //                         format: 'pdf',
    //                         options: {
    //                             // margins: { top: 20, bottom: 20 } // Padding for the PDF page
    //                         }
    //                     }
    //                 },
    //                 hooks: {
    //                     beforeRender: (pdf: any) => {
    //                         pdf.internal.insertHtml(paddingStyles, 'afterbegin');
    //                     }
    //                 }
    //             }).outputPdf('blob') // Generate PDF as a Blob directly
    //                 .then(async (pdfBlob: any) => {
    //                     // Download PDF...
    //                     // html2pdf().from(element).set(pdfOptions).save();
    //                     const reader = new FileReader();
    //                     reader.onload = async () => {
    //                         const base64Url: string | ArrayBuffer | null = reader.result;
    //                         const fileURL = typeof (base64Url) == "string" ? base64Url?.split('base64,')[1] : "";
    //                         // const fileName = `report-${uuidv4().split('-')[0]}`;
    //                         const fileName = `${(patientDetails?.name || '').replaceAll(" ", "")}-${moment().format('YYYY-MM-DD hh:mm:ss')}`;

    //                         let payload = {
    //                             containerAccount: containerAccount,
    //                             containerName: "health-coach-smart-report",
    //                             fileName,
    //                             fileData: fileURL,
    //                             extensionType: "pdf",
    //                         };

    //                         const encrypted = getEncryptedText(payload)
    //                         const decryptedData1 = getDecryptedData(encrypted)
    //                         const data = await API.UploadPdf(encrypted)
    //                         let decryptedData = getDecryptedData(data)
    //                         const parsedDecrypted = JSON.parse(decryptedData)
    //                         if (parsedDecrypted.code === '1') {
    //                             let payload = {
    //                                 patient_id: patientDetails?.patient_id,
    //                                 end_date: reportPeiodDates?.start_date,
    //                                 start_date: reportPeiodDates?.start_date,
    //                                 document_name: `${fileName}.pdf`,
    //                                 status: userInfo?.chief_hc === "Y" ? "Approved" : "Pending",
    //                                 document_title: fileName,
    //                                 diet_plan_id: selectedDietPlan?.value,
    //                                 report_type: "diet"
    //                             }

    //                             const responseData = await API.CreateReport(payload)
    //                             if (responseData?.code === '1') {
    //                                 setReportModal(false)
    //                                 fetchDietData()
    //                                 setReportLoading(false)
    //                                 setSelectedDietPlan(null)
    //                                 setReportPeriodDates({ start_date: null, end_date: null })
    //                             }
    //                         }
    //                     };

    //                     reader.readAsDataURL(pdfBlob);
    //                     setReportLoading(false);
    //                 });

    //         } catch (error) {
    //             setReportLoading(false)
    //             console.error('Error generating PDF:', error);
    //         }
    //     }
    // };

    /** Optimized GeneratePDF function... */
    const GeneratePDF = async () => {
        setReportLoading(true);
        if (targetRef && targetRef.current) {
            try {
                const element = targetRef.current;

                const pdfOptions = {
                    filename: 'page.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2, logging: false },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                    margin: [4, 0, 0, 0]
                };

                const paddingStyles = `
                    <style>
                        .pdf-padding-top {
                            margin-top: 20px; /* Adjust the top padding */
                        }
                        .pdf-padding-bottom {
                            margin-bottom: 20px; /* Adjust the bottom padding */
                        }
                    </style>
                `;

                // Generate PDF with padding elements
                const pdfBlob = await html2pdf().from(element).set({
                    ...pdfOptions,
                    hooks: {
                        beforeRender: (pdf: any) => {
                            pdf.internal.insertHtml(paddingStyles, 'afterbegin');
                        }
                    }
                }).outputPdf('blob');
                
                const reader = new FileReader();
                reader.onload = async () => {
                    const base64Url = reader.result;
                    const fileURL = typeof base64Url === 'string' ? base64Url.split('base64,')[1] : '';
                    const fileName = `${(patientDetails?.name || '').replaceAll(' ', '')}-${moment().format('YYYY-MM-DD hh:mm:ss')}`;

                    const payload = {
                        containerAccount: containerAccount,
                        containerName: 'health-coach-smart-report',
                        fileName,
                        fileData: fileURL,
                        extensionType: 'pdf'
                    };

                    const encrypted = getEncryptedText(payload);
                    const data = await API.UploadPdf(encrypted);
                    const decryptedData = getDecryptedData(data);
                    const parsedDecrypted = JSON.parse(decryptedData);

                    if (parsedDecrypted.code === '1') {
                        const reportPayload = {
                            patient_id: patientDetails?.patient_id,
                            end_date: reportPeiodDates?.start_date,
                            start_date: reportPeiodDates?.start_date,
                            document_name: `${fileName}.pdf`,
                            status: userInfo?.chief_hc === 'Y' ? 'Approved' : 'Pending',
                            document_title: fileName,
                            diet_plan_id: selectedDietPlan?.value,
                            report_type: 'diet'
                        };

                        const responseData = await API.CreateReport(reportPayload);
                        if (responseData?.code === '1') {
                            setReportModal && setReportModal(false);
                            fetchDietData && fetchDietData();
                            setSelectedDietPlan && setSelectedDietPlan(null);
                            setReportPeriodDates && setReportPeriodDates({ start_date: null, end_date: null });

                            toast.success(responseData?.message || "Report successfully Uploaded!");
                        }
                    }

                    setReportLoading(false);
                };

                reader.readAsDataURL(pdfBlob);
            } catch (error) {
                setReportLoading(false);
                console.error('Error generating PDF:', error);
            }
        } else {
            setReportLoading(false);
        }
    };

    const renderInItData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>;
    };

    const renderAchievedData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : '-';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px', marginTop: '10px', fontFamily: 'Poppins' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span style={{ fontSize: '18px', fontWeight: '600', color: '#8129B9' }}>Report Name<span style={{ color: 'red' }}>*</span></span>
                <input
                    name='document_name'
                    onChange={(e) => setReportName(e.target.value)}
                    style={{ width: '50%', height: '30px', border: '1px solid lightgray', outline: 'none', borderRadius: '5px' }}
                />
            </div> */}
            <div className="patient-report-container" ref={targetRef}>
                <div className="report-header">
                    <div className="report-title">
                        Smart Health Report
                    </div>
                    <div>
                        <img src={MyTatvaLogo} />
                    </div>
                </div>

                <div className="patient-details-report">
                    <div className="patient-details-first-row">
                        <div className="patient-profile-sec">
                            <div className="patient-profile-pic">
                                {/* <img src={patientDetails?.profile_pic} alt="Profile" /> */}
                                <ProfileIcon />
                            </div>
                            <div className="patient-name-age-container">
                                <span style={{ fontSize: '14px', fontWeight: '600' }}>{patientDetails?.name}</span>
                                <span style={{ fontSize: '10px', fontWeight: '400' }}>{patientDetails?.gender === 'M' ? 'Male' : 'Female'} • {patientDetails?.patient_age} yrs</span>
                            </div>
                        </div>

                        <div className="hc-profile-sec">
                            <HealthCoachIcon />
                            <div className="hc-profile-name">
                                <span style={{ fontWeight: '400', fontSize: '9px' }}>Health Coach</span>
                                <span style={{ fontWeight: '600', fontSize: '10px' }}>{userInfo?.first_name} {userInfo?.last_name}</span>
                            </div>
                            <div style={{ border: '1px solid #4B4AD5', marginLeft: '20px' }}></div>
                            <div className="hc-profile-name" style={{ marginLeft: '10px' }}>
                                <span style={{ fontWeight: '600', fontSize: '12px', color: '#4B4AD5', lineHeight: 'normal', fontFamily: 'Poppins' }}>{patientDetails?.plan_name}</span>
                                <span style={{ fontWeight: '400', fontSize: '9px' }}>End date - {moment(patientDetails?.expiry_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ border: '1px solid #E2E2EA', margin: '20px auto 0px auto', width: '97.5%' }}></div>

                    <div className="patient-details-second-row">
                        <div className="patient-details-second">
                            <span style={{ fontSize: '9px', fontWeight: '400' }}>Height</span>
                            <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.height || '-'} {patientDetails?.height_unit}</span>
                        </div>
                        <div className="patient-details-second">
                            <span style={{ fontSize: '9px', fontWeight: '400' }}>Weight</span>
                            <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.weight || '-'} {patientDetails?.weight_unit}</span>
                        </div>
                        <div className="patient-details-second">
                            <span style={{ fontSize: '9px', fontWeight: '400' }}>Disease</span>
                            <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.medical_condition_name || '-'}</span>
                        </div>
                        <div className="patient-details-second" style={{ width: '40%' }}>
                            <span style={{ fontSize: '9px', fontWeight: '400' }}>Report Period</span>
                            <span style={{ fontSize: '10px', fontWeight: '600' }}> {moment(reportPeiodDates?.start_date).format('ll')} - {moment(reportPeiodDates?.end_date).format('ll')}</span>
                        </div>
                    </div>

                    <div className="hc-month-content">
                        <span style={{ fontSize: '9px', fontWeight: '500', padding: '8px' }}>
                            {selectedReportData?.patient_profile_notes}
                        </span>
                    </div>
                </div>

                <div className="reports-all-tables">

                    {/*Body Composition*/}
                    {selectedReportData?.body_composition &&
                        <div className="table-container">
                            <div className="table-heading">
                                <BodyCompositionIcon />
                                <span style={{ fontWeight: '600', fontSize: '12px' }}>Body Composition</span>
                            </div>

                            <div className="table-header">
                                <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    Parameters
                                </div>
                                <div style={{ width: '33%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                    </div>
                                </div>
                                {/* <div style={{ width: '20%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Goals</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                    </div>
                                </div> */}
                                <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    Achieved
                                </div>
                            </div>

                            {selectedReportData?.body_composition?.weight &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <WeightIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Weight (Kg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.body_composition?.weight?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {selectedReportData?.body_composition?.weight?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={selectedReportData?.body_composition?.weight?.achieved_value} size={20} /> */}
                                        {renderAchievedData(selectedReportData?.body_composition?.weight?.achieved_value)}
                                    </div>
                                </div>
                            }

                            {selectedReportData?.body_composition?.bmi &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <BmiIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>BMI (kg/m2 )</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.body_composition?.bmi?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {selectedReportData?.body_composition?.bmi?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={selectedReportData?.body_composition?.bmi?.achieved_value} size={20} /> */}
                                        {renderAchievedData(selectedReportData?.body_composition?.bmi?.achieved_value)}
                                    </div>
                                </div>
                            }

                            {selectedReportData?.body_composition?.waist &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <WaistIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.body_composition?.waist?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {selectedReportData?.body_composition?.waist?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={selectedReportData?.body_composition?.waist?.achieved_value} size={20} /> */}
                                        {renderAchievedData(selectedReportData?.body_composition?.waist?.achieved_value)}
                                    </div>
                                </div>
                            }

                            {selectedReportData?.body_composition?.hip &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <HipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Hip (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.body_composition?.hip?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '23%', fontSize: '9px', fontWeight: '500' }}>
                                        {selectedReportData?.body_composition?.hip?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={selectedReportData?.body_composition?.hip?.achieved_value} size={20} /> */}
                                        {renderAchievedData(selectedReportData?.body_composition?.hip?.achieved_value)}
                                    </div>
                                </div>
                            }

                            {selectedReportData?.body_composition?.waist_hip_ratio &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <WaistToHipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist to Hip Ratio</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.body_composition?.waist_hip_ratio?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {selectedReportData?.body_composition?.waist_hip_ratio?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={selectedReportData?.body_composition?.waist_hip_ratio?.achieved_value} size={20} /> */}
                                        {renderAchievedData(selectedReportData?.body_composition?.waist_hip_ratio?.achieved_value)}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {/*Vitals Summary*/}
                    {selectedReportData?.vitals_summary &&
                        <div className="table-container" style={{ marginTop: '30px' }}>
                            <div className="table-heading">
                                <VitalsIcon />
                                <span style={{ fontWeight: '600', fontSize: '12px' }}>Vitals Summary</span>
                            </div>

                            <div className="table-header">
                                <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    Parameters
                                </div>
                                <div style={{ width: '20%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                    </div>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Final</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                    </div>
                                </div>
                                <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    %Change
                                </div>
                            </div>

                            {selectedReportData?.vitals_summary?.temperature &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <TemperatureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Temperature (C)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.vitals_summary?.temperature?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderAchievedData(selectedReportData?.vitals_summary?.temperature?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((selectedReportData?.vitals_summary?.temperature?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            selectedReportData?.vitals_summary?.temperature?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.vitals_summary?.temperature?.percentage_changed}% {selectedReportData?.vitals_summary?.temperature?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.vitals_summary?.temperature?.percentage_changed}% {selectedReportData?.vitals_summary?.temperature?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            }

                            {selectedReportData?.vitals_summary?.pulse &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <PusleIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Pulse (bpm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.vitals_summary?.pulse?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderAchievedData(selectedReportData?.vitals_summary?.pulse?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((selectedReportData?.vitals_summary?.pulse?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            selectedReportData?.vitals_summary?.pulse?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.vitals_summary?.pulse?.percentage_changed}% {selectedReportData?.vitals_summary?.pulse?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.vitals_summary?.pulse?.percentage_changed}% {selectedReportData?.vitals_summary?.pulse?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            }

                            {selectedReportData?.vitals_summary?.resp_rate &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <LungsIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Resp. Rate (/min)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.vitals_summary?.resp_rate?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderAchievedData(selectedReportData?.vitals_summary?.resp_rate?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((selectedReportData?.vitals_summary?.resp_rate?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            selectedReportData?.vitals_summary?.resp_rate?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.vitals_summary?.resp_rate?.percentage_changed}% {selectedReportData?.vitals_summary?.resp_rate?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.vitals_summary?.resp_rate?.percentage_changed}% {selectedReportData?.vitals_summary?.resp_rate?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            }

                            {selectedReportData?.vitals_summary?.blood_pressure &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <BloodPressureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Blood Pressure (mmHg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {(!selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic &&
                                            !selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic)
                                            ? <span style={{ fontSize: '8px' }}>Data Not Provided</span>
                                            : <>{selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic || '-'} / {selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic || '-'}</>
                                        }
                                        {/* {selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic || '-'} / {selectedReportData?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic || '-'} */}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {(!selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic &&
                                            !selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic)
                                            ? <>-</>
                                            : <>{selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic || '-'} / {selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic || '-'}</>
                                        }
                                        {/* {selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic || '-'} / {selectedReportData?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic || '-'} */}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((selectedReportData?.vitals_summary?.blood_pressure?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            selectedReportData?.vitals_summary?.blood_pressure?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.vitals_summary?.blood_pressure?.percentage_changed}% {selectedReportData?.vitals_summary?.blood_pressure?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.vitals_summary?.blood_pressure?.percentage_changed}% {selectedReportData?.vitals_summary?.blood_pressure?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            }

                            {selectedReportData?.vitals_summary?.spo2 &&
                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <SPO2Icon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>SPO2</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderInItData(selectedReportData?.vitals_summary?.spo2?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {renderAchievedData(selectedReportData?.vitals_summary?.spo2?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((selectedReportData?.vitals_summary?.spo2?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            selectedReportData?.vitals_summary?.spo2?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.vitals_summary?.spo2?.percentage_changed}% {selectedReportData?.vitals_summary?.spo2?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.vitals_summary?.spo2?.percentage_changed}% {selectedReportData?.vitals_summary?.spo2?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            }

                            {
                                (selectedReportData?.vitals_summary?.other_vitals || [])?.map((vital: any) => {
                                    return (
                                        <div className="table-data">
                                            <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                                {(vital?.keys == 'lung' || vital?.keys == 'pef' || vital?.keys == 'fvc' || vital?.keys == 'aqi' || vital?.keys == 'humidity' || vital?.keys == 'fev1_fvc_ratio') &&
                                                    <img
                                                        src={LungIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }
                                                {(vital?.keys == 'six_min_walk') &&
                                                    <img
                                                        src={WalkIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }
                                                {(vital?.keys == 'fibro_scan' || vital?.keys == 'fatty_liver_ugs_grade' || vital?.keys == 'fib4') &&
                                                    <img
                                                        src={LiverIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }

                                                {(vital?.keys == 'egfr' || vital?.keys == 'acr' || vital?.keys == 'serum_creatinine') &&
                                                    <img
                                                        src={KidneyIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }

                                                {(vital?.keys == 'triglycerides' || vital?.keys == 'blood_glucose' || vital?.keys == 'basel_metabolic_rate' || vital?.keys == 'random_blood_glucose' || vital?.keys == 'platelet') &&
                                                    <img
                                                        src={CardioIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }
                                                {(vital?.keys == 'calories_burned') &&
                                                    <img
                                                        src={CaloriesBurnIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }
                                                {(vital?.keys == 'sedentary_time' || vital?.keys == 'hydration' || vital?.keys == 'metabolic_age' || vital?.keys == 'muscle_mass' || vital?.keys == 'subcutaneous_fat' || vital?.keys == 'protein' || vital?.keys == 'visceral_fat' || vital?.keys == 'skeletal_muscle' || vital?.keys == 'bone_mass' || vital?.keys == 'body_fat') &&
                                                    <img
                                                        src={AccessbilityIcon}
                                                        alt='vital_icon'
                                                        style={{
                                                            height: '13px',
                                                            width: '13px',
                                                            //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                        }}
                                                    />
                                                }
                                                <span style={{ fontSize: '9px', fontWeight: '500' }}>{vital?.reading_name}</span>
                                            </div>
                                            {vital?.keys == 'blood_glucose' ?
                                                <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                    {/* {vital?.initial_reading_value_data?.fast || '-'} / {vital?.initial_reading_value_data?.pp || '-'} */}
                                                    {(!vital?.initial_reading_value_data?.fast && !vital?.initial_reading_value_data?.pp)
                                                        ? <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>
                                                        : <>{vital?.initial_reading_value_data?.fast || '-'} / {vital?.initial_reading_value_data?.pp || '-'}</>
                                                    }
                                                </div>
                                                :
                                                <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                    {renderInItData(vital?.initial_value)}
                                                </div>
                                            }
                                            {vital?.keys == 'blood_glucose' ?
                                                <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                    {(!vital?.final_reading_value_data?.fast && !vital?.final_reading_value_data?.pp)
                                                        ? <>-</>
                                                        : <>{vital?.final_reading_value_data?.fast || '-'} / {vital?.final_reading_value_data?.pp || '-'}</>
                                                    }
                                                    {/* {vital?.final_reading_value_data?.fast || '-'} / {vital?.final_reading_value_data?.pp || '-'} */}
                                                </div>
                                                :
                                                <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                    {renderAchievedData(vital?.final_value)}
                                                </div>
                                            }
                                            <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                                {
                                                    ((vital?.change_type === 'unchanged')) ?
                                                        <div>-</div>
                                                        :
                                                        (
                                                            <>
                                                                {
                                                                    vital?.change_type === 'Increased' ?
                                                                        <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {vital?.percentage_changed}% {vital?.change_type}</div>
                                                                        :
                                                                        <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {vital?.percentage_changed}% {vital?.change_type}</div>
                                                                }
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                    {/* SGPT Table */}
                    {selectedReportData?.sgpt &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <SGPTIcons />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>SGPT</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>{selectedReportData?.sgpt?.default_reading}</span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.sgpt?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.sgpt?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.sgpt?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.sgpt?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.sgpt?.percentage_changed}% {selectedReportData?.sgpt?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.sgpt?.percentage_changed}% {selectedReportData?.sgpt?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* SGOT Table*/}
                    {selectedReportData?.sgot &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <SGPTIcons />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>SGOT</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>{selectedReportData?.sgot?.default_reading}</span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.sgot?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.sgot?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.sgot?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.sgot?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.sgot?.percentage_changed}% {selectedReportData?.sgot?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.sgot?.percentage_changed}% {selectedReportData?.sgot?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div style={{ fontWeight: '400', fontSize: '8px', display: 'inline-block', marginTop: '20px', gap: '8px', pageBreakInside: 'avoid' }}>
                        <span style={{ fontWeight: '600' }}>Note :</span><span style={{ paddingLeft: '2px' }}>{selectedReportData?.sgpt_notes}</span>
                    </div>

                    <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                    {/* LDL Table*/}
                    {selectedReportData?.ldl &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <LDLIcon />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>LDL</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>{selectedReportData?.ldl?.default_reading}</span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.ldl?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.ldl?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.ldl?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.ldl?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.ldl?.percentage_changed}% {selectedReportData?.ldl?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.ldl?.percentage_changed}% {selectedReportData?.ldl?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* HDL Table*/}
                    {
                        selectedReportData?.hdl &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <LDLIcon />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>HDL</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>{selectedReportData?.hdl?.default_reading}</span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.hdl?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.hdl?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.hdl?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.hdl?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.hdl?.percentage_changed}% {selectedReportData?.hdl?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.hdl?.percentage_changed}% {selectedReportData?.hdl?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/*Total Cholesterol*/}
                    {selectedReportData?.total_cholestrol &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <LDLIcon />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>Total Cholesterol</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>{selectedReportData?.total_cholesterol?.default_reading}</span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.total_cholesterol?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.total_cholesterol?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.total_cholesterol?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.total_cholesterol?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.total_cholesterol?.percentage_changed}% {selectedReportData?.total_cholesterol?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.total_cholesterol?.percentage_changed}% {selectedReportData?.total_cholesterol?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px', pageBreakInside: "avoid" }}>
                        <span style={{ fontWeight: '600' }}>Note:</span><span> {selectedReportData?.ldl_notes}</span>
                    </div>

                    <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                    {/*HbA1c Table*/}
                    {selectedReportData?.hba1c &&
                        <div className="second-table-container">
                            <div className="second-table-header">
                                <div className="second-table-header-left">
                                    <LDLIcon />
                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>HbA1c</span>
                                </div>
                                <div className="second-table-header-right">
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>Normal <b>below 5.7 %</b></span>
                                </div>
                            </div>

                            <div className="second-table-data">
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderInItData(selectedReportData?.hba1c?.initial_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                    <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                        {renderAchievedData(selectedReportData?.hba1c?.final_value)}
                                    </span>
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                    {
                                        ((selectedReportData?.hba1c?.change_type === 'unchanged')) ?
                                            <div>-</div>
                                            :
                                            (
                                                <>
                                                    {
                                                        selectedReportData?.hba1c?.change_type === 'Increased' ?
                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {selectedReportData?.hba1c?.percentage_changed}% {selectedReportData?.hba1c?.change_type}</div>
                                                            :
                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {selectedReportData?.hba1c?.percentage_changed}% {selectedReportData?.hba1c?.change_type}</div>
                                                    }
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/*Food Diary Summary*/}
                    {selectedReportData?.diet_details &&
                        <div className="table-container" style={{ marginTop: '30px' }}>
                            <div className="table-heading">
                                <FoodDiaryIcon />
                                <span style={{ fontWeight: '600', fontSize: '12px' }}>Food Diary</span>
                            </div>

                            <div className="table-header">
                                <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    Parameters
                                </div>
                                <div style={{ width: '33%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Goal</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                    </div>
                                </div>
                                <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Achieved</span>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-data">
                                <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                    <ProteinIcon />
                                    <span style={{ fontSize: '9px', fontWeight: '500' }}>Protein(%)</span>
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                    {renderInItData(selectedReportData?.diet_details?.protein_goal_value)}
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                    <Progress type="circle" percent={!selectedReportData?.diet_details?.protein_goal_value ? 0 : ((selectedReportData?.diet_details?.protein_achieved_value / selectedReportData?.diet_details?.protein_goal_value) * 100)} size={20} />
                                    {renderAchievedData(selectedReportData?.diet_details?.protein_achieved_value)}
                                </div>
                            </div>

                            <div className="table-data">
                                <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                    <FatIcon />
                                    <span style={{ fontSize: '9px', fontWeight: '500' }}>Fat(%)</span>
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                    {renderInItData(selectedReportData?.diet_details?.fat_goal_value)}
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                    <Progress type="circle" percent={!selectedReportData?.diet_details?.fat_goal_value ? 0 : ((selectedReportData?.diet_details?.fat_achieved_value / selectedReportData?.diet_details?.fat_achieved_value) * 100)} size={20} />
                                    {renderAchievedData(selectedReportData?.diet_details?.fat_achieved_value)}
                                </div>
                            </div>

                            <div className="table-data">
                                <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                    <CarbIcon />
                                    <span style={{ fontSize: '9px', fontWeight: '500' }}>Carb(%)</span>
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                    {renderInItData(selectedReportData?.diet_details?.carb_goal_value)}
                                </div>
                                <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                    <Progress type="circle" percent={!selectedReportData?.diet_details?.carb_goal_value ? 0 : ((selectedReportData?.diet_details?.carb_achieved_value / selectedReportData?.diet_details?.carb_achieved_value) * 100)} size={20} />
                                    {renderAchievedData(selectedReportData?.diet_details?.carb_achieved_value)}
                                </div>
                            </div>
                        </div>
                    }

                    {/*Diet Parameters Summary*/}
                    {!!selectedReportData?.food_frequency?.length &&
                        <div className="table-container" style={{ marginTop: '30px' }}>
                            <div className="table-heading">
                                <FoodDiaryIcon />
                                <div className="heading-content">
                                    <span className="title">Diet Parameters</span>
                                    <div className="adherence-status">
                                        {[{ label: 'Good', color: 'good' }, { label: 'Needs Improvement', color: 'needs-improvement' }].map((item, index) => (
                                            <div key={index} className="status-item">
                                                <div className={`status-icon ${item.color}`}></div>
                                                {item.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="box-container">
                                <div className="box-rows">
                                    {selectedReportData.food_frequency.slice(0, 3).map((data: any) => (
                                        <div className="box">
                                            <div className="sub-box-1">{data.key}</div>
                                            <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                {(data.status !== 'Data Not Provided')
                                                    ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                            <div className="sub-box-3">
                                                {(data.init_value && data.final_value)
                                                    ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="box-rows">
                                    {selectedReportData.food_frequency.slice(3, 6).map((data: any) => (
                                        <div className="box">
                                            <div className="sub-box-1">{data.key}</div>
                                            <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                {(data.status !== 'Data Not Provided')
                                                    ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                            <div className="sub-box-3">
                                                {(data.init_value && data.final_value)
                                                    ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="box-rows">
                                    {selectedReportData.food_frequency.slice(6, 10).map((data: any) => (
                                        <div className="box">
                                            <div className="sub-box-1">{data.key}</div>
                                            <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                {(data.status !== 'Data Not Provided')
                                                    ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                            <div className="sub-box-3">
                                                {(data.init_value && data.final_value)
                                                    ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                    : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {selectedReportData?.food_frequency_notes &&
                                    <div style={{ fontWeight: '400', fontSize: '8px', display: 'inline-block', marginTop: '15px', pageBreakInside: 'avoid' }}>
                                        <span style={{ fontWeight: '600', width: 'fit-content' }}>Note : </span><span style={{ paddingLeft: '2px' }}>{selectedReportData?.food_frequency_notes}</span>
                                    </div>}
                            </div>
                        </div>
                    }

                    <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                    {/*Suggested lifestyle goals input*/}
                    {selectedReportData?.life_style_notes &&
                        <div className="table-container" style={{ marginTop: '30px' }}>
                            <div className="table-heading">
                                <OtherLifeIcon />
                                <span style={{ fontWeight: '600', fontSize: '12px' }}>Suggested lifestyle goals</span>
                            </div>
                            <div style={{ fontSize: '12px' }}>
                                {selectedReportData?.life_style_notes}
                            </div>
                        </div>
                    }

                    <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                    {/*Doctor Notes*/}
                    {selectedReportData?.doctor_notes &&
                        <div className="table-container" style={{ marginTop: '30px' }}>
                            <div className="table-heading">
                                <OtherLifeIcon />
                                <span style={{ fontWeight: '600', fontSize: '12px' }}>Doctor Notes</span>
                            </div>
                            <div style={{ fontSize: '12px' }}>
                                {selectedReportData?.doctor_notes}
                            </div>
                        </div>
                    }

                </div>
            </div>

            <div className="btn-wrapper">
                <Button
                    variant="primary"
                    onClick={GeneratePDF}
                    loading={reportLoading}
                    disabled={reportLoading}
                >
                    Upload PDF
                </Button>
            </div>

            {/* <div className={reportName ? "report-btn" : "report-btn-disable"} onClick={GeneratePDF}> */}
            {/* <button className={"report-btn"} onClick={GeneratePDF} disabled={reportLoading}>
                {!reportLoading ? 'Upload Pdf' : <Spin style={{ color: 'white' }} />}
            </button> */}

        </div>
    )
}

export default PatientReportView;