// src/store/slices/patientSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { patient } from "../../types/index";

// Initial state for the patient slice
const initialState: patient.PatientStateType = {
  patientDetails: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

// Define the patient slice
const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    getPatientDetailsRequest: (
      state: patient.PatientStateType,
      action: PayloadAction<any>
    ) => {
      state.patientDetails.isLoading = true;
      state.patientDetails.data = null;
      state.patientDetails.errors = "";
    },
    patientDetailsSuccess: (
      state: patient.PatientStateType,
      action: PayloadAction<any>
    ) => {
      state.patientDetails.isLoading = false;
      state.patientDetails.data = action.payload.data;
      state.patientDetails.errors = null;
    },
    getPatientDetailsError: (
      state: patient.PatientStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.patientDetails.isLoading = false;
      state.patientDetails.data = null;
      state.patientDetails.errors = error;
    },
    resetPatientDetails: (state: patient.PatientStateType) => {
      state.patientDetails.isLoading = false;
      state.patientDetails.data = null;
      state.patientDetails.errors = null;
    },
  },
});

// Export actions and reducer
export const {
  getPatientDetailsRequest,
  patientDetailsSuccess,
  getPatientDetailsError,
  resetPatientDetails,
} = patientSlice.actions;

export default patientSlice.reducer;
