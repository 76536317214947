// src/store/slices/deitSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { diet } from "../../types/index";

// Initial state for the patient slice
const initialState: diet.DietStateType = {
  dietPlans: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

// Define the patient slice
const deitSlice = createSlice({
  name: "diet",
  initialState,
  reducers: {
    getDietPlansRequest: (
      state: diet.DietStateType,
      action: PayloadAction<any>
    ) => {
      state.dietPlans.isLoading = true;
      state.dietPlans.data = null;
      state.dietPlans.errors = "";
    },
    getDietPlansSuccess: (
      state: diet.DietStateType,
      action: PayloadAction<any>
    ) => {
      state.dietPlans.isLoading = false;
      state.dietPlans.data = action.payload.data;
      state.dietPlans.errors = null;
    },
    getDietPlansError: (
      state: diet.DietStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.dietPlans.isLoading = false;
      state.dietPlans.data = null;
      state.dietPlans.errors = error;
    },
  },
});

// Export actions and reducer
export const {
  getDietPlansRequest,
  getDietPlansSuccess,
  getDietPlansError,
} = deitSlice.actions;

export default deitSlice.reducer;
