import React from "react";
import "./GoalTable.scss";
import Table from "../../../../../atoms/Table/Table";
import Button from "../../../../../atoms/Button/Button";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { Goal } from "../../../../../types/goal";
import Spinner from "../../../../../atoms/Spinner/Spinner";

interface GoalTableProps {
  goals: Record<string, Goal>;
  columns: any[];
  collapsedGoals: Record<string, boolean>;
  onToggleCollapse: (goalId: string) => void;
  onAssessmentChange: (
    value: string,
    goalId: string,
    subGoalId: string,
    assessmentId: string
  ) => void;
  hasNewAssessment: () => boolean;
  onAddAssessment: () => void;
  onAddCustomGoal: () => void;
  onLoadMore: () => void;
  hasMore: boolean;
  isLoading?: boolean;
}

const GoalTable: React.FC<GoalTableProps> = ({
  goals,
  columns,
  collapsedGoals,
  onToggleCollapse,
  onAssessmentChange,
  hasNewAssessment,
  onAddAssessment,
  onAddCustomGoal,
  onLoadMore,
  hasMore,
  isLoading,
}) => {
  const handleTableScroll = (scrollData: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
  }) => {
    const isNearEnd =
      scrollData.scrollLeft + scrollData.clientWidth >=
      scrollData.scrollWidth - 50;
    if (isNearEnd && hasMore && !isLoading) {
      onLoadMore();
    }
  };

  return (
    <div>
      <Table
        data={Object.values(goals)}
        columns={columns.map((col) => ({
          ...col,
          accessor: col.key,
        }))}
        border={true}
        onScroll={handleTableScroll}
      />

      {(hasMore || isLoading) && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to right, transparent, #ffffff)",
            pointerEvents: "none",
          }}
        >
          {isLoading && <Spinner />}
        </div>
      )}
    </div>
  );
};

export default GoalTable;
