import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/AssessmentSlice";

import { constants } from "../../constants/common";
import { common } from "../../types";

import API from "../../services/rest/api";

const GetAssessmentDiseaseRequest = function* GetAssessmentDiseaseRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(API.GetDiseasesData);
    if (Number(response.code) === 1) {
      yield put(actions.getAssessmentDiseaseSuccess(response));
      resolve(response);
    } else if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(
      actions.getAssessmentDiseaseError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    // reject(error);
  }
};

const GetAssessmentRequest = function* GetAssessmentRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      API.GetPatientAssessments,
      payload
    );
    if (Number(response.code) === 1) {
      yield put(actions.getAssessmentsSuccess(response));
      resolve(response);
    } else if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(
      actions.getAssessmentsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    // reject(error);
  }
};

export default function* dietSaga() {
  yield all([
    takeEvery(actions.getAssessmentDiseaseRequest, GetAssessmentDiseaseRequest),
  ]);
  yield all([takeEvery(actions.getAssessmentsRequest, GetAssessmentRequest)]);
}
