import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishAssessment } from '../store/slices/GoalSlice';
import { RootState } from '../store/store';
import useApi from './useApi';
import API from '../services/rest/api';
import { Assessment } from '../types/goal';
import { toast } from 'react-toastify';
import { useScoreProgress } from './useScoreProgress';

interface UseAssessmentPublisherProps {
  activeTab: string;
  onPublishSuccess?: () => void;
  onPublishError?: (message: string) => void;
  patientId: string;
  isPrimary: boolean;
}

export const useAssessmentPublisher = ({
  activeTab,
  patientId,
  onPublishSuccess,
  onPublishError,
  isPrimary,
}: UseAssessmentPublisherProps) => {
  const dispatch = useDispatch();
  const { goals, assessments } = useSelector(
    (state: RootState) =>
      state.goals.data[isPrimary ? "mainGoal" : "customGoals"]
  );
  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");

  const { getScoringHistoryData } = useScoreProgress();

  const {
    mutate: publishReviewedAssessment,
    response: publishReviewedAssessmentResponse,
    error: publishReviewedAssessmentError,
    isLoading: isPublishReviewedAssessmentLoading,
  } = useApi(API.publishAssessment);

  const hasAssessmentWithName = (name: string) => {
    return Object.values(assessments).some(
      (assessment) => assessment.name === name && !assessment.isNew
    );
  };
  
  const postAssessment = async () => {
    try {
      const newAssessment = Object.values(assessments).find(
        (assessment) => assessment.name === 'New Assessment' && assessment.isNew
      );
      if (!newAssessment) return;

      const formattedData = Object.values(goals)
        .map((goal) => {
          const baseGoalData = {
            goal_id: goal.id,
            sub_goals: goal.subGoals?.map((subGoal) => ({
              id: subGoal.id,
              name: subGoal.name,
              value: (subGoal[newAssessment.id] as Assessment)?.value || '',
              ...(activeTab !== 'diagnostics' && {
                delta: {
                  type: (subGoal[newAssessment.id] as Assessment)?.delta?.type || ''
                }
              })
            })) || []
          };

          return goal?.goalScore?.score ?? 0 > 0
            ? { ...baseGoalData, score: goal.goalScore!.score! }
            : baseGoalData;
        });

      const assessment = {
        id: Number(newAssessment.id.split('_')[1]),
        data: formattedData,
      }

      const payload = {
        tag: activeTab,
        action: newAssessment?.isSkip ? 'skipped' : 'published',
        // assessment_id: newAssessment.id.split('_')[1],
        assessment,
        patient_id: patientId,
        is_primary: isPrimary ? 1 : 0,
        disease: patientInfo?.disease || "",
      };
      await publishReviewedAssessment(payload);
      dispatch(publishAssessment({ isPrimary, activeTab,
        patientId: patientId || "", }));
    } catch (error) {
      console.error('Error publishing assessment:', error);
      onPublishError?.(error instanceof Error ? error.message : 'Unknown error');
    }
  };

  useEffect(() => {
    if (publishReviewedAssessmentResponse?.code?.toString() === "1") {
      toast.success(publishReviewedAssessmentResponse?.message || "Assessment published successfully");
      onPublishSuccess?.();
      getScoringHistoryData();
    } else if (publishReviewedAssessmentResponse?.code?.toString() == "0" || publishReviewedAssessmentError) {
      toast.error(publishReviewedAssessmentResponse?.message || publishReviewedAssessmentError?.message || "Failed to publish assessment");
      onPublishError?.('Failed to publish assessment');
    }
  }, [publishReviewedAssessmentResponse, publishReviewedAssessmentError]);

  const handlePublish = () => {
    postAssessment();
  };

  return {
    handlePublish,
    isPublishing: isPublishReviewedAssessmentLoading,
  };
}; 