import { useState, useEffect, useRef } from 'react';
import API from '../services/rest/api';
import { CustomGoal } from '../types/goal';
import useApi from './useApi';
import { toast } from 'react-toastify';
import { useGoals } from './useGoals';
// import { addGoals, totalAssessmentCount } from '../store/slices/GoalSlice';
// import processGoalData from '../helpers/goal';

const usePatientCustomGoals = (patientId: string, activeTab: string, onClose: () => void, isPrimary: boolean, fetchGoalsHandler: () => Promise<void>) => {
    const [goals, setGoals] = useState<CustomGoal[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
    const isInitialMount = useRef(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const {
        mutate: fetchCustomGoals,
        response: customGoalsResponse,
        error: customGoalsError,
        isLoading: isCustomGoalsLoading
    } = useApi(API.PatientCustomGoals)
    // const { fetchGoals: fetchGoalsHandler } = useGoals(activeTab, patientId, isPrimary);

    const {
        mutate: addPatientCustomGoalsHandler,
        response: addPatientCustomGoalsResponse,
        error: addPatientCustomGoalsError,
        isLoading: isAddPatientCustomGoalsLoading
    } = useApi(API.AddPatientCustomGoals)

    useEffect(() => {
  
        if(customGoalsResponse?.code?.toString() === "1") {
            setLoading(false);
            setGoals(customGoalsResponse?.data || []);

        }   else if(customGoalsResponse?.code?.toString() === "0") {
            setError(customGoalsResponse?.message || "Failed to fetch goals");
        }
      
      }, [customGoalsResponse, customGoalsError]);

      useEffect(() => {
        const handleResponse = async () => {
            if(addPatientCustomGoalsResponse?.code?.toString() === "1") {
                setIsSuccess(true);
                await fetchGoalsHandler();
                toast.success(addPatientCustomGoalsResponse?.message || "Goals added successfully");
                onClose();
            } else if(addPatientCustomGoalsResponse?.code?.toString() === "0") {
                toast.error(addPatientCustomGoalsResponse?.message || "Failed to add goals");
                setIsSuccess(false);
                setError(addPatientCustomGoalsResponse?.message || "Failed to add goals");
            }
        };
        handleResponse();
    }, [addPatientCustomGoalsError, addPatientCustomGoalsResponse]);

    const fetchGoals = async () => {
        try {
            setLoading(true);
            fetchCustomGoals({ patient_id: patientId, tag: activeTab, is_primary: isPrimary ? 1 : 0 });
        } catch (err) {
            setError('Failed to fetch goals');
        } finally {
            
        }
    };

    const filteredGoals = goals.filter(goal => 
        goal.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleGoalSelect = (goalId: string) => {
        setSelectedGoals(prev => 
            prev.includes(goalId) 
                ? prev.filter(id => id !== goalId)
                : [...prev, goalId]
        );
    };

    useEffect(() => {
        if (isInitialMount.current) {
            if (patientId && activeTab) {
                fetchGoals();
            }
            isInitialMount.current = false;
        }
    }, [patientId, activeTab, fetchCustomGoals]);

    const addPatientCustomGoals = async (goalIds: string[]) => {
        try {
            addPatientCustomGoalsHandler({ patient_id: patientId, goals: goalIds, tag: activeTab, is_primary: isPrimary ? 1 : 0 })
        } catch (error) {
            console.error('Error adding custom goals:', error);
            throw error;
        }
    };


    return {
        goals,
        loading,
        error,
        searchQuery,
        setSearchQuery,
        filteredGoals,
        selectedGoals,
        handleGoalSelect,
        refreshGoals: fetchGoals,
        addPatientCustomGoals,
        isSuccess
    };
};

export default usePatientCustomGoals; 