import React from "react";

import RedirectComponent from "../../../../atoms/RedirectComponent/RedirectComponent";

const SleepTaskPage: React.FC<any> = () => {
  return (
    <div>
      <RedirectComponent moduleName="sleep" />
    </div>
  );
};

export default SleepTaskPage;
