import React from "react";
import { DatePicker as DatePickerAntd } from "antd";

import "./DateRangePicker.scss";
import dayjs from "dayjs";
import moment from "moment";

interface Props {
  label?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  onChange?: any;
  value?: any;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  disabledDate?: any;
}

const DateRangePicker: React.FC<Props> = ({
  label,
  options,
  onChange,
  value,
  placeholder,
  error,
  helperText,
  disabledDate,
}) => {
  const { RangePicker } = DatePickerAntd;

  return (
    <div className="date-range-select-wrapper">
      {label && <label className="date-range-select-label">{label}</label>}
      <RangePicker
        className="date-range-select"
        style={{ borderColor: error ? "red" : "#7E22B7", color: "#7E22B7" }}
        onChange={onChange}
        value={[
          value?.start_date ? dayjs(value?.start_date) : null,
          value?.end_date ? dayjs(value?.end_date) : null,
        ]}
        disabledDate={(current) => {
          let customDate = moment().format("YYYY-MM-DD");
          if (value) {
            return (
              (value?.start_date &&
                current <
                  moment(value?.start_date, "YYYY-MM-DD").startOf("day")) ||
              (value?.end_date &&
                current > moment(value?.end_date, "YYYY-MM-DD").endOf("day"))
            );
          } else {
            return (
              current && current > moment(customDate, "YYYY-MM-DD").endOf("day")
            );
          }
        }}
      />
      {helperText && (
        <div className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
