import React, { FC, useEffect, useState } from "react";
import './DietAssessmentView.scss'
import API from "../../services/rest/api";
import { RiPencilFill } from "react-icons/ri";
import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import ReactSelect from "react-select";


type AssessmentViewProps = {
    patient_id?: any,
    selectedAssessment?: any,
    chief_hc?: any,
    ifHomeScreen?: any,
    showCloseButton?: boolean,
    assessment_type?: any,
    selectedDisease?: any
    enableEdit?: boolean;
}

const DietAssessmentView: FC<AssessmentViewProps> = ({ patient_id, selectedAssessment, chief_hc, ifHomeScreen, showCloseButton, assessment_type, selectedDisease, enableEdit = true }) => {
    const [assessmentData, setAssessmentData] = useState<any>(null)
    const navigate = useNavigate();
    const location = useLocation();

    const health_coach_id = localStorage.getItem('health_coach_id')
    const token = localStorage.getItem('token')

    const editAssessment = (assessment: any) => {
        let encodedtoken = encodeURIComponent(token || '')
        navigate(`/patients/${health_coach_id}/assessment/${patient_id}/${encodedtoken}`, { state: { data: assessment, button: 'edit', screen: ifHomeScreen } })
    }

    // const deleteAssessment = async (assessment: any) => {
    //     try {
    //         const data = await API.DeletePatientAssessment({ patient_assessment_id: assessment?.patient_assessment_id })
    //         getPatientAssessments()
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    
    // useEffect(() => {
    //     if(selectedAssessment) {
    //         setAssessmentData(() => selectedAssessment)
    //     } else {
    //         getPatientAssessments();
    //     }
    //     return() => {
    //         setAssessmentData(null)
    //     }
    // }, [selectedAssessment, selectedDisease, patient_id])

        //funtion to get patient assessments
        const getPatientAssessments = async () => {
            try {
                let res = await API.GetPatientAssessments({ patient_id, read_only: true, disease_type: selectedDisease, ...location?.state?.hc_role === 'Physiotherapist' ? { assessment_type: 'exercise' } : { assessment_type: 'diet' } })
                if (res?.code === "1") {
                  setAssessmentData(
                    () => res?.data[res?.data?.length - 1] || null
                  );
                  window.parent.postMessage(
                    {
                      messageType: "getSelectedAssessmentForExercise",
                      assessmentData: res?.data[res?.data?.length - 1] || null,
                    },
                    "*"
                  );
                } else {
                  setAssessmentData(null);
                  window.parent.postMessage(
                    {
                      messageType: "getSelectedAssessmentForExercise",
                      assessmentData: null,
                    },
                    "*"
                  );
                }
            } catch (error) {
                console.log(error);
                setAssessmentData(null);
            }
        }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '33px',
            boxShadow: state.isFocused ? null : null,
            borderRadius: '5px',
            width: '200px',
            cursor: 'pointer',
            border: '1px solid #7E22B7',
            color: '#7E22B7'
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '3px 10px',
            fontSize: '12px',
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
        menuPortal: (base: any) => ({
            ...base,
            fontSize: '8px'
        })
    };

    return (
        <div className="assessment-view-container">

            {selectedAssessment &&
                <div className="assessment-view-inner">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className="assessment-heading">{selectedAssessment?.assessment_name}</span>
                        {(enableEdit && !chief_hc) &&
                            <>
                                <Tooltip placement="topLeft" title={'Click to Change Assessment'}>
                                    <RiPencilFill style={{ color: '#760fb2', cursor: 'pointer', height: '20px', width: '20px', marginRight: '10px' }} onClick={() => editAssessment(selectedAssessment)} />
                                </Tooltip>
                                {/* <Tooltip placement="topLeft" title={'Click to Change Assessment'}>
                                    <AiOutlineDelete style={{ color: '#760fb2', cursor: 'pointer', height: '22px', width: '22px', marginRight: '10px' }} onClick={() => deleteAssessment(selectedAssessment)} />
                                </Tooltip> */}
                            </>
                        }
                    </div>
                    <div className="assessment-view-personal">
                        <p className="assessment-view-personal-heading">Personal History</p>
                        <div className="personal-view-que-ans">
                            {
                                selectedAssessment?.answer_list[0].questions_list.map((d: any) => {
                                    return (
                                        <>
                                            {d?.options?.length ?
                                                <>
                                                    {d?.is_multiselect == 'Y' ?
                                                        <div className="personal-view-que-ans-inner">
                                                            <div className="personal-view-que-ans-left"><span>{d?.order_no}. {d?.question}</span> <span>:</span></div>
                                                            <ul>
                                                                {
                                                                    d?.answer?.split('<->').map((ans: any) => {
                                                                        return (
                                                                            <li style={{ fontSize: '14px', fontWeight: '600' }}>{ans}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            {d?.other_answer && <div className="assessment-view-ans">{d?.other_answer}</div>}
                                                        </div>
                                                        :
                                                        <div className="personal-view-que-ans-inner">
                                                            <div className="personal-view-que-ans-left"><span>{d?.order_no}. {d?.question}</span> <span>:</span></div>
                                                            <div className="personal-view-que-ans-right">{d?.answer}</div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div className="personal-view-que-ans-inner">
                                                    <div className="personal-view-que-ans-left"><span>{d?.order_no}. {d?.question}</span> <span>:</span></div>
                                                    <div className="personal-view-que-ans-right">{d?.answer}</div>
                                                </div>
                                            }

                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="assessment-view-other">
                        {
                            selectedAssessment?.answer_list.slice(1).map((d: any) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="assessment-view-other-heading">
                                            {d?.section_label}
                                        </span>

                                        {
                                            d?.questions_list.map((que: any) => {
                                                return (
                                                    <>
                                                        {
                                                            que?.options?.length ?
                                                                <>
                                                                    {que?.is_multiselect == 'Y' ?
                                                                        <div className="assessment-view-que-ans">
                                                                            <span className="assessment-view-que">{que?.order_no}. {que?.question}</span>
                                                                            <ul>
                                                                                {
                                                                                    que?.answer?.split('<->').map((ans: any) => {
                                                                                        return (
                                                                                            <li style={{ fontSize: '14px', fontWeight: '600' }}>{ans}</li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                            {que?.other_answer && <div className="assessment-view-ans">{que?.other_answer}</div>}
                                                                        </div>
                                                                        :
                                                                        <div className="assessment-view-que-ans">
                                                                            <span className="assessment-view-que">{que?.order_no}. {que?.question}</span>
                                                                            <div className="assessment-view-ans">{que?.answer}</div>
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                <div className="assessment-view-que-ans">
                                                                    <span className="assessment-view-que">{que?.order_no}. {que?.question}</span>
                                                                    <div className="assessment-view-ans">{que?.answer}</div>
                                                                </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            }
            {
                showCloseButton &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                    <button
                        className="add-que-button"
                        onClick={() => {
                            window.parent.postMessage("cancelAssessment", "*")
                        }
                        }
                    >
                        Close
                    </button>
                </div>
            }
        </div>
    )
}

export default DietAssessmentView