import CRYPTO from "crypto-js";

export const getEncryptedText = (data: any) => {
  // var stringifiedJson = JSON.stringify(data)
  var truncHexKey = CRYPTO.SHA256("9Ddyaf6rfywpiTvTiax2iq6ykKpaxgJ6")
    .toString()
    .substr(0, 32); // hex encode and truncate

  var key = CRYPTO.enc.Utf8.parse(truncHexKey);

  var iv = CRYPTO.enc.Utf8.parse("9Ddyaf6rfywpiTvT");

  var ciphertext = CRYPTO.AES.encrypt(
    JSON.stringify(JSON.stringify(data)),
    key,
    {
      iv: iv,
      mode: CRYPTO.mode.CBC,
    }
  );

  return ciphertext.toString();
};

//In the above function they are stringifying twice so added the below one
export const getEncryptedBody = (data: any) => {
  // var stringifiedJson = JSON.stringify(data)
  var truncHexKey = CRYPTO.SHA256("9Ddyaf6rfywpiTvTiax2iq6ykKpaxgJ6")
    .toString()
    .substr(0, 32); // hex encode and truncate

  var key = CRYPTO.enc.Utf8.parse(truncHexKey);

  var iv = CRYPTO.enc.Utf8.parse("9Ddyaf6rfywpiTvT");

  var ciphertext = CRYPTO.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CRYPTO.mode.CBC,
  });

  return ciphertext.toString();
};

export const getDecryptedData = (cipher: string) => {
  var truncHexKey = CRYPTO.SHA256("9Ddyaf6rfywpiTvTiax2iq6ykKpaxgJ6")
    .toString()
    .substr(0, 32); // hex encode and truncate

  var key = CRYPTO.enc.Utf8.parse(truncHexKey);

  var iv = CRYPTO.enc.Utf8.parse("9Ddyaf6rfywpiTvT");

  var decryptedData = CRYPTO.AES.decrypt(cipher, key, {
    iv: iv,
    mode: CRYPTO.mode.CBC,
  });

  return decryptedData.toString(CRYPTO.enc.Utf8);
};

export const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
) => {
  let debounceTimer: ReturnType<typeof setTimeout>;
  return function (this: undefined, ...args: Parameters<F>) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const secToMin = (sec: any) => {
  const totalSeconds = parseInt(sec || 0);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = [
    hours > 0 ? `${hours} h` : "",
    minutes > 0 ? `${minutes} min` : "",
    seconds > 0 ? `${seconds} sec` : "",
  ]
    .filter(Boolean)
    .join(" ");

  return formattedTime || "-";
};

// Function to truncate file name to first 5 and last 5 characters
export const truncate = (str: string = "") => {
  if (str.length > 25) {
    return str.slice(0, 10) + "..." + str.slice(-10);
  }
  return str; // If the name is shorter than or equal to 10 characters, display it fully
};

export const formatPrice = (price: string | number = "") => {
  if (!price) return null;

  const priceNumber = typeof price === "string" ? parseFloat(price) : price;
  if (isNaN(priceNumber)) return null;

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(priceNumber);
};
