import React, { useEffect, useState } from "react";
import "./Adherence-Graph.scss";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Select from "react-select";

import Spinner from "../Spinner/Spinner";

import API from "../../services/rest/api";
import useApi from "../../hooks/useApi";

import "./Adherence-Graph.scss";

type GraphArrayItem = {
  x_value: string;
  achieved_value: number;
  goal_value: number;
};

type GraphProps = {
  patient_id: any;
};

const timeOptions: any = [
  { label: "1 Day", value: "1D" },
  { label: "7 Days", value: "7D" },
  // {label: "30 Days", value: "30D"},
  // {label: "90 Days", value: "90D"},
  { label: "1 Month", value: "1M" },
  { label: "3 Months", value: "90D" },
  { label: "6 Months", value: "6M" },
  { label: "1 Year", value: "1Y" },
];

// const typeOptions = [
//     { value: 'carbs', label: 'Carbs' },
//     { value: 'fats', label: 'Fats' },
//     { value: 'protein', label: 'Protein' },
// ]

const AdherenceGraph: React.FC<GraphProps> = ({ patient_id }) => {
  const [takenTime, setTakenTime] = useState("7D");
  const [filterType, setFilterType] = useState("carbs");
  const [adherenceData, setAdherenceData] = useState([]);
  const {
    mutate: getAdherenceData,
    response: adherenceResponse,
    error: adherenceError,
    isLoading: isAdherenceLoading,
  } = useApi(API.GetCaloriesAdherence);

  useEffect(() => {
    if (patient_id) {
      const payload = {
        // "todays_date": "2024-07-04",
        patient_id: patient_id,
        taken_time: takenTime,
      };
      getAdherenceData(payload);
    }
  }, [takenTime, patient_id]);

  useEffect(() => {
    console.log("adherence", adherenceResponse, adherenceError);
    if (adherenceResponse?.code === 1) {
      setAdherenceData(adherenceResponse?.data?.finalData);
    } else {
      setAdherenceData([]);
    }
    if (adherenceError) {
      console.error("Adherence Error", adherenceError);
    }
  }, [adherenceResponse, adherenceError]);

  const handleTakenTimeChange = (value: any) => {
    setTakenTime(value?.value);
  };

  const handleFilterChange = (option: any) => {
    setFilterType(option?.value);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "23px",
      height: "23px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "6px 6px",
      fontSize: "10px",
      marginTop: "-5px",
      minWidth: "84px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "23px",
      marginTop: "-2px",
    }),
  };

  const caloriesData: GraphArrayItem[] = adherenceData?.map((data: any) => ({
    achieved_value: data?.achieved_calories,
    goal_value: data?.target_calories,
    x_value: data?.x_value,
  }));

  const macronutrients = ["carbs", "fats", "protein"];

  const macronutrientsData: { [key: string]: GraphArrayItem[] } = {
    carbs: adherenceData?.map((data: any) => ({
      achieved_value: data[`achieved_carbs`],
      goal_value: data[`total_carbs`],
      x_value: data?.x_value,
    })),
    fats: adherenceData?.map((data: any) => ({
      achieved_value: data[`achieved_fats`],
      goal_value: data[`total_fats`],
      x_value: data?.x_value,
    })),
    protein: adherenceData?.map((data: any) => ({
      achieved_value: data[`achieved_protein`],
      goal_value: data[`total_protein`],
      x_value: data?.x_value,
    })),
  };

  return (
    <div className="adherence-graph">
      {isAdherenceLoading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="adherence-container">
            <div className="diet-adherence-header">
              <span className="diet-adherence-heading">Adherence Graph</span>
              <Select
                value={timeOptions.find(
                  (option: any) => option.value == takenTime
                )}
                onChange={handleTakenTimeChange}
                options={timeOptions}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary25: "#F0E7F7",
                    primary: "#760fb2",
                  },
                })}
                styles={customStyles}
              />
            </div>
            <div className="graph-wrapper" style={{ width: "50%" }}>
              <div className="graph-container">
                <div className="graph-title">Calories Graph</div>
                <div className="graph">
                  <LineChart
                    width={400}
                    height={300}
                    data={caloriesData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x_value" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="goal_value"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                      name="Goal Value"
                    />
                    <Line
                      type="monotone"
                      dataKey="achieved_value"
                      stroke="#82ca9d"
                      name="Achieved Value"
                    />
                  </LineChart>
                </div>
              </div>
            </div>
          </div>

          <div className="adherence-container">
            <div className="diet-adherence-header">
              {/* <span className="diet-adherence-heading">
            Macronutrient Adherence Graph
          </span> */}

              {/* <div className="diet-adherence-filter">
                <Select
                    value={typeOptions.find((option: any) => option.value == filterType)}
                    onChange={handleFilterChange}
                    options={typeOptions}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 6,
                        colors: {
                            ...theme.colors,
                            primary25: '#F0E7F7',
                            primary: '#760fb2',
                        },
                    })}
                    styles={customStyles}
                />
            </div> */}
            </div>
            <div className="graph-wrapper">
              {macronutrients.map((macronutrient) => {
                return (
                  <div className="graph-container">
                    <div className="graph-title">{macronutrient} Graph</div>
                    <div className="graph">
                      <LineChart
                        className="graph"
                        width={400}
                        height={300}
                        data={macronutrientsData[macronutrient]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="x_value" />
                        <YAxis
                        //tickFormatter={(value: any) => `${value}%`}
                        />
                        <Tooltip
                          formatter={(value: any, name) => [
                            `${value.toFixed(2)}`,
                            name,
                          ]}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="goal_value"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                          name="Goal Value"
                        />
                        <Line
                          type="monotone"
                          dataKey="achieved_value"
                          stroke="#82ca9d"
                          name="Achieved Value"
                        />
                      </LineChart>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdherenceGraph;
