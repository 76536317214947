import React, { useState } from "react";

import "./AssessmentSideDrawer.scss";
import Physio from "../../../organisms/Progress/Physio/Physio";
import Tabs from "../../../atoms/Tabs/Tabs";
import AssessmentWrapper from "../AssessmentWrapper/AssessmentWrapper";

const AssessmentSideDrawer: React.FC<any> = () => {
  const tabs = [
    {
      label: "Goals",
      value: "GOALS",
    },
    {
      label: "Assessment",
      value: "ASSESSMENT",
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <div className="assessment-side-drawer">
      <div className="tabs-container">
        <Tabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      </div>
      <div className="tabs-content">
        {activeTab === "ASSESSMENT" ? (
          <AssessmentWrapper />
        ) : activeTab === "GOALS" ? (
          <Physio mode="view" />
        ) : null}
      </div>
    </div>
  );
};

export default AssessmentSideDrawer;
