import { useEffect, useRef, useState } from 'react';
import { message, Modal, Popover, Select, Spin, Switch, TimePicker, Input as AntdInput } from 'antd';
import { RiDeleteBin6Line, RiPencilFill } from 'react-icons/ri';
import { InfoCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import dayjs from 'dayjs';

import Loader from '../../../atoms/Loader';
import Input from '../../../atoms/Input/Input';
import { mealTypes } from '../../../constants';
import API from '../../../services/rest/api';
import NutrientWarningModal from './NutrientWarningModal';
import { NUTRIENT_MESSAGE } from '../../../constants/diet';

import './index.scss';

interface AddDietPlanTemplate {
    setAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
    showCloseConfirm: VoidFunction;
    editModal?: boolean;
    templateId?: string | null;
    dataAdded: boolean;
    setDataAdded: React.Dispatch<React.SetStateAction<boolean>>;
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedTemplateId: React.Dispatch<React.SetStateAction<string | null>>;
};

const SUM_ERROR_MESSAGE = 'The total sum of the percentages for protein, carbs, and fat must be exactly 100.';
const { FAT_INFO, CARBS_INFO, PROTEIN_INFO } = NUTRIENT_MESSAGE;

const AddDietPlanTemplate: React.FC<AddDietPlanTemplate> = (props) => {
    const { setAddModalOpen, setRefreshList, showCloseConfirm, editModal = false, templateId = null, setDataAdded, dataAdded, setEditModal, setSelectedTemplateId } = props;
    const uniqueId = uuidv4();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [selectedMealType, setSelectedMealType] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<any>();
    const [draggedItem, setDraggedItem] = useState<any>(null);

    const [dietTemplateData, setDietTemplateData] = useState<any>({});

    const [visibleInputField, setVisibleInputField] = useState<boolean>(false);
    const [editMealName, setEditMealName] = useState<string | null>(null);
    const [newMealName, setNewMealName] = useState<any>(null);

    const [tipError, setTipError] = useState<any>(null);
    const [foodItemOptions, setFoodItemOptions] = useState<any>([]);
    const [actualCalories, setActualCalories] = useState<string>('0');
    const [actualCarbs, setActualCarbs] = useState<string>('0');
    const [actualProtein, setActualProtein] = useState<string>('0');
    const [actualFat, setActualFat] = useState<string>('0');

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');

    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);

    useEffect(() => {
        if (successMessage) setTimeout(() => setSuccessMessage(''), 3000);
    }, [successMessage]);

    useEffect(() => {
        if (editModal && templateId) {
            fetchTemplateDataById(templateId);
        } else {
            const mealsData = mealTypes.map((meal) => ({
                meal_types_id: meal.meal_types_id,
                meal_name: meal.meal_type,
                order_no: meal.order_no,
                start_time: meal.default_time,
                end_time: moment(meal.default_time, 'HH:mm:ss').add(1, 'hours').format('HH:mm:ss'),
                patient_permission: 'R',
                options: [
                    { options_name: 'Option 1', order_no: 1, food_items: [{ is_deleted: 'N' }] },
                    { options_name: 'Option 2', order_no: 2, food_items: [{ is_deleted: 'N' }] }
                ],
                is_hidden: 'N'
            }));

            const emptyTemplate = {
                template_name: '',
                actual_calories: null,
                total_calories: null,
                proteins: null,
                fat: null,
                carbs: null,
                meals_data: mealsData
            };

            setDietTemplateData(emptyTemplate);
        }
    }, [editModal, templateId]);

    useEffect(() => {
        const mealsData = dietTemplateData?.meals_data;

        if (mealsData && mealsData.length) {
            if (!selectedMealType) {
                setSelectedMealType(mealsData[0]?.meal_types_id);
            }

            const options = mealsData[0]?.options;
            if (!selectedOption && options && options.length) {
                setSelectedOption(options[0]?.order_no);
            }
        }
    }, [dietTemplateData]);

    useEffect(() => {
        const temp = { ...dietTemplateData };
        let totalCalories = 0;
        let totalCarbs = 0;
        let totalProtein = 0;
        let totalFats = 0;

        temp?.meals_data?.forEach((meal: any) => {
            let mealTotalCalories = 0;
            let validOptionsCount = 0;
            let mealTotalCarbs = 0;
            let mealTotalProtein = 0;
            let mealTotalFats = 0;

            meal?.options?.forEach((op: any) => {
                const validItems = op?.food_items?.filter((item: any) => item?.is_deleted === 'N') || [];
                op.total_calories = validItems.reduce((acc: number, item: any) => {
                    const quantity = parseFloat(item.quantity || '0');
                    return acc + parseFloat(item.calories || '0') * quantity;
                }, 0).toString();

                mealTotalCarbs += validItems.reduce((acc: number, item: any) => {
                    const quantity = parseFloat(item.quantity || '0');
                    return acc + parseFloat(item.carbs || '0') * quantity;
                }, 0);

                mealTotalProtein += validItems.reduce((acc: number, item: any) => {
                    const quantity = parseFloat(item.quantity || '0');
                    return acc + parseFloat(item.protein || '0') * quantity;
                }, 0);

                mealTotalFats += validItems.reduce((acc: number, item: any) => {
                    const quantity = parseFloat(item.quantity || '0');
                    return acc + parseFloat(item.fats || '0') * quantity;
                }, 0);

                if (parseFloat(op.total_calories) > 0) {
                    mealTotalCalories += parseFloat(op.total_calories);
                    validOptionsCount++;
                }

            });

            meal.meal_total_calories = (
                mealTotalCalories / (validOptionsCount || 1)
            ).toString();

            totalCarbs += (mealTotalCarbs / (validOptionsCount || 1));
            totalProtein += (mealTotalProtein / (validOptionsCount || 1));
            totalFats += (mealTotalFats / (validOptionsCount || 1));
            totalCalories += parseFloat(meal.meal_total_calories);
        });

        setActualCarbs(totalCarbs.toFixed(3));
        setActualCalories(totalCalories.toFixed(3));
        setActualProtein(totalProtein.toFixed(3));
        setActualFat(totalFats.toFixed(3));

        if (errorMessage) setErrorMessage('');
    }, [dietTemplateData]);

    const onKeyDownForNumber = (e: React.KeyboardEvent<HTMLInputElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const convertToTitleCase = (input: string): string => {
        let result = input.replace(/_/g, ' ');
        result = result
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

        return result;
    };

    const handleDragStart = (e: any, item: any) => {
        setDraggedItem(item);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', JSON.stringify(item));
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any, type: any) => {
        e.preventDefault();
        const droppedItem = JSON.parse(e.dataTransfer.getData('text'));
        const draggedIndex = dietTemplateData.meals_data.findIndex((item: any) => item === draggedItem);
        const droppedIndex = dietTemplateData.meals_data.findIndex((item: any) => item === type);

        if (draggedIndex > -1 && droppedIndex > -1) {
            const updatedMeals = [...dietTemplateData.meals_data];
            updatedMeals.splice(draggedIndex, 1);
            updatedMeals.splice(droppedIndex, 0, droppedItem);

            const updatedMealsWithOrder = updatedMeals.map((meal, index) => ({
                ...meal,
                order_no: index + 1,
            }));

            setDietTemplateData({ ...dietTemplateData, meals_data: updatedMealsWithOrder });
        }
    };

    const onChangePlanDetails = (key: string, value: any, type?: 'string' | 'number') => {
        try {
            if (type === 'number') value = parseFloat(value.replace(/[^0-9] | ./g, ''));
            setDietTemplateData((prevData: any) => ({ ...prevData, [key]: value }));
            if (errorMessage) setErrorMessage('');
            if (!dataAdded) setDataAdded(true);
        } catch (error) {
            console.error(error);
            message.error('Something went wrong while changing the value!');
        }
    };

    const onAddMeal = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter' || !newMealName) return;

        const mealsData = dietTemplateData?.meals_data || [];
        const newMealPayload = {
            is_additional_meal: true,
            meal_types_id: uniqueId,
            meal_name: newMealName,
            order_no: mealsData.length + 1,
            start_time: '00:00:00',
            end_time: '01:00:00',
            patient_permission: 'R',
            options: [
                { options_name: 'Option 1', order_no: 1, food_items: [{ is_deleted: 'N' }] },
                { options_name: 'Option 2', order_no: 2, food_items: [{ is_deleted: 'N' }] }
            ],
            is_hidden: 'N',
        };
        const updatedMealsData = [...mealsData, newMealPayload];

        setNewMealName(null);
        setSelectedMealType(newMealPayload.meal_types_id);
        setVisibleInputField(false);
        setDietTemplateData((prevData: any) => ({ ...prevData, meals_data: updatedMealsData }));
    };

    const updateMealData = (mealIndex: number, key: string, value: string | boolean) => {
        setDietTemplateData((prevData: any) => {
            const updatedMeals = [...prevData.meals_data];
            updatedMeals[mealIndex] = { ...updatedMeals[mealIndex], [key]: value };
            return { ...prevData, meals_data: updatedMeals };
        });
        if (!dataAdded) setDataAdded(true);
    };

    const addOption = (mealIndex: number) => {
        setDietTemplateData((prevData: any) => {
            const updatedMeals = [...prevData.meals_data];
            const currentOptions = updatedMeals[mealIndex].options || [];
            const newOption = {
                options_name: `Option ${currentOptions.length + 1}`,
                order_no: currentOptions.length + 1,
                food_items: [{ is_deleted: 'N' }]
            };

            updatedMeals[mealIndex] = {
                ...updatedMeals[mealIndex],
                options: [...currentOptions, newOption]
            };
            return { ...prevData, meals_data: updatedMeals };
        });
        if (!dataAdded) setDataAdded(true);
    };

    const handleOptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>, mealIndex: number, opIdx: number) => {
        const { value, name } = e.target;
        if (!name) return;

        setDietTemplateData((prevData: any) => {
            const updatedMeals = [...prevData.meals_data];
            const updatedOptions = [...updatedMeals[mealIndex].options];

            updatedOptions[opIdx] = {
                ...updatedOptions[opIdx],
                [name]: value,
            };
            updatedMeals[mealIndex] = {
                ...updatedMeals[mealIndex],
                options: updatedOptions,
            };
            return { ...prevData, meals_data: updatedMeals };
        });
        if (!dataAdded) setDataAdded(true);
    };

    const calculateTotal = (items: any[], nutrient: string) => {
        return items
            .filter(item => item?.is_deleted !== 'Y')
            .reduce((total, item) => total + (parseFloat(item[nutrient] || '0') * parseFloat(item.quantity || '0')), 0)
            .toFixed(2);
    };

    const getFoodItemNutritions = async (foodItem: any): Promise<any | null> => {
        const payload = {
            food_id: foodItem.FOOD_ID,
            unit_id: foodItem.UNIT_ID
        };

        try {
            const response = await API.GetFoodItemNutritions(payload);
            if (response.code === 1) {
                return response.data;
            } else {
                console.error('Unable to fetch food item nutritions: Response code not 1');
                message.error('Unable to fetch food item nutritions: Response code not 1');
                return null;
            }
        } catch (error) {
            console.error('An error occurred while fetching food item nutritions:', error);
            message.error('An error occurred while fetching food item nutritions!');
            return null;
        }
    };

    const getFoodItemsOptions = async (value: string) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        if (value) {
            timeoutRef.current = setTimeout(async () => {
                try {
                    setFoodItemOptions([]);
                    const payload = { page: 1, food_name: value };
                    const response = await API.GetFoodItemsOptions(payload);

                    if (response.code === 1) {
                        const newData = response.data?.map((d: any) => ({
                            ...d,
                            label: d.FOOD_NAME,
                            value: d.FOOD_ID
                        })) || [];
                        setFoodItemOptions(newData);
                    } else {
                        console.error('Failed to fetch food item options: Response code not 1');
                        setFoodItemOptions([]);
                    }
                } catch (error) {
                    console.error('An error occurred while fetching food item options:', error);
                    setFoodItemOptions([]);
                }
            }, 300);
        } else {
            setFoodItemOptions([]);
        }
    };

    const handleFoodItemChange = (e: React.ChangeEvent<HTMLInputElement>, mealIdx: number, opIdx: number, itemIdx: number) => {
        const { value, name } = e.target;
        setDietTemplateData((prevData: any) => {
            const updatedData = { ...prevData };
            const { meals_data } = updatedData;

            if (name) {
                meals_data[mealIdx].options[opIdx].food_items[itemIdx] = {
                    ...meals_data[mealIdx].options[opIdx].food_items[itemIdx],
                    [name]: value
                };
            }
            return updatedData;
        });
        if (!dataAdded) setDataAdded(true);
    };

    const createSaveTemplatePayload = () => {
        try {
            if (!dietTemplateData) return;
            const { meals_data = [], id, ...templateDetails } = dietTemplateData;
            const payload = {
                ...templateDetails,
                actual_calories: Number(actualCalories || 0),
            };

            const newMealData = meals_data.map((meal: any) => {
                const { options = [], patient_permission, meal_total_calories, is_hidden, is_additional_meal, ...otherMealData } = meal;

                const newOptions = options.flatMap((option: any, _j: number) =>
                    option?.food_items?.filter((item: any) => item.food_item_id).map((foodItem: any, k: number) => ({
                        tips: option.tips || 'No Tips',
                        option_no: option?.order_no?.toString(),
                        food_item_id: foodItem.food_item_id,
                        food_name: foodItem.food_item_name,
                        quantity: foodItem.quantity,
                        unit_option_id: foodItem.measure_id,
                        unit_option_name: foodItem.measure_name,
                        order_no: k + 1
                    }))
                );

                return { ...otherMealData, options: newOptions, hide_meal: is_hidden };
            });

            return { ...payload, meals_data: newMealData };
        } catch (error) {
            console.error(error);
            message.error('Error while creating payload!');
        }
    };

    const validateTemplateData = (data: Record<string, any>) => {
        const keyMapToValidate: any = {
            'template_name': 'Template Name',
            'total_calories': 'Total Calories Goal',
            'actual_calories': 'Actual Calories',
            'proteins': 'Protien Percentage',
            'fat': 'Fat Percentage',
            'carbs': 'Carbs Percentage',
        };
        const inValidKeys = [];
        for (const key of Object.keys(keyMapToValidate)) {
            if (data[key] === null || data[key] === '' || data[key] === 0 || (key !== 'template_name' && isNaN(data[key]))) {
                inValidKeys.push(keyMapToValidate[key])
            }
        }
        return inValidKeys;
    };

    const onPressSaveTemplate = async () => {
        try {
            const templateDetails = createSaveTemplatePayload();
            const inValidKeys = validateTemplateData(templateDetails);
            if (inValidKeys.length) {
                if (inValidKeys.length == 1) setErrorMessage(`${inValidKeys.join(', ')} fields is required!`);
                else setErrorMessage(`The following field are required: ${inValidKeys.join(', ')}`);
                return;
            }

            if (!isValidNutrientPercentage()) {
                setErrorMessage(SUM_ERROR_MESSAGE);
                return;
            }
            let inValidNutrientValues = compareNutrientValues();
            if (inValidNutrientValues.length > 0) {
                setWarningModal(true);
                return;
            }

            if (templateDetails.actual_calories > templateDetails.total_calories) {
                Modal.confirm({
                    type: 'warning',
                    title: 'Actual calories greater than Total Calories Goal!',
                    content: 'Do you still want to proceed?',
                    centered: true,
                    async onOk() {
                        // if (editModal && templateId) {
                        //     await updateDietPlanTemplate(templateDetails);
                        // } else {
                        //     await saveDietPlanTemplate(templateDetails);
                        // }
                        await submitAction(templateDetails);
                    },
                });
                return;
            }

            // if (editModal && templateId) {
            //     await updateDietPlanTemplate(templateDetails);
            // } else {
            //     await saveDietPlanTemplate(templateDetails);
            // }
            await submitAction(templateDetails)
        } catch (error) {
            console.log(error);
            message.error('Error while creating template!');
        }
    };

    const submitAction = async (templateDetails: any) => {
        if (editModal && templateId) {
            await updateDietPlanTemplate(templateDetails);
        } else {
            await saveDietPlanTemplate(templateDetails);
        }
    };

    const saveDietPlanTemplate = async (templateDetails: any) => {
        try {
            setSaveLoading(true);
            const saveTemplate = await API.CreateDietTemplate(templateDetails);
            if (Number(saveTemplate.code) != 1) {
                message.error(saveTemplate?.message || 'Error while creating template!');
                return;
            }
            message.success('Template Created Successfully.');
            setSelectedTemplateId(null);
            setEditModal(false);
            setAddModalOpen(false);
            setRefreshList(true);
        } catch (error) {
            console.log(error);
            message.error('Error while creating template!');
        } finally {
            setSaveLoading(false);
        }
    };

    const updateDietPlanTemplate = async (templateDetails: any) => {
        try {
            setSaveLoading(true);
            const saveTemplate = await API.UpdateTemplateList(dietTemplateData?.id, templateDetails);
            if (Number(saveTemplate.code) != 1) {
                message.error(saveTemplate?.message || 'Error while updating template!');
                return;
            }
            message.success('Template Update Successfully.');
            setSelectedTemplateId(null);
            setEditModal(false);
            setAddModalOpen(false);
            setRefreshList(true);
        } catch (error) {
            console.log(error);
            message.error('Error while updating template!');
        } finally {
            setSaveLoading(false);
        }
    };

    const fetchTemplateDataById = async (templateId: string) => {
        try {
            setLoading(true);
            const templateData: any = await API.GetDietTemplateByID(templateId);
            if (templateData.code !== 1) throw new Error(templateData.msg || 'Internal server error!');
            const modifiedData = modifyResponse(templateData.data || {});
            setDietTemplateData(modifiedData);
        } catch (error) {
            console.log('Error fetching template date:', error);
            message.error({ content: 'Failed to fetch template data!' });
        } finally {
            setLoading(false);
        }
    };

    const modifyResponse = (data: any) => {
        try {
            const { meals_data, _id, chief_health_coach_id, created_at, updated_at, __v, ...otherData } = data;
            const response: any = { ...otherData, id: _id };

            const newMealData = meals_data.map(({ options, _id, ...otherMealData }: any) => {
                const newOptions = (options && options.length > 0) ? options.map((option: any) => ({
                    order_no: option.option_no,
                    options_name: `Option ${option.option_no}`,
                    total_calories: option.meal_total_calories,
                    tips: option.tips,
                    food_items: option.food_items.map(({ option_no, food_item_name, unit_option_id, unit_option_name, _id, ...otherData }: any) => ({
                        is_deleted: 'N',
                        ...otherData,
                        food_item_name: otherData.food_name,
                        measure_name: unit_option_name,
                        measure_id: unit_option_id,
                        protein: otherData.protein / Number(otherData.quantity),
                        carbs: otherData.carbs / Number(otherData.quantity),
                        fiber: otherData.fiber / Number(otherData.quantity),
                        calories: otherData.calories / Number(otherData.quantity),
                        fats: otherData.fats / Number(otherData.quantity),
                        sodium: otherData.sodium / Number(otherData.quantity),
                        potassium: otherData.potassium / Number(otherData.quantity),
                        sugar: otherData.sugar / Number(otherData.quantity),
                        fatty_acid: otherData.fatty_acid / Number(otherData.quantity),
                    })),
                })) : [
                    { options_name: 'Option 1', order_no: 1, food_items: [{ is_deleted: 'N' }] },
                    { options_name: 'Option 2', order_no: 2, food_items: [{ is_deleted: 'N' }] }
                ];

                return {
                    ...otherMealData,
                    options: newOptions,
                    patient_permission: 'R',
                    is_hidden: otherMealData.hide_meal,
                };
            });

            response.meals_data = newMealData;
            setActualCalories(response?.actual_calories?.toString());
            return response;
        } catch (error) {
            console.log('Error while modifying template data:', error);
            message.error({ content: 'Error while modifying template data!' });
        }
    };

    const onWarningModalSubmit = async () => {
        const templateDetails = createSaveTemplatePayload();
        await submitAction(templateDetails);
    };

    const renderInfoPopover = (text: string) => {
        return (
            <div style={{ width: '250px', margin: '0px !important' }}>
                {/* {text} */}
                <div dangerouslySetInnerHTML={{ __html: text?.replace(/\n/g, '<br>') }}></div>
            </div>
        );
    };

    const isValidNutrientPercentage = () =>
        parseFloat(dietTemplateData?.fat || '0') +
        parseFloat(dietTemplateData?.proteins || '0') +
        parseFloat(dietTemplateData?.carbs || '0') === 100;

    /**
     * Calculates the actual nutrient amount in grams based on the nutrient type (protein, carbs, or fat)
     * using the total calories from the diet template data.
     * 
     * @param {string} key - The nutrient type ('proteins', 'carbs', or 'fat') to calculate.
     * @returns {number} The calculated nutrient amount in grams, or 0 if the key is invalid.
     * 
     * Note: If the key is 'fat', divide by 9; for 'carbs' and 'proteins', divide by 4.
     */
    const calculateActualNutrientInGram = (key: string): number => {
        const totalCalories = parseFloat(dietTemplateData?.total_calories || '0');
        const nutrientValue = parseFloat(dietTemplateData?.[key] || '0');
        const caloriePerGram = key === 'fat' ? 9 : 4; // If the key is 'fat', divide by 9; for 'carbs' and 'proteins', divide by 4.

        return key === 'proteins' || key === 'carbs' || key === 'fat'
            ? Number(((nutrientValue * totalCalories) / (caloriePerGram * 100)).toFixed(3))
            : 0;
    };

    /**
     * Compares the actual nutrient values (calories, protein, fat, and carbs) against the goal values 
     * from the diet template and returns an array of objects with discrepancies.
     * 
     * @returns {Array} An array of objects containing the nutrient key, goal, and actual values 
     * where the actual exceeds the goal.
     */
    const compareNutrientValues = (): any[] => {
        const finalDataObject: any[] = [];
        const nutrientKeys = [
            { key: 'actual_calories', actual: parseFloat(actualCalories || '0'), goal: parseFloat(dietTemplateData.total_calories || '0') },
            { key: 'protein', actual: parseFloat(actualProtein || '0'), goal: calculateActualNutrientInGram('proteins') },
            { key: 'fat', actual: parseFloat(actualFat || '0'), goal: calculateActualNutrientInGram('fat') },
            { key: 'carbs', actual: parseFloat(actualCarbs || '0'), goal: calculateActualNutrientInGram('carbs') },
        ];

        nutrientKeys.forEach(({ key, actual, goal }) => {
            if (actual > goal) {
                finalDataObject.push({ key, goal, actual });
            }
        });

        return finalDataObject;
    };


    const nutrientCustomInput = (label: string, value: string) => {
        return (
            <div style={{ marginTop: '5px' }}>
                <label className='custom_label'>{label}</label>
                <AntdInput
                    disabled
                    value={value}
                    style={{ width: '80%', color: '#760fb2', marginTop: '5px' }} />
            </div>
        )
    };

    return (
        <>
            {loading
                ? <Loader noBackground />
                : <>
                    <div className='diet-input-container'>
                        <div className='diet-input'>
                            <Input
                                label='Name of the Diet Plan'
                                placeholder='Name of the Diet Plan'
                                type='text'
                                name='template_name'
                                id='template_name'
                                required
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePlanDetails('template_name', e.target.value)}
                                value={dietTemplateData?.template_name || null}
                            />
                            <div className='nutrient_input' >
                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <Input
                                        label='Protein Percentage(%)'
                                        placeholder='Protein Percentage'
                                        type='number'
                                        name='proteins'
                                        id='proteins'
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) onChangePlanDetails('proteins', e.target.value, 'number') }}
                                        value={dietTemplateData?.proteins || null}
                                        onKeyDown={onKeyDownForNumber}
                                    />
                                    <Popover
                                        content={renderInfoPopover(PROTEIN_INFO)}
                                        trigger={'hover'}
                                        style={{ padding: '0px !important' }}
                                    >
                                        <InfoCircleOutlined style={{ cursor: 'pointer', opacity: '0.5' }} />
                                    </Popover>
                                </div>
                                <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                                    {nutrientCustomInput('Goal (gm)', calculateActualNutrientInGram('proteins').toString())}
                                    {nutrientCustomInput('Actual (gm)', actualProtein.toString())}
                                </div>
                            </div>
                        </div>

                        <div className='diet-input'>
                            <Input
                                label='Total Calories Goal'
                                placeholder='Total Calories Goal'
                                type='number'
                                name='total_calories'
                                id='total_calories'
                                required
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePlanDetails('total_calories', e.target.value, 'number')}
                                value={dietTemplateData?.total_calories || null}
                                onKeyDown={onKeyDownForNumber}
                            />
                            <div className='nutrient_input'>
                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <Input
                                        label='Fat Percentage(%)'
                                        placeholder='Fat Percentage'
                                        type='number'
                                        name='fat'
                                        id='fat'
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) onChangePlanDetails('fat', e.target.value, 'number') }}
                                        value={dietTemplateData?.fat || null}
                                        onKeyDown={onKeyDownForNumber}
                                    />
                                    <Popover
                                        content={renderInfoPopover(FAT_INFO)}
                                        trigger={'hover'}
                                        style={{ padding: '0px !important' }}
                                    >
                                        <InfoCircleOutlined style={{ cursor: 'pointer', opacity: '0.5' }} />
                                    </Popover>
                                </div>
                                <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                                    {nutrientCustomInput('Goal (gm)', calculateActualNutrientInGram('fat').toString())}
                                    {nutrientCustomInput('Actual (gm)', actualFat.toString())}
                                </div>
                            </div>
                        </div>

                        <div className='diet-input'>
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <Input
                                    label='Actual Calories'
                                    placeholder='Actual Calories'
                                    type='number'
                                    name='actual_calories'
                                    id='actual_calories'
                                    required
                                    disabled
                                    value={actualCalories}
                                />
                                <Popover
                                    content={renderInfoPopover(`The 'Actual Calories' represent the sum of the average calories from each meal's options`)}
                                    trigger={'hover'}
                                    style={{ padding: '0px !important' }}
                                >
                                    <InfoCircleOutlined style={{ cursor: 'pointer', opacity: '0.5' }} />
                                </Popover>
                            </div>
                            <div className='nutrient_input'>
                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <Input
                                        label='Carbs Percentage(%)'
                                        placeholder='Carbs Percentage'
                                        type='number'
                                        name='carbs'
                                        id='carbs'
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (parseFloat(e.target.value || '0') <= 100) onChangePlanDetails('carbs', e.target.value, 'number') }}
                                        value={dietTemplateData?.carbs || null}
                                        onKeyDown={onKeyDownForNumber}
                                    />
                                    <Popover
                                        content={renderInfoPopover(CARBS_INFO)}
                                        trigger={'hover'}
                                        style={{ padding: '0px !important' }}
                                    >
                                        <InfoCircleOutlined style={{ cursor: 'pointer', opacity: '0.5' }} />
                                    </Popover>
                                </div>
                                <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                                    {nutrientCustomInput('Goal (gm)', calculateActualNutrientInGram('carbs').toString())}
                                    {nutrientCustomInput('Actual (gm)', actualCarbs.toString())}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        {(errorMessage && !isValidNutrientPercentage())
                            && <span style={{ color: 'red', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{SUM_ERROR_MESSAGE}</span>}
                    </div>

                    <div className='diet-schedule-container' style={{ overflow: 'auto' }}>
                        {(dietTemplateData?.meals_data || []).map((type: any, typeIndex: number) => {
                            const isActivated = selectedMealType === type?.meal_types_id;
                            const options = type?.options || [];
                            const mealTypeId = type?.meal_types_id;
                            const mealName = type?.meal_name;

                            // const mealTotalCalories = Number(type?.meal_total_calories ?? 0);
                            // const totalCalories = Number(dietTemplateData?.total_calories ?? 0);
                            // const caloriesReachedForMeal = totalCalories > 0
                            //     ? +(mealTotalCalories / totalCalories * 100).toFixed(2)
                            //     : 0;

                            return (
                                <>
                                    {!type?.is_edit_name ? (
                                        <div
                                            className={isActivated ? 'diet-schedule-activate' : 'diet-schedule'}
                                            onClick={() => {
                                                setSelectedMealType(mealTypeId);
                                                if (options.length > 0) setSelectedOption(options[0].order_no);
                                            }}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, type)}
                                            onDragOver={handleDragOver}
                                            onDrop={(e) => handleDrop(e, type)}
                                        >
                                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>{mealName}</span>
                                                {!!(caloriesReachedForMeal && !isNaN(caloriesReachedForMeal)) &&
                                                    <span style={{ fontSize: '10px', display: 'flex', gap: '2px', justifyContent: 'center' }}>
                                                        {`(${caloriesReachedForMeal}% reached)`}
                                                        <div style={{ right: '5px', top: '1px', fontSize: '10.5px' }}>
                                                            <Popover
                                                                content={renderInfoPopover(`You've consumed <b>${caloriesReachedForMeal}%</b> of your daily calorie goal based on the average total calories of this meal's all option.`)}
                                                                trigger={'hover'}
                                                                style={{ padding: '0px !important' }}
                                                                placement='bottom'
                                                            >
                                                                <InfoCircleOutlined style={{ cursor: 'pointer', opacity: '0.5' }} />
                                                            </Popover>
                                                        </div>
                                                    </span>
                                                }
                                            </div> */}
                                            {mealName}
                                            {type?.is_additional_meal && (
                                                <RiPencilFill
                                                    style={{ color: '#760fb2', cursor: 'pointer', height: '15px', width: '15px', alignSelf: 'center', marginLeft: '10px', }}
                                                    onClick={() => {
                                                        const updatedDietPlan = { ...dietTemplateData };
                                                        updatedDietPlan.meals_data[typeIndex].is_edit_name = true;
                                                        setDietTemplateData(updatedDietPlan);
                                                        setEditMealName(mealName);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className='diet-schedule'>
                                            <Input
                                                label=''
                                                placeholder='Add Meal'
                                                type='text'
                                                name='meal'
                                                id='meal'
                                                value={editMealName}
                                                onChange={(e: any) => setEditMealName(e.target.value)}
                                                onKeyDown={(e: any) => {
                                                    if (e.key === 'Enter') {
                                                        const updatedDietPlan = { ...dietTemplateData };
                                                        updatedDietPlan.meals_data[typeIndex].meal_name = editMealName;
                                                        delete updatedDietPlan.meals_data[typeIndex].is_edit_name;
                                                        setDietTemplateData(updatedDietPlan);
                                                    }
                                                }}
                                                className='add-meal-input'
                                            />
                                        </div>
                                    )}
                                </>
                            );
                        })}

                        {!visibleInputField
                            ? <div className={'diet-add'}
                                style={dietTemplateData?.meals_data?.length >= 15 ? { display: "none" } : undefined}
                                onClick={() => {
                                    setVisibleInputField(true);
                                    if (!dataAdded) setDataAdded(true);
                                }}>+</div>
                            : <div className={'diet-schedule'}>
                                <Input
                                    label=''
                                    className='add-meal-input'
                                    placeholder='Add Meal'
                                    type='text'
                                    name='meal'
                                    id='meal'
                                    value={newMealName}
                                    onChange={(e: any) => setNewMealName(e.target.value)}
                                    onKeyDown={onAddMeal}
                                />
                            </div>
                        }
                    </div>

                    {(dietTemplateData.meals_data || []).map((meal: any, mealIndex: number) => (<>
                        {meal?.meal_types_id == selectedMealType && <>
                            <div className='breakfast-container'>
                                <div className='meal-timing-container'>
                                    <div>Meal timing</div>
                                    <div className='time-picker-container'>
                                        <TimePicker
                                            placeholder=''
                                            onChange={(_time, timeString) => {
                                                const formattedTime = moment(timeString, 'hh:mm a').format('HH:mm:ss');
                                                updateMealData(mealIndex, 'start_time', formattedTime);
                                            }}
                                            value={dayjs(meal.start_time, 'HH:mm:ss')}
                                            style={{ width: '110px' }}
                                            format={'hh:mm a'}
                                            allowClear={false}
                                            disabled={meal?.is_hidden === 'Y'}
                                        />
                                    </div>
                                    <div>to</div>
                                    <div className='time-picker-container'>
                                        <TimePicker
                                            placeholder=''
                                            onChange={(_time, timeString) => {
                                                const formattedTime = moment(timeString, 'hh:mm a').format('HH:mm:ss');
                                                updateMealData(mealIndex, 'end_time', formattedTime);
                                            }}
                                            value={dayjs(meal.end_time, 'HH:mm:ss')}
                                            style={{ width: '110px' }}
                                            format={'hh:mm a'}
                                            allowClear={false}
                                            disabled={meal?.is_hidden === 'Y'}
                                        />
                                    </div>
                                </div>

                                <div className='hide-meal'>
                                    <div className='hide-meal-title'>
                                        <span className='hide-meal-label'>Hide this meal</span>
                                        <Switch
                                            onChange={(checked: boolean) => updateMealData(mealIndex, 'is_hidden', checked ? 'Y' : 'N')}
                                            style={{ marginLeft: '15px' }}
                                            checked={meal.is_hidden === 'Y'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <>
                                <div className='diet-schedule-options'>
                                    {(meal?.options || []).map((op: any) => {
                                        return (
                                            <div className={selectedOption !== op?.order_no ? 'diet-schedule-option' : 'diet-option-activate'} onClick={() => { setSelectedOption(op?.order_no) }}>{op?.options_name}</div>
                                        )
                                    })}
                                    {(meal?.is_hidden == 'N' && meal?.options?.length <= 14) &&
                                        <div className='add-option-button' onClick={() => addOption(mealIndex)}>
                                            + Add Option
                                        </div>
                                    }
                                </div>

                                {(meal?.options || []).map((op: any, opIdx: number) => (
                                    op?.order_no === selectedOption && (
                                        <div className='option-container' key={opIdx}>
                                            <div style={{ marginBottom: '25px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <div className='total-diet-counts-title' style={{ width: 'auto' }}>Tips</div>
                                                    <div style={{ width: '100%' }}>
                                                        <textarea
                                                            placeholder=''
                                                            name='tips'
                                                            id='tips'
                                                            value={op.tips || ''}
                                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                                const tipsLength = e.target.value.length;
                                                                if (tipsLength > 2000) {
                                                                    setTipError('Tips length cannot exceed more than 2000 characters');
                                                                } else {
                                                                    setTipError(null);
                                                                }
                                                                handleOptionChange(e, mealIndex, opIdx);
                                                            }}
                                                            style={{ width: '100%', border: '1px solid #cecece', height: '100px', outline: 'none', color: '#760FB2' }}
                                                            disabled={meal?.is_hidden === 'Y'}
                                                        />
                                                        {tipError && <span style={{ color: 'red', fontSize: '14px' }}>{tipError}</span>}
                                                    </div>
                                                </div>
                                                <div className='total-diet-flex'>
                                                    {['calories', 'carbs', 'protein', 'fats', 'fibers', 'sodium', 'potassium', 'sugar', 'fatty_acids'].map(nutrient => (
                                                        <div className='total-diet-counts' key={nutrient}>
                                                            <div className='total-diet-counts-title'>Total {convertToTitleCase(nutrient)}</div>
                                                            <span>{calculateTotal(op?.food_items || [], nutrient)}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>

                                            {(op?.food_items || []).map((item: any, itemIndex: number) => {
                                                if (item?.is_deleted !== 'N') return null;

                                                const handleInputChange = (e: any, name: string) => handleFoodItemChange(e, mealIndex, opIdx, itemIndex);
                                                const handleDelete = async () => {
                                                    let temp = dietTemplateData;
                                                    if (item?.diet_plan_food_item_id) {
                                                        temp.meals_data[mealIndex].options[opIdx].food_items[itemIndex].is_active = 'N';
                                                        temp.meals_data[mealIndex].options[opIdx].food_items[itemIndex].is_deleted = 'Y';
                                                    } else {
                                                        temp.meals_data[mealIndex].options[opIdx].food_items.splice(itemIndex, 1);
                                                    }
                                                    setDietTemplateData({ ...temp });
                                                };

                                                const calculateNutrition = (value: string | number, quantity: string | number) =>
                                                    value === '0' ? '0' : (parseFloat(value as string || '0') * parseFloat(quantity as string || '0')).toFixed(2);

                                                return (
                                                    <div className='food-items-main-container'>
                                                        <div className='food-items-container'>
                                                            <div className='food-items'>
                                                                <span className='label'>
                                                                    Food Items <span style={{ color: 'red' }}>*</span>
                                                                </span>
                                                                <Select
                                                                    className='ant-select-selection'
                                                                    showSearch
                                                                    filterOption={false}
                                                                    onSearch={getFoodItemsOptions}
                                                                    options={foodItemOptions}
                                                                    onChange={async (e: any, option: any) => {
                                                                        let temp = dietTemplateData;
                                                                        const foodItemExists = temp.meals_data[mealIndex].options[opIdx].food_items.find(
                                                                            (fi: any) => fi?.food_item_id === option.value
                                                                        );
                                                                        if (foodItemExists) return;

                                                                        temp.meals_data[mealIndex].options[opIdx].food_items[itemIndex] = {
                                                                            ...temp.meals_data[mealIndex].options[opIdx].food_items[itemIndex],
                                                                            food_item_id: option.value,
                                                                            food_item_name: option.label,
                                                                            quantity: '1',
                                                                        };

                                                                        const foodItemNutritions = await getFoodItemNutritions(option);
                                                                        if (foodItemNutritions) {
                                                                            Object.assign(temp.meals_data[mealIndex].options[opIdx].food_items[itemIndex], {
                                                                                measure_name: option?.UNIT_NAME,
                                                                                measure_id: option?.UNIT_ID,
                                                                                ...foodItemNutritions,
                                                                            });
                                                                        }

                                                                        setDietTemplateData({ ...temp });
                                                                        setFoodItemOptions([]);
                                                                    }}
                                                                    value={item?.food_item_name ?? null}
                                                                    placeholder='select food item'
                                                                    disabled={meal?.is_hidden === 'Y'}
                                                                />
                                                            </div>

                                                            {['quantity', 'measure_name', 'protein', 'carbs', 'fats', 'fibers', 'sodium', 'fatty_acids', 'calories', 'sugar', 'potassium'].map((name) => (
                                                                <div key={name} className='food-items'>
                                                                    <span className='label'>
                                                                        {convertToTitleCase(name)}
                                                                        {['measure_name', 'quantity'].includes(name) && <span style={{ color: 'red' }}> *</span>}
                                                                    </span>
                                                                    <Input
                                                                        label=''
                                                                        placeholder={name}
                                                                        type={name === 'measure_name' ? 'text' : 'number'}
                                                                        name={name}
                                                                        id={name}
                                                                        value={
                                                                            name === 'quantity'
                                                                                ? item?.quantity === '0' ? null : item?.quantity
                                                                                : name === 'measure_name'
                                                                                    ? item[name]
                                                                                    : calculateNutrition(item[name], item?.quantity)
                                                                        }
                                                                        onChange={(e: any) => handleInputChange(e, name)}
                                                                        disabled={name !== 'quantity' || meal?.is_hidden === 'Y'}
                                                                    />
                                                                </div>
                                                            ))}

                                                            <button className='delete-icons' onClick={handleDelete}>
                                                                {meal.is_hidden === 'N' && (
                                                                    <>
                                                                        <RiDeleteBin6Line />
                                                                        <span>Delete</span>
                                                                    </>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {(meal.is_hidden == 'N' && meal?.options[opIdx]?.food_items[meal?.options[opIdx]?.food_items?.length - 1]?.measure_name || op?.food_items.length === 0) &&
                                                <div
                                                    className='addFood-item-button-1'
                                                    onClick={() => {
                                                        setDietTemplateData((prevState: any) => {
                                                            const updatedData = { ...prevState };
                                                            const meal = updatedData.meals_data[mealIndex];
                                                            if (meal && meal.options[opIdx]) {
                                                                meal.options[opIdx].food_items = [
                                                                    ...(meal.options[opIdx].food_items || []),
                                                                    { is_deleted: 'N' }
                                                                ];
                                                            }
                                                            return updatedData;
                                                        });
                                                    }}>
                                                    + Add Food Items
                                                </div>
                                            }
                                        </div>
                                    )
                                ))}
                            </>
                        </>
                        }
                    </>))}

                    {errorMessage && <p style={{ color: 'red', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{errorMessage}</p>}
                    {successMessage && <p style={{ color: 'green', fontSize: '16px', fontWeight: '500', padding: '0px 5px', marginLeft: '10px' }}>{successMessage}</p>}

                    <div style={{ display: 'flex', width: '500px', margin: `${(errorMessage || successMessage) ? 10 : 25}px auto 0px auto` }}>
                        {((dietTemplateData?.meals_data || [])[dietTemplateData?.meals_data?.length - 1]?.meal_types_id == selectedMealType)
                            ? <button
                                className='save-next-button'
                                onClick={onPressSaveTemplate}
                                disabled={saveLoading}
                            >
                                {!saveLoading ? 'Save' : <Spin style={{ color: 'white' }} />}
                            </button>
                            : <button
                                className='save-next-button'
                                onClick={() => {
                                    if (!tipError) {
                                        const mealsData = dietTemplateData?.meals_data;
                                        if (mealsData && mealsData.length > 0) {
                                            let mealIdx = selectedMealType
                                                ? mealsData.findIndex((d: any) => d.meal_types_id === selectedMealType) + 1
                                                : 0;

                                            const nextMeal = mealsData[mealIdx];
                                            if (nextMeal) {
                                                setSelectedMealType(nextMeal.meal_types_id);
                                                const nextOption = nextMeal.options?.[0]?.order_no;
                                                if (nextOption) {
                                                    setSelectedOption(nextOption);
                                                }
                                            }
                                        }
                                    }
                                }}
                            >
                                Save & Next
                            </button>
                        }

                        <button
                            className='save-next-button'
                            style={{ backgroundColor: 'white', color: '#760fb2', border: '1px solid #760fb2' }}
                            onClick={showCloseConfirm}
                        >
                            Cancel
                        </button>
                    </div>
                </>}
            {warningModal &&
                <Modal
                    className='custom-warning-modal'
                    centered
                    open={warningModal}
                    onCancel={() => setWarningModal(false)}
                    footer={false}
                    closeIcon={false}
                >
                    <NutrientWarningModal invalidNutrientValues={compareNutrientValues()} onSubmit={onWarningModalSubmit} onCancel={() => setWarningModal(false)} loading={loading} />
                </Modal>
            }
        </>
    );
};

export default AddDietPlanTemplate;