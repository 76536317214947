/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./AssessmentGoal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssessment,
  cancelAssessment,
  markAsComplete,
  markAsSkip,
  publishAssessment,
  updateSubGoalAssessment,
  addGoals,
} from "../../../store/slices/GoalSlice";
import { RootState } from "../../../store/store";
import { useGoals } from "../../../hooks/useGoals";
import {
  Assessment,
  Goal,
  HealthProgressData,
  SubGoal,
} from "../../../types/goal";
import Table from "../../../atoms/Table/Table";
import Button from "../../../atoms/Button/Button";
import Select from "../../../atoms/Select/Select";
import { Dropdown, Modal } from "antd";
import Input from "../../../atoms/Input/Input";
import { EllipsisOutlined } from "@ant-design/icons";
import GoalTable from "./components/GoalTable/GoalTable";
import CustomGoalModal from "./components/CustomGoalModal/CustomGoalModal";
import CompletionModal from "./components/CompletionModal/CompletionModal";
import PreviewModal from "./components/PreviewModal/PreviewModal";
import LOCAL_STORAGE from "../../../constants/localStorage";
import useApi from "../../../hooks/useApi";
import API from "../../../services/rest/api";
import { useAssessmentPublisher } from "../../../hooks/useAssessmentPublisher";
import processGoalData from "../../../helpers/goal";
import Badge from "../../../atoms/Badge/Badge";
import SideDrawer from "../../../atoms/SideDrawer/SideDrawer";
import DiagnosticDrawer from "./components/DiagnosticDrawer/DiagnosticDrawer";
// import { HealthProgressData } from "../../../types/healthProgress";
import { toast } from "react-toastify";
import Loader from "../../../atoms/Loader";
import PreviewDrawerContent from "./components/PreviewDrawerContent/PreviewDrawerContent";
// import Modal from "../../../atoms/Modal/Modal";
import DownloadIcon from "../../../assets/icons/download_icon.png";

// Add this type before the component
type TabType = "exercise" | "diet" | "success" | "diagnostics";

interface Column {
  key: string;
  header: any;
  render?: any;
  [key: string]: any;
}

// Add this utility function at the top of the file, after imports
const formatAssessmentName = (
  assessment: any,
  isDiagnostic: boolean
): string => {
  if (!assessment) return "";

  // Handle case when name is "New Assessment"
  if (assessment.name === "New Assessment") {
    // Format the assessment ID and remove single quotes
    let formattedName = assessment.id.split("_").join(" ").replace(/'/g, "");
    formattedName =
      formattedName.charAt(0).toUpperCase() + formattedName.slice(1);

    console.log("formattedName", assessment);

    // Replace "Assessment" with "Diagnostic" if in diagnostic mode
    if (isDiagnostic) {
      formattedName = formattedName.split("Assessment").join("Diagnostic");
    }

    return formattedName;
  } else {
    // Handle case for other assessment names
    if (isDiagnostic) {
      return assessment.name
        ? assessment.name.split("Assessment").join("Diagnostic")
        : "";
    }
    return assessment.name || "";
  }
};

const AssessmentGoal = ({
  activeTab,
  mode = "view",
  isPrimary,
}: {
  activeTab: TabType;
  mode?: "view" | "edit";
  isPrimary?: boolean;
}) => {
  const patientId = localStorage.getItem(LOCAL_STORAGE.PATIENT_ID);
  const dispatch = useDispatch();
  const { isLoading, fetchGoals: fetchGoalsHandler } = useGoals(
    activeTab,
    patientId || "",
    isPrimary || false
  );
  const { goals, assessments, totalAssessment, assessmentTrend } = useSelector(
    (state: RootState) =>
      state.goals.data[isPrimary ? "mainGoal" : "customGoals"]
  );

  const [collapsedGoals, setCollapsedGoals] = useState<Record<string, boolean>>(
    {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState<string>("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const PER_PAGE = 2;
  const [showAssessmentSideDrawer, setShowAssessmentSideDrawer] =
    useState(false);
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<string>("");
  // Add this state near other useState declarations
  const [showPreviewSideDrawer, setShowPreviewSideDrawer] = useState(false);
  const [reportUploaded, setReportUploaded] = useState(false);
  const [isErrorInReport, setIsErrorInReport] = useState(false);
  const [noActionDiagnostic, setNoActionDiagnostic] = useState(false);

  const { handlePublish, isPublishing } = useAssessmentPublisher({
    activeTab,
    patientId: patientId || "",
    onPublishSuccess: () => setIsPreviewModalOpen(false),
    onPublishError: (message) => setIsPreviewModalOpen(false),
    isPrimary: isPrimary || false,
  });
  const [reportIsProcessing, setReportIsProcessing] = useState(true);

  const {
    mutate: getPatientMetaInfo,
    response: getPatientMetaInfoResponse,
    error: getPatientMetaInfoError,
    isLoading: isGetPatientMetaInfoLoading,
  } = useApi(API.GetPatientMetaInfo);

  useEffect(() => {
    if (activeTab === "diagnostics") {
      getPatientMetaInfo(patientId);
    }
  }, [activeTab, showAssessmentSideDrawer]);

  useEffect(() => {
    if (getPatientMetaInfoResponse?.code?.toString() === "1") {
      localStorage.setItem("reportUploaded", "");
      setIsErrorInReport(
        getPatientMetaInfoResponse?.data[0]?.patient_meta_info
          ?.lastReportAIStatus === false
      );
      setReportIsProcessing(
        getPatientMetaInfoResponse?.data[0]?.patient_meta_info
          ?.isReportProcessing
      );
    } else if (
      getPatientMetaInfoResponse?.code?.toString() === "0" ||
      getPatientMetaInfoError
    ) {
      localStorage.setItem("reportUploaded", "");
      setReportIsProcessing(true);
      toast.error(
        getPatientMetaInfoError?.message || "Error fetching patient meta info"
      );
    }
  }, [getPatientMetaInfoResponse, getPatientMetaInfoError]);

  useEffect(() => {
    try {
      const reportUploadedStr = localStorage.getItem("reportUploaded");
      if (reportUploadedStr) {
        const reportUploaded = JSON.parse(reportUploadedStr);
        setReportUploaded(Boolean(reportUploaded));
      }
    } catch (error) {
      console.error("Error parsing reportUploaded from localStorage:", error);
      setReportUploaded(false);
    }
  }, []);

  const {
    mutate: upadtePatientGoals,
    response: upadtePatientGoalsResponse,
    error: upadtePatientGoalsError,
    isLoading: isUpadtePatientGoalsLoading,
  } = useApi(API.UpdatePatientGoals);

  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");

  useEffect(() => {
    if (upadtePatientGoalsResponse?.code?.toString() === "1") {
      toast.success(
        upadtePatientGoalsResponse?.message || "Goal Marked as completed"
      );
      setIsCompletionModalOpen(false);
    } else if (upadtePatientGoalsResponse?.code?.toString() === "0") {
      toast.error(
        upadtePatientGoalsResponse?.message || "Failed to mark as completed"
      );
    }
  }, [upadtePatientGoalsError, upadtePatientGoalsResponse]);

  // Add ref for table container
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const filteredGoals = Object.values(goals).filter((goal) =>
    goal.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleCollapse = (goalId: string) => {
    setCollapsedGoals((prevState) => ({
      ...prevState,
      [goalId]: !prevState[goalId],
    }));
  };

  const hasNewAssessment = () => {
    return Object.values(assessments).some((assessment) => assessment.isNew);
  };

  const hasNewAssessmentWithPreview = () => {
    return Object.values(assessments).some(
      (assessment) => assessment.isNew && assessment.action === "preview"
    );
  };

  const handleAssessmentChange = (
    value: string,
    goalId: string,
    subGoalId: string,
    assessmentId: string
  ) => {
    dispatch(
      updateSubGoalAssessment({
        value,
        goalId,
        subGoalId,
        assessmentId,
        isPrimary: isPrimary || false,
      })
    );
  };
  const handleAddCustomGoal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchQuery("");
  };

  const handleGoalSelect = (goalId: string) => {
    setSelectedGoals((prev) =>
      prev.includes(goalId)
        ? prev.filter((id) => id !== goalId)
        : [...prev, goalId]
    );
  };

  const getMenuItems = (goal: Goal) => ({
    items: [
      {
        key: "1",
        label: "Mark as completed",
        onClick: () => {
          setSelectedGoalId(goal.id.toString());
          setIsCompletionModalOpen(true);
        },
      },
    ],
  });

  const getMenuItemsAssessment = (assessmentId: string) => ({
    items: [
      {
        key: "1",
        label: "Mark as Skip",
        onClick: () => {
          markAsSkipHandler(assessmentId);
        },
      },
    ],
  });

  const markAsSkipHandler = (assessmentId: string) => {
    setIsSkipModalOpen(true);
    setSelectedAssessmentId(assessmentId);
  };

  const handleSkipConfirm = () => {
    dispatch(
      markAsSkip({
        assessmentId: selectedAssessmentId,
        isPrimary: isPrimary || false,
      })
    );
  };

  const hasSkippedAssessment = Object.values(assessments).some(
    (assessment) => assessment.isSkip
  );

  useEffect(() => {
    if (hasSkippedAssessment) {
      handlePublish();
      setIsSkipModalOpen(false);
    }
  }, [hasSkippedAssessment]);

  const previewAndPublishHandler = () => {
    if (activeTab === "diagnostics") {
      handlePublish();
      return;
    }
    isNewAssessmentSkipped()
      ? dispatch(
          publishAssessment({
            isPrimary: isPrimary || false,
            activeTab,
            patientId: patientId || "",
          })
        )
      : setIsPreviewModalOpen(true);
  };

  const markAsCompleteHandler = async (goalId: string) => {
    try {
      await upadtePatientGoals({
        patient_id: patientId,
        goal: { id: goalId, data: { status: "completed" } },
        is_primary: isPrimary ? 1 : 0,
        tag: activeTab,
      });
      dispatch(markAsComplete({ goalId, isPrimary: isPrimary || false }));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLatestAssessment = () => {
    const newAssessment = Object.values(assessments).find(
      (assessment) => assessment.isNew
    );
    return newAssessment ? [newAssessment] : [];
  };
  const checkForAssessmentPreview = () => {
    if (
      Object.keys(assessments).length === totalAssessment ||
      !totalAssessment
    ) {
      return true;
    }
    return false;
  };

  const isNewAssessmentStatus = (status: string) => {
    const newAssessment = Object.values(assessments).find(
      (assessment) => assessment.isNew
    );

    if (!newAssessment) return false;

    return Object.values(goals)[0]?.status === status?.toLowerCase();
  };

  const isNewAssessmentSkipped = () => {
    const newAssessment = Object.values(assessments).find(
      (assessment) => assessment.isNew
    );

    if (!newAssessment) return false;
    return newAssessment?.isSkip;
  };

  const handleShowPreviews = () => {
    setShowPreviewSideDrawer(true);
  };

  console.log("assessmentsss", assessments);

  const headerConfig = {
    exercise: {
      title: "GF Goals",
      buttons: [
        {
          label: "Add Assessment",
          show: (props: any) =>
            !props.hasNewAssessment() &&
            mode === "edit" &&
            Object.keys(goals).length > 0,
          onClick: (dispatch: any) => () =>
            dispatch(addAssessment({ type: activeTab, isPrimary })),
        },
        {
          label: "Add Custom Goal",
          show: () => mode === "edit" && !isPrimary,
          onClick: () => handleAddCustomGoal,
        },
        {
          label: "Show Previews",
          show: () => mode === "edit",
          onClick: () => handleShowPreviews,
        },
      ],
    },
    diet: {
      title: "GF Goals",
      buttons: [
        {
          label: "Add Assessment",
          show: (props: any) =>
            !props.hasNewAssessment() &&
            mode === "edit" &&
            Object.keys(goals).length > 0,
          onClick: (dispatch: any) => () =>
            dispatch(addAssessment({ type: activeTab, isPrimary })),
        },
        {
          label: "Add Custom Goal",
          show: () => mode === "edit" && !isPrimary,
          onClick: () => handleAddCustomGoal,
        },
        {
          label: "Show Previews",
          show: () => mode === "edit",
          onClick: () => handleShowPreviews,
        },
      ],
    },
    success: {
      title: "GF Goals",
      buttons: [
        {
          label: "Add Assessment",
          show: (props: any) =>
            !props.hasNewAssessment() &&
            mode === "edit" &&
            Object.keys(goals).length > 0,
          onClick: (dispatch: any) => () =>
            dispatch(addAssessment({ type: activeTab, isPrimary })),
        },
        {
          label: "Add Custom Goal",
          show: () => mode === "edit" && !isPrimary,
          onClick: () => handleAddCustomGoal,
        },
        {
          label: "Show Previews",
          show: () => mode === "edit",
          onClick: () => handleShowPreviews,
        },
      ],
    },
    diagnostics: {
      title: "GF Goals",
      buttons: [
        {
          label: "Add Diagnostics",
          show: (props: any) =>
            !props.hasNewAssessment() &&
            !reportIsProcessing &&
            !hasNewAssessmentWithPreview(),
          onClick: (dispatch: any) => () =>
            dispatch(addAssessment({ type: activeTab, isPrimary })),
        },
        {
          label: "Show Previews",
          show: () => mode === "edit" && Object.keys(goals).length > 0,
          onClick: () => handleShowPreviews,
        },
      ],
    },
  };

  const assessmentActions = {
    exercise: {
      className: "assessment-actions",
      isShowSkip: true,
      buttons: [
        {
          variant: "primary" as "primary" | "outlined" | "secondary",
          label: "Preview & Publish",
          onClick: () => previewAndPublishHandler(),
          className: "",
        },
        {
          variant: "outlined" as "primary" | "outlined" | "secondary",
          label: "Cancel",
          onClick: () =>
            dispatch(cancelAssessment({ isPrimary: isPrimary || false })),
          className: "cancel-btn",
        },
      ],
    },
    diet: {
      className: "assessment-actions",
      isShowSkip: true,
      buttons: [
        {
          variant: "primary" as "primary" | "outlined" | "secondary",
          label: "Preview & Publish",
          onClick: () => previewAndPublishHandler(),
          className: "",
        },
        {
          variant: "outlined" as "primary" | "outlined" | "secondary",
          label: "Cancel",
          onClick: () =>
            dispatch(cancelAssessment({ isPrimary: isPrimary || false })),
          className: "cancel-btn",
        },
      ],
    },
    success: {
      className: "assessment-actions",
      isShowSkip: true,
      buttons: [
        {
          variant: "primary" as "primary" | "outlined" | "secondary",
          label: "Preview & Publish",
          onClick: () => previewAndPublishHandler(),
          className: "",
        },
        {
          variant: "outlined" as "primary" | "outlined" | "secondary",
          label: "Cancel",
          onClick: () =>
            dispatch(cancelAssessment({ isPrimary: isPrimary || false })),
          className: "cancel-btn",
        },
      ],
    },
    diagnostics: {
      className: "assessment-actions",
      isShowSkip: false,
      buttons: [
        {
          variant: "primary" as "primary" | "outlined" | "secondary",
          label: "Publish",
          onClick: () => previewAndPublishHandler(),
          className: "",
        },
        {
          variant: "outlined" as "primary" | "outlined" | "secondary",
          label: "Upload From report",
          onClick: () => handleUploadFromReport(),
          className: "cancel-btn",
        },
      ],
    },
  };

  const handleUploadFromReport = () => {
    setShowAssessmentSideDrawer(true);
  };

  const columns = [
    {
      key: "id",
      header: (column: any) => {
        return (
          <div className="title-container">
            <span className="title">{headerConfig[activeTab]?.title}</span>
            <div
              className="header-buttons"
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {headerConfig[activeTab]?.buttons.map(
                (button) =>
                  button.show({ hasNewAssessment }) && (
                    <Button
                      className="header-btn"
                      variant="primary"
                      onClick={button.onClick(dispatch)}
                    >
                      {button.label}
                    </Button>
                  )
              )}
            </div>
          </div>
        );
      },
      render: (value: any, goal: Goal) => {
        return (
          <div>
            <div className="goal-header">
              <button
                className="collapse-btn"
                onClick={() => toggleCollapse(goal.id.toString())}
              >
                {collapsedGoals[goal.id] ? "+" : "-"}
              </button>
              {/* <input
                type="checkbox"
                className="goal-checkbox"
                checked={goal.status === "completed"}
              /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <span className="goal-title">{goal.name}</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  <Badge variant="info">{`${goal.type} term goal`}</Badge>
                  <Badge variant="info">{`${
                    goal.isPrimary ? "Primary" : "Custom"
                  } goal`}</Badge>
                  {goal.status === "completed" && (
                    <Badge variant="success">Completed</Badge>
                  )}
                </div>
              </div>

              {hasNewAssessment() &&
                !isNewAssessmentSkipped() &&
                goal.status !== "completed" &&
                activeTab !== "diagnostics" && (
                  <Dropdown menu={getMenuItems(goal)} trigger={["click"]}>
                    <button className="more-options-btn">
                      <EllipsisOutlined
                        style={{
                          fontSize: "40px",
                          color: "#333",
                          transform: "scale(0.7, 1)",
                        }}
                      />
                    </button>
                  </Dropdown>
                )}
            </div>
            {!collapsedGoals[goal.id] && (
              <div className="sub-goals-div-goal">
                <div>
                  {goal.subGoals?.map((subGoal) => (
                    <div key={subGoal.id} className="goal-metrics">
                      <div className="metric">
                        <div className="metric-details">
                          <div className="metric-name">{subGoal.name}</div>
                          {subGoal?.target && (
                            <div className="metric-target">
                              {`Target: ${subGoal?.target}`}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    ...Object.values(assessments).map((assessment) => ({
      key: assessment.id,
      label: assessment.id,
      header: (column: any) => (
        <div className="title-container">
          <div className="title-date">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="title">
                {formatAssessmentName(assessment, activeTab === "diagnostics")}
                {assessment?.action === "skipped" && (
                  <Badge variant="danger">Skipped</Badge>
                )}
              </div>
              {assessment.date && (
                <div className="date">
                  Done on {new Date(assessment.date).toLocaleDateString()}
                </div>
              )}
            </div>
            {assessment.isNew && assessmentActions[activeTab].isShowSkip && (
              <Dropdown
                menu={getMenuItemsAssessment(assessment?.id)}
                trigger={["click"]}
              >
                <button className="more-options-btn">
                  <EllipsisOutlined
                    style={{
                      fontSize: "40px",
                      color: "#333",
                      transform: "scale(0.7, 1)",
                    }}
                  />
                </button>
              </Dropdown>
            )}

            {activeTab === "diagnostics" &&
              assessment?.action === "preview" && (
                <a
                  target="_blank"
                  href={
                    "https://aztatvaprodstorage.blob.core.windows.net/patient-reports/c99973b4-f9ac-11ef-a6ab-0022486e34ba/Comprehensive_Lab_Report.pdf?st=2025-03-07T08%3A10%3A38Z&se=2025-03-09T08%3A10%3A38Z&sp=r&sv=2018-03-28&sr=b&sig=XpXCwnNZ%2F7kl58BIvOTFG28tDcBJygc17i%2FZsMCNjHw%3D"
                  }
                >
                  <img
                    src={DownloadIcon}
                    alt="download_icon"
                    style={{
                      marginRight: "20px",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </a>
              )}
          </div>
          {assessment.isNew && (
            <div className={assessmentActions[activeTab].className}>
              {assessmentActions[activeTab].buttons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.variant}
                  className={button.className}
                  disabled={
                    (activeTab === "diagnostics" &&
                      (reportIsProcessing || reportUploaded)) ||
                    (assessment.action === "preview" &&
                      button.label === "Upload From report") ||
                    (button.label === "Publish" &&
                      !noActionDiagnostic &&
                      assessment?.action !== "preview")
                  }
                  onClick={() => {
                    button.onClick();
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      ),

      render: (value: any, goal: Goal) => {
        return (
          <div
            className={`goal-item ${
              assessment.isNew && goal?.status !== "completed" ? "" : "disabled"
            }`}
          >
            {!collapsedGoals[goal.id] && (
              <div className="sub-goals-div">
                {goal.subGoals?.map((subGoal) => (
                  <div key={subGoal.id} className="goal-metrics">
                    {!(subGoal[assessment.id] as Assessment)?.isSkip &&
                      assessment?.action != "skipped" && (
                        <>
                          {activeTab !== "diagnostics" ? (
                            <Select
                              // disabled={!assessment.isNew}
                              options={
                                subGoal.answers?.options?.map((value) => ({
                                  label: value,
                                  value: value,
                                })) || []
                              }
                              value={
                                (subGoal[assessment.id] as Assessment)?.value ||
                                ""
                              }
                              onChange={(value) =>
                                handleAssessmentChange(
                                  String(value),
                                  goal?.id.toString(),
                                  subGoal?.id.toString(),
                                  assessment.id.toString()
                                )
                              }
                            />
                          ) : (
                            <p
                              style={{
                                fontSize: "16px",
                                color: "#333",
                                marginTop: "auto",
                              }}
                            >
                              {(subGoal[assessment.id] as Assessment)?.value ||
                                ""}
                            </p>
                          )}
                        </>
                      )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      },
    })),
    ...(checkForAssessmentPreview() && isPrimary && activeTab !== "diagnostics"
      ? [
          {
            key: "preview",
            header: (column: any) => (
              <div className="title-container">
                <div className="title-date">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="title">{`${"Pre Assessment"}`}</div>
                  </div>
                </div>
              </div>
            ),
            render: (value: any, goal: Goal) => {
              return (
                <div className="goal-item">
                  {!collapsedGoals[goal.id] && (
                    <div className="sub-goals-div">
                      <div
                        key={"00"}
                        className="goal-metrics"
                        style={{
                          margin: 0,
                          fontSize: "24px",
                          color: "#333",
                        }}
                      >
                        {goal?.preAssessment}
                      </div>
                      {/* {goal.subGoals?.map((subGoal) => (
                        <div key={subGoal.id} className="goal-metrics">
                          <Select
                            disabled={true}
                            options={
                              subGoal.answers?.options?.map((value) => ({
                                label: value,
                                value: value,
                              })) || []
                            }
                            value=""
                            onChange={() => {}}
                          />
                        </div>
                      ))} */}
                    </div>
                  )}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const previewColumns = [
    {
      ...columns[0],
      header: (column: any) => {
        return (
          <div
            className="title-container"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="title">GF Goals</span>
          </div>
        );
      },
      render: (value: any, goal: Goal) => {
        return (
          <div>
            <div className="goal-header">
              <button
                className="collapse-btn"
                onClick={() => toggleCollapse(goal.id.toString())}
              >
                {collapsedGoals[goal.id] ? "+" : "-"}
              </button>
              {/* <input
                type="checkbox"
                className="goal-checkbox"
                checked={goal.status === "completed"}
              /> */}
              <span className="goal-title">{goal.name}</span>
              <span className="goal-tag">{goal.tag}</span>
            </div>
            {!collapsedGoals[goal.id] && (
              <div className="sub-goals-div-goal">
                <div>
                  {goal.subGoals?.map((subGoal) => (
                    <div key={subGoal.id} className="goal-metrics">
                      <div className="metric">
                        <div className="metric-details">
                          <div className="metric-name">{subGoal.name}</div>
                          {subGoal?.target && (
                            <div className="metric-target">
                              {`Target: ${subGoal?.target}`}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    ...getLatestAssessment().map((assessment) => ({
      ...columns.find((col) => col.key === assessment.id),
      header: (column: any) => (
        <div className="title-container">
          <div className="title-date">
            <div className="title">{assessment.id}</div>
            {assessment.date && (
              <span className="date">
                Done on {new Date(assessment.date).toLocaleDateString()}
              </span>
            )}
          </div>
        </div>
      ),
      render: (value: any, goal: Goal) => {
        return (
          <div className="goal-item">
            {!collapsedGoals[goal.id] && (
              <div className="sub-goals-div">
                {goal.subGoals?.map((subGoal) => (
                  <div key={subGoal.id} className="goal-metrics">
                    {!(subGoal[assessment.id] as Assessment)?.isSkip && (
                      <Select
                        disabled={true}
                        options={
                          subGoal.answers?.options?.map((value) => ({
                            label: value,
                            value: value,
                          })) || []
                        }
                        value={(subGoal[assessment.id] as Assessment)?.value}
                        onChange={(value) =>
                          handleAssessmentChange(
                            String(value),
                            goal?.id.toString(),
                            subGoal?.id.toString(),
                            assessment.id.toString()
                          )
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      },
    })),
  ];

  const loadMoreAssessments = async () => {
    if (isLoadingMore) return;

    if (page * PER_PAGE >= totalAssessment) {
      setHasMore(false);
      return;
    }

    setIsLoadingMore(true);
    const nextPage = page + 1;

    setHasMore(true);

    const payload = {
      page: nextPage,
      per_page: PER_PAGE,
      tag: activeTab,
      disease: patientInfo?.disease || "",
      patient_id: patientId,
      is_primary: isPrimary ? 1 : 0,
    };

    try {
      const response = await API.getAssessmentGoals(payload);
      if (response?.code?.toString() === "1") {
        // Create goalsArray with existing goals data
        const goalsArray = Object.values(goals).map((goal) => {
          // Get matching goal from response data
          const responseGoal = response.data.find((g: any) => g.id === goal.id);
          const mappedSubGoals = responseGoal.subGoals.map((subGoal: any) => {
            return {
              ...subGoal,
              ...(goal.subGoals?.find((sg: any) => sg.id === subGoal.id) || {}),
            };
          });

          return {
            ...goal,
            subGoals: mappedSubGoals,
          };
        });
        const newData = processGoalData(goalsArray, isPrimary);
        dispatch(
          addGoals({
            goals: {
              ...goals,
              ...newData.data[isPrimary ? "mainGoal" : "customGoals"].goals,
            },
            assessments: {
              ...assessments,
              ...newData.data[isPrimary ? "mainGoal" : "customGoals"]
                .assessments,
            },
            isPrimary: isPrimary || false,
          })
        );

        setPage(nextPage);
      }
    } catch (error) {
      console.error("Error loading more assessments:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Add this after the goals selector

  return (
    <div className="assessment-goal">
      {isLoading ? (
        <Loader noBackground={true} />
      ) : (
        <>
          {isErrorInReport && activeTab === "diagnostics" && (
            <div
              style={{
                color: "red",
                marginBottom: "16px",
                textAlign: "center",
              }}
            >
              Error in your uploaded report, please upload new lab test
            </div>
          )}
          {!hasNewAssessmentWithPreview() &&
            reportIsProcessing &&
            activeTab === "diagnostics" && (
              <div
                style={{
                  color: "red",
                  marginBottom: "16px",
                  textAlign: "center",
                }}
              >
                Report is currently being processed for AI analysis
              </div>
            )}
          <div className="table-container" ref={tableContainerRef}>
            <GoalTable
              goals={goals}
              columns={columns}
              collapsedGoals={collapsedGoals}
              onToggleCollapse={toggleCollapse}
              onAssessmentChange={handleAssessmentChange}
              hasNewAssessment={hasNewAssessment}
              onAddAssessment={() =>
                dispatch(addAssessment({ type: activeTab, isPrimary }))
              }
              onAddCustomGoal={handleAddCustomGoal}
              onLoadMore={loadMoreAssessments}
              hasMore={hasMore}
              isLoading={isLoadingMore}
            />
          </div>
        </>
      )}

      {isModalOpen && (
        <CustomGoalModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          patientId={patientId || ""}
          activeTab={activeTab}
          isPrimary={isPrimary || false}
          fetchGoalsHandler={fetchGoalsHandler}
        />
      )}

      <CompletionModal
        isOpen={isCompletionModalOpen}
        onClose={() => setIsCompletionModalOpen(false)}
        onComplete={() => markAsCompleteHandler(selectedGoalId)}
      />

      {isPreviewModalOpen && (
        <PreviewModal
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          goals={goals}
          assessments={assessments}
          previewColumns={previewColumns}
          handlePublish={handlePublish}
          patientId={patientId || ""}
          isPublishing={isPublishing}
        />
      )}

      {showAssessmentSideDrawer && (
        <SideDrawer
          isOpen={showAssessmentSideDrawer}
          title="Select / Upload Record"
          onClose={() => setShowAssessmentSideDrawer(false)}
          position="right"
        >
          <DiagnosticDrawer
            patientId={patientId || ""}
            setShowAssessmentSideDrawer={setShowAssessmentSideDrawer}
            setNoActionDiagnostic={setNoActionDiagnostic}
            fetchGoalsHandler={fetchGoalsHandler}
          />
        </SideDrawer>
      )}

      {showPreviewSideDrawer && (
        <SideDrawer
          isOpen={showPreviewSideDrawer}
          title="Assessment Previews"
          onClose={() => setShowPreviewSideDrawer(false)}
          position="right"
        >
          <PreviewDrawerContent
            patientId={patientId || ""}
            activeTab={activeTab}
          />
        </SideDrawer>
      )}

      <Modal
        open={isSkipModalOpen}
        onClose={() => setIsSkipModalOpen(false)}
        onCancel={() => setIsSkipModalOpen(false)}
        title="Skip Assessment"
        footer={null}
      >
        <div className="skip-modal-content">
          <p>
            Are you sure you want to skip this assessment. This action can not
            be reversed
          </p>
          <div className="skip-modal-actions">
            <Button variant="primary" onClick={handleSkipConfirm}>
              Skip Assessment
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsSkipModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssessmentGoal;
