import React, { useState } from "react";

import "./DietViews.scss";
import Tabs from "../../../atoms/Tabs/Tabs";
import AdherenceGraph from "../../../atoms/Adherence-Graph/Adherence-Graph";

import { DietPlan } from "../../../types/diet";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import UserView from "./UserView";

interface Props {
  //   dietPlan: DietPlan | null;
}

const DietViews: React.FC<Props> = ({}) => {
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const tabs = [
    {
      label: "Adherence View",
      value: "ADHERENCE",
    },
    {
      label: "User View",
      value: "USER",
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={(activeTab) => setActiveTab(activeTab)}
      />

      <div>
        {activeTab === "ADHERENCE" && (
          <AdherenceGraph patient_id={patientDetails?.data?.patient_id} />
        )}
        {activeTab === "USER" && (
          <UserView
            mobileNumber={patientDetails?.data?.contact_no}
            name={patientDetails?.data?.name}
            moduleName="diet"
          />
        )}
      </div>
    </div>
  );
};

export default DietViews;
