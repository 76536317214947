import React from "react";
import AddDietPlan from "../../molecules/addDiet-plan/addDiet-plan";

type AddDietContainerProps = {
  showTitle?: boolean;
  getPatientList?: any;
  setAddDietShow?: any;
  setScreen?: any;
  chief_hc?: any;
  latestDietForApprove?: any;
  setShowDietModal?: any;
  selectedAssessment?: any;
  userDetails?: any;
  setEnableSwitch?: any;
  setToggle?: any;
  disableUpdateButton?: any;
  setDisableUpdateButton?: any;
  editModal?: boolean;
  templateId?: string | null;
  editDietPlanData?: any;
  copyDietPlanData?: any;
  onCancel?: any;
  onSuccess?: any;
};

const AddDietContainer: React.FC<AddDietContainerProps> = ({
  showTitle,
  getPatientList,
  setAddDietShow,
  setScreen,
  chief_hc,
  latestDietForApprove,
  setShowDietModal,
  selectedAssessment,
  userDetails,
  setEnableSwitch,
  setToggle,
  disableUpdateButton,
  setDisableUpdateButton,
  editModal,
  templateId,
  editDietPlanData,
  copyDietPlanData,
  onCancel,
  onSuccess
}) => {
  return (
    <>
      <div>
        <AddDietPlan
          showTitle={showTitle}
          editModal={editModal}
          templateId={templateId}
          getPatientList={getPatientList}
          setAddDietShow={setAddDietShow}
          setScreen={setScreen}
          chief_hc={chief_hc}
          latestDietForApprove={latestDietForApprove}
          setShowDietModal={setShowDietModal}
          selectedAssessment={selectedAssessment}
          userDetails={userDetails}
          setEnableSwitch={setEnableSwitch}
          setToggle={setToggle}
          disableUpdateButton={disableUpdateButton}
          setDisableUpdateButton={setDisableUpdateButton}
          editDietPlanData={editDietPlanData}
          copyDietPlanData={copyDietPlanData}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      </div>
    </>
  );
};

export default AddDietContainer;
