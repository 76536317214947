import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Checkbox,
  Slider,
  Select,
  Form,
  Input,
  message,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "./CreateNewList.scss";
import useApi from "../../../hooks/useApi";
import API from "../../../services/rest/api";

interface CreateNewListProps {
  isVisible: boolean;
  onClose: () => void;
  onCreateList: (data: any) => void;
  //   userToken: string;
}

const CreateNewList: React.FC<CreateNewListProps> = ({
  isVisible,
  onClose,
  onCreateList,
}) => {
  // State management
  const [form] = Form.useForm();
  const [patientFilter, setPatientFilter] = useState<any>(null);
  const [locationData, setLocationData] = useState<any[]>([]);
  const [genderData, setGenderData] = useState<any[]>([]);
  const [indicationsData, setIndicationsData] = useState<any[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedIndications, setSelectedIndications] = useState<string[]>([]);
  const [selectedGenders, setSelectedGenders] = useState<string[]>([]);
  const [age, setAge] = useState<[number, number]>([0, 100]);
  const [lastActivity, setLastActivity] = useState<[number, number]>([0, 20]);
  const [lastJoined, setLastJoined] = useState<[number, number]>([0, 20]);
  const [medicationAdherence, setMedicationAdherence] = useState<string>("");
  const [breathingAdherence, setBreathingAdherence] = useState<string>("");
  const [dietAdherence, setDietAdherence] = useState<string>("");
  const [exerciseAdherence, setExerciseAdherence] = useState<string>("");

  const adherenceOptions = [
    { name: "All", value: "100" },
    { name: "Below 30%", value: "30" },
    { name: "Below 50%", value: "50" },
    { name: "Below 70%", value: "70" },
  ];

  const {
    mutate: fetchPatientListFilters,
    response: fetchPatientListFiltersResponse,
    error: fetchPatientListFiltersError,
    isLoading: isFetchPatientListFiltersLoading,
  } = useApi(API.GetPatientListFilters);

  useEffect(() => {
    if (fetchPatientListFiltersResponse?.code?.toString() === "1") {
      setPatientFilter(fetchPatientListFiltersResponse.data);
      setLocationData(fetchPatientListFiltersResponse.data[0].data);
      setIndicationsData(fetchPatientListFiltersResponse.data[1].data);
      setGenderData(fetchPatientListFiltersResponse.data[2].data);
    } else if (fetchPatientListFiltersResponse?.code?.toString() === "0") {
      // toast.error(assessmentGoalsResponse?.message || "Failed to fetch goals");
    }
  }, [fetchPatientListFiltersResponse, fetchPatientListFiltersError]);

  useEffect(() => {
    if (isVisible) {
      getPatientData();
    }
  }, [isVisible]);

  const getPatientData = async () => {
    try {
      await fetchPatientListFilters();
    } catch (error) {
      message.error("Failed to fetch patient data");
    }
  };

  const handleCreateList = async (values: any) => {
    const formData = {
      genders: selectedGenders,
      cities: selectedLocations,
      medical_condition: selectedIndications,
      age_from: age[0].toString(),
      age_to: age[1].toString(),
      activity_from: lastActivity[0].toString(),
      activity_to: lastActivity[1].toString(),
      joined_from: lastJoined[0].toString(),
      joined_to: lastJoined[1].toString(),
      list_name: values.list_name,
      medication_adherence: medicationAdherence,
      steps_adherence: "",
      pranayam_adherence: breathingAdherence,
      exercise_adherence: exerciseAdherence,
      diet_adherence: dietAdherence,
    };

    if (validateForm(formData)) {
      onCreateList(formData);
    } else {
      message.error("Please fill all required fields");
    }
  };

  const validateForm = (data: any) => {
    return (
      data.genders.length > 0 &&
      data.cities.length > 0 &&
      data.medical_condition.length > 0 &&
      data.medication_adherence &&
      data.pranayam_adherence &&
      data.exercise_adherence &&
      data.diet_adherence
    );
  };

  return (
    <Modal
      title={<h5 className="modal-title">Choose Patient List Criteria</h5>}
      open={isVisible}
      onCancel={onClose}
      width={1000}
      className="create-list-modal"
      footer={null}
    >
      <Form form={form} onFinish={handleCreateList} layout="vertical">
        <Row gutter={[24, 24]}>
          {/* Indications Section */}
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Indication & Severity<span className="required">*</span>
                </span>
              }
              name="indications"
              rules={[{ required: true, message: "Please select indications" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select indications"
                onChange={(values) => setSelectedIndications(values)}
                options={indicationsData?.map((item) => ({
                  label: item.medical_condition_name,
                  value: item.medical_condition_group_id,
                }))}
              />
            </Form.Item>
          </Col>
          {/* Gender Section */}
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Gender<span className="required">*</span>
                </span>
              }
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Checkbox.Group
                onChange={(values) => setSelectedGenders(values as string[])}
              >
                {genderData.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.key}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
          {/* Age Section */}
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Age<span className="required">*</span>
                </span>
              }
              name="age"
            >
              <Slider
                range
                min={0}
                max={100}
                onChange={(value) => setAge(value as [number, number])}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Location<span className="required">*</span>
                </span>
              }
              name="location"
              rules={[{ required: true, message: "Please select location" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select locations"
                onChange={(values) => setSelectedLocations(values)}
                options={locationData?.map((item) => ({
                  label: item.city_name,
                  value: item.city_name,
                }))}
              />
            </Form.Item>
          </Col>
          {/* Last Activity Section */}
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Days since last activity<span className="required">*</span>
                </span>
              }
              name="last_activity"
            >
              <Slider
                range
                min={0}
                max={20}
                onChange={(value) => setLastActivity(value as [number, number])}
              />
            </Form.Item>
          </Col>
          {/* Days Since Joined Section */}
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Days since joined<span className="required">*</span>
                </span>
              }
              name="last_joined"
            >
              <Slider
                range
                min={0}
                max={20}
                onChange={(value) => setLastJoined(value as [number, number])}
              />
            </Form.Item>
          </Col>
          {/* Location Section */}

          {/* Adherence Sections */}
          {["Medication", "Breathing", "Diet", "Exercise"].map((type) => (
            <Col span={12} key={type}>
              <Form.Item
                label={
                  <span>
                    {type} Adherence<span className="required">*</span>
                  </span>
                }
                name={`${type.toLowerCase()}_adherence`}
                rules={[
                  {
                    required: true,
                    message: `Please select ${type.toLowerCase()} adherence`,
                  },
                ]}
              >
                <Checkbox.Group className="adherence-group">
                  {adherenceOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      onChange={(e: CheckboxChangeEvent) => {
                        const setter = {
                          Medication: setMedicationAdherence,
                          Breathing: setBreathingAdherence,
                          Diet: setDietAdherence,
                          Exercise: setExerciseAdherence,
                        }[type];
                        if (setter && e.target.checked) {
                          setter(option.value);
                        }
                      }}
                    >
                      {option.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          ))}
        </Row>

        <div className="modal-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Create List
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateNewList;
