import React, { useRef, useState } from "react";
import "./DietTaskPage.scss";

import DietPlansList from "../../../../molecules/DietV2/DietPlansList/DietPlansList";
import DietViews from "../../../../molecules/DietV2/DietViews/DietViews";
import BottomSheet from "../../../../atoms/BottomSheet/BottomSheet";
import CreateDietPlan from "../../../../molecules/DietV2/CreateDietPlan/CreateDietPlan";

const DietTaskPage: React.FC<any> = () => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [editDietPlan, setEditDietPlan] = useState<any>(null);
  const [copyDietPlan, setCopyDietPlan] = useState<any>(null);

  const dietPlansListRef = useRef<any>(null);

  const handleAddDietClick = () => {
    setShowBottomSheet(true);
  };

  const handleEditDietClick = (dietPlan: any) => {
    setShowBottomSheet(true);
    setEditDietPlan(dietPlan)
  }

  const handleCopyDietClick = (dietPlan: any) => {
    setShowBottomSheet(true);
    setCopyDietPlan(dietPlan)
  }

  const onSuccess = () => {
    setShowBottomSheet(false);
    setEditDietPlan(null);
    setCopyDietPlan(null);
    window.scrollTo(0, 0);
    dietPlansListRef?.current?.refresh();
  };

  return (
    <div className="diet-plan-page-container">
      <h2 className="page-title">Diet plans</h2>

      <DietPlansList
        ref={dietPlansListRef}
        handleAddDietClick={handleAddDietClick}
        handleEditDietClick={handleEditDietClick}
        handleCopyDietClick={handleCopyDietClick}
      />

      <DietViews />

      {showBottomSheet && (
        <BottomSheet
          isOpen={showBottomSheet}
          title="Create Diet Plan"
          onClose={() => setShowBottomSheet(false)}
        >
          <CreateDietPlan
            onCancel={() => {
              setShowBottomSheet(false);
              setEditDietPlan(null);
            }}
            onSuccess={onSuccess}
            selectedDietPlan={editDietPlan || copyDietPlan}
            isEdit={!!editDietPlan}
            isCopy={!!copyDietPlan}
          />
        </BottomSheet>
      )}
    </div>
  );
};

export default DietTaskPage;
