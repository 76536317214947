import React from "react";
import "./Badge.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  variant: "success" | "danger" | "info" | "warning";
}

const Badge = ({ children, className, variant }: Props) => {
  return (
    <div className={`badge badge-${variant} ${className}`}>{children}</div>
  );
};

export default Badge;
