import React, { useEffect, useRef } from "react";
import "./BottomSheet.style.scss";

interface BottomSheetProps {
  isOpen: boolean;
  showHeader?: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  showHeader = true,
  title,
  onClose,
  children,
}) => {
  const sheetRef = useRef<HTMLDivElement>(null);

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (sheetRef.current && !sheetRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll when the sheet is open
    } else {
      document.body.style.overflow = ""; // Restore scroll
    }
    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [isOpen]);

  return (
    <div
      className={`bottom-sheet ${isOpen ? "open" : ""}`}
      // onClick={handleBackgroundClick}
    >
      <div className="bottom-sheet-content" ref={sheetRef}>
        {showHeader && (
          <div className="header-container">
            <div className="title">{title}</div>
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>
          </div>
        )}
        <div className="bottom-sheet-main-content">{children}</div>
      </div>
    </div>
  );
};

export default BottomSheet;
