import { Assessment, Goal } from "../types/goal";

export default function processGoalData(
  mockData: Goal[],
  isPrimary: boolean = false
) {
  const processedData = {
    data: {
      [isPrimary ? "mainGoal" : "customGoals"]: {
        goals: {} as Record<string, Goal>,
        assessments: {} as Record<string, Assessment>,
        totalAssessment: 0,
      },
    },
  };

  mockData.forEach((goal) => {
    const goalType = isPrimary ? "mainGoal" : "customGoals";

    // Process goal
    processedData.data[goalType].goals[goal.id] = {
      id: goal.id,
      name: goal.name,
      type: goal.type,
      tag: goal.tag,
      role: goal.role,
      status: goal.status,
      isPrimary: goal.isPrimary,
      preAssessment: goal?.preAssessment,
      subGoals: goal?.subGoals,
      assessmentIds: [],
      goalScore: goal?.goalScore ?? null,
    };

    // Process subgoals and assessments
    goal?.subGoals?.forEach((subGoal) => {
      Object.entries(subGoal).forEach(([key, value]) => {
        if (key.startsWith("assessment_")) {
          const assessment = value as Assessment;
          processedData.data[goalType].assessments[key] =
            assessment.action === "preview"
              ? {
                  id: key,
                  isNew: true,
                  name: "New Assessment",
                  action: assessment.action,
                }
              : {
                  id: key,
                  value: assessment.value,
                  date: assessment.date,
                  isNew: false,
                  name: assessment.name,
                  action: assessment.action,
                };

          if (
            !processedData.data[goalType].goals[goal.id].assessmentIds.includes(
              key
            )
          ) {
            processedData.data[goalType].goals[goal.id].assessmentIds.push(key);
          }
        }
      });
    });
  });

  return processedData;
}
