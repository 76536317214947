import React, { useEffect, useState, useRef } from "react";
import "./ScoreProgress.scss";
import { Modal } from "antd";
import Loader from "../../../atoms/Loader";
import { useScoreProgress } from "../../../hooks/useScoreProgress";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const ScoreProgress = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const scoringHistory = useSelector(
    (state: RootState) => state.scoring.scoringHistory
  );
  const patientInfoStorage = localStorage.getItem("PatientInfo");

  useEffect(() => {
    if (patientInfoStorage) {
      try {
        setPatientInfo(JSON.parse(patientInfoStorage));
      } catch (error) {
        console.error("Error parsing patient info:", error);
      }
    }
  }, [patientInfoStorage]);

  const { isLoading, isGetScoringHistoryLoading, loadMoreData } =
    useScoreProgress();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const isNearRight =
      container.scrollLeft + container.clientWidth >=
      container.scrollWidth - 50;

    if (isNearRight && !isGetScoringHistoryLoading) {
      loadMoreData();
    }
  };

  return (
    <div className="score-progress">
      <div className="header" onClick={() => setIsExpanded(!isExpanded)}>
        {/* <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <h3
            style={{
              fontSize: "1.25rem",
              fontWeight: 600,
              color: "#2D3748",
              margin: 0,
            }}
          >
            Score Progress
          </h3>
          <span
            style={{
              fontSize: "1rem",
              color: "#2D3748",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            View User Details
          </span>
          {isModalOpen && (
            <Modal
              open={isModalOpen}
              onCancel={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
              }}
              onOk={(e) => {
                setIsModalOpen(false);
                e.stopPropagation();
              }}
              title="User Details"
            >
              <div onClick={(e) => e.stopPropagation()}>
                {renderUserDetailsModal()}
              </div>
            </Modal>
          )}
        </div> */}
        <span className={`chevron ${isExpanded ? "expanded" : ""}`}>▼</span>
      </div>

      <div className={`collapsible-content ${isExpanded ? "expanded" : ""}`}>
        {scoringHistory?.length > 0 && (
          <div
            className="progress-cards"
            ref={scrollContainerRef}
            onScroll={handleScroll}
            style={{
              display: "flex",
              overflowX: "auto",
              padding: "20px",
              gap: "10px",
              width: "100%",
              position: "relative",
            }}
          >
            {scoringHistory.map((card, index) => (
              <React.Fragment key={index}>
                <div
                  className="progress-card"
                  style={{ width: "205px", height: "175px" }}
                >
                  <div
                    className="gauge-container"
                    style={{ width: "100%", height: "60%" }}
                  >
                    <div className="gauge">
                      <div className="score">{`${
                        card?.score?.metabolism || 0
                      }`}</div>
                    </div>
                  </div>
                  <div className="details">
                    {card.updated_by.health_coach_name && (
                      <div className="name">{`${card.updated_by.health_coach_name} (${card.updated_by.health_coach_role})`}</div>
                    )}
                    <div className="date">
                      {new Date(card.updated_at).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                {index < scoringHistory?.length - 1 && (
                  <div className="arrow">˿</div>
                )}
              </React.Fragment>
            ))}
            {isGetScoringHistoryLoading && (
              <div
                style={{
                  position: "sticky",
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px",
                }}
              >
                <Loader noBackground={true} />
              </div>
            )}
          </div>
        )}
        {!isGetScoringHistoryLoading && scoringHistory?.length === 0 && (
          <div
            style={{
              position: "sticky",
              right: 0,
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <div className="no-history-message">
              No scoring history available
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreProgress;
