import React from "react";

import "./Tabs.style.scss";

interface Props {
  tabs: Array<{
    label: string;
    value: string;
  }>;
  activeTab?: string;
  onTabChange?: (activeTab: string) => void;
}

const Tabs: React.FC<Props> = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className="tabs-wrapper">
      <ul className="tabs-container">
        {tabs.map((tab) => (
          <li key={tab.value}>
            <button
              className={`tab ${activeTab === tab.value && "active-tab"}`}
              onClick={() => onTabChange && onTabChange(tab.value)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
