import React, { useEffect, useState } from "react";
import AssessmentGoal from "../../../molecules/Progress/AssessmentGoal/AssessmentGoal";
import useApi from "../../../hooks/useApi";
import API from "../../../services/rest/api";
import LOCAL_STORAGE from "../../../constants/localStorage";
import { toast } from "react-toastify";
// import { useSocket } from "../../../context/socket/SocketContext";

type SocketEventKeys =
  | "ANALYSE_REPORT_SUCCESS"
  | "ANALYSE_REPORT_FAILURE"
  | "REPORT_SYNC_DATA_SUCCESS"
  | "REPORT_SYNC_DATA_FAILURE";

const Diagnostics = ({
  mode = "view",
  isPrimary,
}: {
  mode?: "view" | "edit";
  isPrimary?: boolean;
}) => {
  // const { socket } = useSocket();
  // console.log("socket-1111", socket);

  // const handleSocketEvent = (eventName: SocketEventKeys, data: any) => {
  //   console.log("Received ", eventName, " with data: ", data);

  //   switch (eventName) {
  //     case "REPORT_SYNC_DATA_SUCCESS":
  //       return;
  //     case "REPORT_SYNC_DATA_FAILURE":
  //       return;
  //     default:
  //       return;
  //   }
  // };

  // useEffect(() => {
  //   if (socket) {
  //     socket.onAny(handleSocketEvent);
  //   }

  //   return () => {
  //     socket?.offAny();
  //   };
  // }, [socket]);

  return (
    <div>
      <AssessmentGoal
        activeTab="diagnostics"
        mode={mode}
        isPrimary={isPrimary}
      />
    </div>
  );
};

export default Diagnostics;
