import React, { FC, useEffect, useState } from "react";
import "./ExerciseAssessmentView.scss";
import API from "../../services/rest/api";
import { RiPencilFill } from "react-icons/ri";
import { Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import ReactSelect from "react-select";

type ExerciseAssessmentViewProps = {
  patient_id?: any;
  selectedAssessment?: any;
  getPatientAssessments?: any;
  chief_hc?: any;
  ifHomeScreen?: any;
  showCloseButton?: boolean;
  screen?: any;
  patientId?: any;
  selectedDisease?: any;
};

const ExerciseAssessmentView: FC<ExerciseAssessmentViewProps> = ({
  selectedAssessment,
  screen,
  chief_hc,
  patientId,
  selectedDisease: disease,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [assessmentTab, setAssessmentTab] = useState<any>(1);
  const [assessmentData, setAssessmentData] = useState<any>(null);
  const [assessmentOptions, setAssessmentOptions] = useState<any>([]);
  const [selectedDisease, setSelectedDisease] = useState<any>(null);
  const [diseaseOptions, setDiseaseOptions] = useState<any>([]);

  useEffect(() => {
    if ((params?.patientId || params.id) && params?.token) {
      const patient_id: any = params.id || params?.patientId;
      localStorage.setItem("patient_id", patient_id);
      const token: any = params.token;
      localStorage.setItem("token", token);
    }
  }, [params]);

  useEffect(() => {
    if (!selectedAssessment) {
      getPatientData();
    }
  }, []);

  useEffect(() => {
    if (selectedAssessment) {
      setAssessmentData(() => selectedAssessment);
    } else {
      setAssessmentData(null);
    }
  }, [selectedAssessment]);

  useEffect(() => {
    if (selectedDisease) {
      getPatientAssessments();
    }
  }, [selectedDisease]);

  useEffect(() => {
    // if (patientData) {
    getAllDiseases();
    // }
  }, []);

  //funtion to get patient assessments
  const getPatientAssessments = async () => {
    try {
      let res = await API.GetPatientAssessments({
        patient_id: params.id || params?.patientId,
        disease_type: selectedDisease,
        read_only: true,
        assessment_type: "exercise",
      });
      if (res?.code === "1") {
        let tempAssessmentData = res?.data?.map((data: any) => {
          return {
            ...data,
            label: data?.assessment_name,
            value: data?.patient_assessment_id,
          };
        });
        setAssessmentOptions(tempAssessmentData);
        setAssessmentData(res?.data[res?.data?.length - 1] || null);
        window.parent.postMessage(
          {
            messageType: "getSelectedAssessmentForExercise",
            assessmentData: res?.data[res?.data?.length - 1] || null,
          },
          "*"
        );
      } else {
        setAssessmentData(null);
        setAssessmentOptions([]);
        window.parent.postMessage(
          {
            messageType: "getSelectedAssessmentForExercise",
            assessmentData: null,
          },
          "*"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to get all diseases
  const getAllDiseases = async () => {
    try {
      const data = await API.GetDiseasesData();
      if (data?.code === "1") {
        setDiseaseOptions(data?.data);
      } else {
        setDiseaseOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //funtion to get patient data
  const getPatientData = async () => {
    try {
      let payload = {
        patient_id: params.id || params?.patientId,
      };
      const data = await API.GetPatientData(payload);
      if (data?.code === "1") {
        setSelectedDisease(data?.data?.disease);
      } else {
        setSelectedDisease(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editAssessment = (assessment: any) => {
    let encodedtoken = encodeURIComponent(params?.token || "");
    navigate(
      `/patients/${params?.hcId}/exercise-assessment/${params?.patientId}/${encodedtoken}`,
      { state: { data: assessment, button: "edit", screen: screen } }
    );
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "200px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  return (
    <div className="exercise-assessment-view-container">
      {screen !== "assessment_form_screen" && screen !== "chief_hc_screen" && (
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              gap: "8px",
            }}
          >
            <span
              style={{ fontSize: "14px", fontWeight: 700, color: "#760fb2" }}
            >
              Select Disease
            </span>
            <ReactSelect
              classNamePrefix="select"
              name="color"
              theme={(theme) => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#F0E7F7",
                  primary: "#760fb2",
                },
              })}
              options={diseaseOptions}
              onChange={(selectedOption: any) => {
                setSelectedDisease(selectedOption?.value);
              }}
              value={diseaseOptions.find(
                (val: any) => val.value == selectedDisease
              )}
              styles={customStyles}
              placeholder="Select Disease"
              //isDisabled={location?.state?.data ? true : false}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "200px",
              gap: "8px",
            }}
          >
            <span
              style={{ fontSize: "14px", fontWeight: 700, color: "#760fb2" }}
            >
              Select Assessment
            </span>
            <ReactSelect
              classNamePrefix="select"
              name="color"
              theme={(theme) => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#F0E7F7",
                  primary: "#760fb2",
                },
              })}
              options={assessmentOptions}
              onChange={(selectedOption: any) => {
                setAssessmentData(selectedOption);
                window.parent.postMessage(
                  {
                    messageType: "getSelectedAssessmentForExercise",
                    assessmentData: selectedOption,
                  },
                  "*"
                );
              }}
              value={assessmentOptions.find(
                (val: any) => val.value == assessmentData?.patient_assessment_id
              )}
              styles={customStyles}
              placeholder="Select Assessment"
              isClearable={true}
              // isDisabled={location?.state?.data ? true : false}
            />
          </div>
        </div>
      )}

      {assessmentData?.answer_list && (
        <>
          <div className="exercise-assessment-view-inner">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="exercise-assessment-heading">
                {assessmentData?.assessment_name}
              </span>
              <>
                {!chief_hc && (
                  <Tooltip
                    placement="topLeft"
                    title={"Click to Change Assessment"}
                  >
                    <RiPencilFill
                      style={{
                        color: "#760fb2",
                        cursor: "pointer",
                        height: "20px",
                        width: "20px",
                        marginRight: "10px",
                      }}
                      onClick={() => editAssessment(assessmentData)}
                    />
                  </Tooltip>
                )}
              </>
            </div>
          </div>

          <div className="exercise-assessment-view-tabs">
            <div
              className={
                assessmentTab === 1
                  ? "exercise-assessment-tab-view-active"
                  : "exercise-assessment-view-tab"
              }
              onClick={() => setAssessmentTab(1)}
            >
              Assessment
            </div>
            <div
              className={
                assessmentTab === 2
                  ? "exercise-assessment-tab-view-active"
                  : "exercise-assessment-view-tab"
              }
              onClick={() => setAssessmentTab(2)}
            >
              Data Points
            </div>
            <div
              className={
                assessmentTab === 3
                  ? "exercise-assessment-tab-view-active"
                  : "exercise-assessment-view-tab"
              }
              onClick={() => setAssessmentTab(3)}
            >
              Health Goals
            </div>
          </div>

          {assessmentTab === 1 && (
            <div className="exercise-assessment-view-inner">
              <div className="exercise-assessment-view-personal">
                <p className="exercise-assessment-view-personal-heading">
                  Personal History
                </p>
                <div className="exercise-personal-view-que-ans">
                  {assessmentData?.answer_list?.assessment_questions[0]?.questions_list.map(
                    (d: any) => (
                      <>
                        {d?.options?.length ? (
                          <>
                            {d?.is_multiselect == "Y" ? (
                              <div className="exercise-personal-view-que-ans-inner">
                                <div className="exercise-personal-view-que-ans-left">
                                  <span>
                                    {d?.order_no}. {d?.question}
                                  </span>{" "}
                                  <span>:</span>
                                </div>
                                <ul>
                                  {d?.answer?.split("<->").map((ans: any) => (
                                    <li
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {ans}
                                    </li>
                                  ))}
                                </ul>
                                {d?.other_answer && (
                                  <div className="exercise-assessment-view-ans">
                                    {d?.other_answer}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="exercise-personal-view-que-ans-inner">
                                <div className="exercise-personal-view-que-ans-left">
                                  <span>
                                    {d?.order_no}. {d?.question}
                                  </span>{" "}
                                  <span>:</span>
                                </div>
                                <div className="exercise-personal-view-que-ans-right">
                                  {d?.answer}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="exercise-personal-view-que-ans-inner">
                            <div className="exercise-personal-view-que-ans-left">
                              <span>
                                {d?.order_no}. {d?.question}
                              </span>{" "}
                              <span>:</span>
                            </div>
                            <div className="exercise-personal-view-que-ans-right">
                              {d?.answer}
                            </div>
                          </div>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>

              <div className="exercise-assessment-view-other">
                {assessmentData?.answer_list?.assessment_questions
                  .slice(1)
                  .map((d: any) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="exercise-assessment-view-other-heading">
                        {d?.section_label}
                      </span>

                      {d?.questions_list.map((que: any) => (
                        <>
                          {d?.section_type === "target" ? (
                            <>
                              <div className="exercise-assessment-view-target-que-ans">
                                <span className="exercise-assessment-view-que">
                                  {que?.order_no}. {que?.question}
                                </span>
                                {que?.answer && (
                                  <div className="exercise-assessment-view-ans">
                                    <div className="title">
                                      {JSON.parse(que.answer)?.value}{" "}
                                      {JSON.parse(que.answer)?.value && "->"}
                                    </div>
                                    <div className="value">
                                      {JSON.parse(que.answer)?.target_days}{" "}
                                      {JSON.parse(que.answer)?.target_days &&
                                        "days"}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {que?.sub_questions &&
                              que?.sub_questions.length ? (
                                <div className="exercise-assessment-view-que-ans">
                                  <span className="exercise-assessment-view-que">
                                    {que?.order_no}. {que?.question}
                                  </span>
                                  {que?.sub_questions.map(
                                    (subQue: any, subQueIdx: any) => (
                                      <div
                                        className="exercise-assessment-view-que-ans"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <span
                                          className="exercise-assessment-view-que"
                                          style={{ fontSize: "13px" }}
                                        >
                                          {subQueIdx + 1}. {subQue?.question}
                                        </span>
                                        <div className="exercise-assessment-view-ans">
                                          {subQue?.answer}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <>
                                  {que?.options?.length ? (
                                    <>
                                      {que?.is_multiselect == "Y" ? (
                                        <div className="exercise-assessment-view-que-ans">
                                          <span className="exercise-assessment-view-que">
                                            {que?.order_no}. {que?.question}
                                          </span>
                                          <ul>
                                            {que?.answer
                                              ?.split("<->")
                                              .map((ans: any) => (
                                                <li
                                                  style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {ans}
                                                </li>
                                              ))}
                                          </ul>
                                          {que?.other_answer && (
                                            <div className="exercise-assessment-view-ans">
                                              {que?.other_answer}
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="exercise-assessment-view-que-ans">
                                          <span className="exercise-assessment-view-que">
                                            {que?.order_no}. {que?.question}
                                          </span>
                                          <div className="exercise-assessment-view-ans">
                                            {que?.answer}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="exercise-assessment-view-que-ans">
                                      <span className="exercise-assessment-view-que">
                                        {que?.order_no}. {que?.question}
                                      </span>
                                      <div className="exercise-assessment-view-ans">
                                        {que?.answer}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          )}

          {assessmentTab === 2 && (
            <div className="data-points-table-main-view">
              <table className="data-points-table-view">
                <thead>
                  <tr className="data-points-table-header-row-view">
                    <th className="data-points-table-header-data-view fixed-column">
                      Parameters to review ( Proactive )
                    </th>
                    {new Array(
                      Math.max(
                        ...(
                          assessmentData?.answer_list?.data_points_questions ||
                          []
                        ).map((o: any) => (o?.answer || []).length),
                        1
                      )
                    )
                      .fill(0)
                      .map((p, i) => i)
                      .map((headerIndex) => {
                        return (
                          <th className="data-points-table-header-data-view">
                            Week {headerIndex + 1}
                            {/* & {headerIndex * 2 + 2} */}
                          </th>
                        );
                      })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="data-points-table-body-data-view fixed-column">
                      Select Date
                    </td>
                    {new Array(
                      Math.max(
                        ...(
                          assessmentData?.answer_list?.data_points_questions ||
                          []
                        ).map((o: any) => (o?.answer || []).length),
                        1
                      )
                    )
                      .fill(0)
                      .map((p, i) => i)
                      .map((headerIndex) => {
                        return (
                          <td className="data-points-table-body-data-view">
                            {
                              assessmentData?.answer_list
                                ?.data_points_questions[0].exercise_dates[
                                headerIndex
                              ]
                            }
                          </td>
                        );
                      })}
                  </tr>
                  {(
                    assessmentData?.answer_list?.data_points_questions || []
                  )?.map((point: any, pointIdx: any) => {
                    // let value;
                    // const pointQuestion = point?.question;
                    // const dataPointsKeys = dataPointsToDisable.map(obj => Object.keys(obj)[0]);

                    // if (dataPointsKeys.includes(pointQuestion)) {
                    //     const assessmentQuestions = patientAssessments[headerIndex]?.answer_list?.assessment_questions || [];
                    //     const physioSpecific = assessmentQuestions.find((a: any) => a.section_type === 'physio_specific');
                    //     const strengthQuestions = physioSpecific?.questions_list?.find((a: any) => a.question === 'Strength')?.sub_questions || [];

                    //     dataPointsToDisable.forEach(dp => {
                    //         const questionKey = dp[pointQuestion];
                    //         const answer = strengthQuestions.find((q: any) => q.question === questionKey)?.answer;
                    //         if (answer) {
                    //             value = answer;
                    //         }
                    //     });
                    // } else {
                    //     value = dataPoints[pointIdx].answer[headerIndex];
                    // }
                    return (
                      <tr>
                        <td className="data-points-table-body-data-view fixed-column">
                          {point?.question}
                        </td>
                        {new Array(
                          Math.max(
                            ...(
                              assessmentData?.answer_list
                                ?.data_points_questions || []
                            ).map((o: any) => (o?.answer || []).length),
                            1
                          )
                        )
                          .fill(0)
                          .map((p, i) => i)
                          .map((headerIndex) => {
                            return (
                              <td className="data-points-table-body-data-view">
                                {assessmentData?.answer_list
                                  ?.data_points_questions[pointIdx].answer[
                                  headerIndex
                                ] || "-"}
                                {point?.measurement && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      marginLeft: "4px",
                                    }}
                                  >{`(${point?.measurement})`}</span>
                                )}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {assessmentTab === 3 && (
            <div className="health-goals-table-main-view">
              <table className="health-goals-table-view">
                <thead>
                  <tr className="health-goals-table-header-row-view">
                    <th className="health-goals-table-header-data-view fixed-column">
                      Parameters to review ( Proactive )
                    </th>
                    {new Array(
                      Math.max(
                        ...(
                          assessmentData?.answer_list?.health_goals_questions ||
                          []
                        ).map((o: any) => (o?.answer || []).length),
                        1
                      )
                    )
                      .fill(0)
                      .map((p, i) => i)
                      .map((headerIndex) => {
                        return (
                          <th className="health-goals-table-header-data-view">
                            Week {headerIndex + 1}
                            {/* & {headerIndex * 2 + 2} */}
                          </th>
                        );
                      })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="health-goals-table-body-data-view fixed-column">
                      Select Date
                    </td>
                    {new Array(
                      Math.max(
                        ...(
                          assessmentData?.answer_list?.health_goals_questions ||
                          []
                        ).map((o: any) => (o?.answer || []).length),
                        1
                      )
                    )
                      .fill(0)
                      .map((p, i) => i)
                      .map((headerIndex) => {
                        return (
                          <td className="health-goals-table-body-data-view">
                            {
                              assessmentData?.answer_list
                                ?.health_goals_questions[0].exercise_dates[
                                headerIndex
                              ]
                            }
                          </td>
                        );
                      })}
                  </tr>
                  {(
                    assessmentData?.answer_list?.health_goals_questions || []
                  )?.map((point: any, pointIdx: any) => {
                    return (
                      <tr>
                        <td className="health-goals-table-body-data-view fixed-column">
                          {point?.question}
                        </td>
                        {new Array(
                          Math.max(
                            ...(
                              assessmentData?.answer_list
                                ?.health_goals_questions || []
                            ).map((o: any) => (o?.answer || []).length),
                            1
                          )
                        )
                          .fill(0)
                          .map((p, i) => i)
                          .map((headerIndex) => {
                            return (
                              <td className="health-goals-table-body-data-view">
                                {assessmentData?.answer_list
                                  ?.health_goals_questions[pointIdx].answer[
                                  headerIndex
                                ] || "-"}
                                {point?.measurement && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "700",
                                      marginLeft: "4px",
                                    }}
                                  >{`(${point?.measurement})`}</span>
                                )}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {screen === "assessment_form_screen" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <button
                className="add-que-button"
                onClick={() => {
                  window.parent.postMessage("cancelAssessment", "*");
                }}
              >
                Close
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExerciseAssessmentView;
