import React, { useState, useEffect } from "react";
import useApi from "../../../hooks/useApi";
import { Tooltip } from "antd";

import Table from "../../../atoms/Table/Table";
import RecordsFilter from "./RecordsFilter";
import Popup from "../../../atoms/Popup/Popup";
import UploadRecordModal from "./UploadRecordModal";

import API from "../../../services/rest/api";
import LOCAL_STORAGE from "../../../constants/localStorage";
import { truncate } from "../../../utils/common";

import "./RecordsPage.scss";
import { TABLE } from "../../../constants/common";
import { formatDateTime } from "../../../utils/date-and-time";

const RecordsPage: React.FC<any> = () => {
  const {
    mutate: getRecords,
    isLoading,
    response: recordsResponse,
    error: recordsError,
  } = useApi(API.GetRecords);

  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageSize, setPageSize] = useState(TABLE.DEFAULT_PAGINATION);
  const [filters, setFilters] = useState({
    search: "",
    recordType: "",
  });

  const [showUploadRecordModal, setShowUploadRecordModal] = useState(false);

  const patientId = localStorage.getItem(LOCAL_STORAGE.PATIENT_ID);

  useEffect(() => {
    fetchRecords();
  }, [patientId, currentPage, pageSize, filters]); // Fetch records when currentPage or pageSize changes

  useEffect(() => {
    if (Number(recordsResponse?.code || 0) === 1) {
      setRecords(recordsResponse.data);
      setTotalRecords(recordsResponse.total_data || 0);
    } else if (recordsResponse || Number(recordsResponse?.code || 0) === 2) {
      setRecords([]);
      setTotalRecords(0);
      setErrorMessage(recordsResponse?.message || "Something went wrong!");
    } else if (recordsError || Number(recordsError?.code || 0) === 2) {
      setRecords([]);
      setTotalRecords(0);
      setErrorMessage(recordsError?.message || "Something went wrong!");
    }
  }, [recordsResponse, recordsError]);

  const resetData = () => {
    setCurrentPage(1);
    setPageSize(TABLE.DEFAULT_PAGINATION);
    setFilters({
      search: "",
      recordType: "",
    });
  };

  const refresh = () => {
    resetData();
  };

  const fetchRecords = () => {
    const payload = {
      page: currentPage,
      per_page: pageSize,
      patient_id: patientId,
      search: filters?.search,
      filter: filters?.recordType,
    };
    getRecords(payload);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size); // Set the new page size
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const columns = [
    {
      label: "Sl. No.",
      accessor: "index",
      // render: (value: any, row: any) => <span>{row.index + 1}</span>,
      render: (_value: any, _row: any, rowIndex: number) =>
        (currentPage - 1) * pageSize + rowIndex + 1, // Calculate Sl. No. based on current page and page size
    },
    {
      label: "Name",
      accessor: "name",
      render: (value: any, row: any) => (
        <div className="table-name-col">
          <Tooltip title={row.name}>
            <span>{truncate(row.name)}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      label: "Report Type",
      accessor: "label",
      render: (value: any, row: any) => <span>{row.label}</span>,
    },
    {
      label: "Created At",
      accessor: "created_at",
      render: (value: any, row: any) =>
        formatDateTime({
          timestamp: row.created_at,
          format: 12,
          showTime: true,
        }),
    },
    // {
    //   label: "Updated At",
    //   accessor: "updated_at",
    //   render: (value: any, row: any) =>
    //     formatDateTime({
    //       timestamp: row.updated_at,
    //       format: 12,
    //       showTime: true,
    //     }),
    // },
    {
      label: "Actions",
      accessor: "document_url",
      render: (value: any, row: any) => {
        const url = Array.isArray(row.document_url)
          ? row.document_url[0]
          : row.document_url;
        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            // download={`${row.name}.pdf`}
          >
            View Document
          </a>
          // <a
          //   href={url}
          //   target="_blank"
          //   rel="noopener noreferrer"
          //   onClick={(e) => {
          //     e.preventDefault();
          //     fetch(url)
          //       .then((response) => response.blob())
          //       .then((blob) => {
          //         const contentType = blob.type; // Get the file type
          //         const extension = contentType.split("/")[1]; // Extract the file extension
          //         const blobUrl = URL.createObjectURL(blob);

          //         const newTab = window.open(blobUrl, "_blank");
          //         if (newTab) {
          //           // Automatically download with a new name when opened
          //           setTimeout(() => {
          //             const link = newTab.document.createElement("a");
          //             link.href = blobUrl;
          //             link.download = `${row.name}.${extension}`; // Dynamic filename with extension
          //             try {
          //               link?.click();
          //             } catch(error) {
          //               console.error("Error downloading the file:", error);
          //               alert("Failed to open the document. Please try again.");
          //             }
          //             newTab?.close();
          //           }, 500); // Allow time for the new tab to load the blob
          //         }
          //       })
          //       .catch((error) => {
          //         console.error("Error downloading the file:", error);
          //         alert("Failed to open the document. Please try again.");
          //       });
          //   }}
          // >
          //   View Document
          // </a>
        );
      },
    },
  ];

  const handleApplyFilter = (newFilters: any) => {
    setFilters(newFilters);
    setCurrentPage(1); // Reset page
    // setTimeout(fetchRecords, 0); // Ensure fetchRecords uses updated state
  };

  const handleClearFilter = () => {
    resetData();
    // setTimeout(fetchRecords, 0);
  };

  const handleUploadSuccess = async () => {
    await resetData();
    await fetchRecords();
    await setShowUploadRecordModal(false);
  };

  return (
    <div className="records-page-container">
      <h2 className="page-title">Patient Records</h2>

      <RecordsFilter
        handleApplyFilter={handleApplyFilter}
        handleClearFilters={handleClearFilter}
        showUploadRecordModal={() => setShowUploadRecordModal(true)}
        refresh={refresh}
      />

      {/* Table with Loader */}
      <Table
        data={records}
        columns={columns}
        showPagination={true}
        totalRecords={totalRecords}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isLoading={isLoading} // Pass the isLoading state to the Table component
        errorMessage={errorMessage}
      />

      {showUploadRecordModal && (
        <div>
          <button onClick={() => setShowUploadRecordModal(true)}>
            Show Popup
          </button>
          <Popup
            show={showUploadRecordModal}
            headerText="Upload Record"
            onClose={() => setShowUploadRecordModal(false)}
          >
            <UploadRecordModal
              onSuccess={handleUploadSuccess}
              onCancel={() => setShowUploadRecordModal(false)}
            />
          </Popup>
        </div>
      )}
    </div>
  );
};

export default RecordsPage;
