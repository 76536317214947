import React, { useState } from "react";
import "./ExercisePlanPage.scss";

import Tabs from "../../../../atoms/Tabs/Tabs";
import UserView from "../../../../molecules/DietV2/DietViews/UserView";
import RedirectComponent from "../../../../atoms/RedirectComponent/RedirectComponent";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

const tabs = [
  {
    label: "User View",
    value: "USER_VIEW",
  },
  {
    label: "Exercise Plans",
    value: "EXERCISE_PLAN",
  },
];

const ExercisePlanPage: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const { patientDetails } = useSelector((state: RootState) => state.patient);

  const handleTabChange = (activeTab: any) => {
    console.log(activeTab);
    setActiveTab(activeTab);
  };

  return (
    <div className="exercise-plan-page-container">
      <h2 className="page-title">Exercise</h2>

      <div>
        <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={activeTab} />
      </div>

      <div className="exercise-plan-content">
        {activeTab === "USER_VIEW" ? (
          <UserView
            mobileNumber={patientDetails?.data?.contact_no}
            name={patientDetails?.data?.name}
            moduleName="exercise"
          />
        ) : activeTab === "EXERCISE_PLAN" ? (
          <RedirectComponent moduleName="exercise" />
        ) : null}
      </div>
    </div>
  );
};

export default ExercisePlanPage;
