import { useState, useEffect } from 'react';
import useApi from './useApi';
import API from '../services/rest/api';
import { toast } from 'react-toastify';
import LOCAL_STORAGE from '../constants/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { setScoring } from '../store/slices/scoringSlice';
import { RootState } from '../store/store';

interface UpdatedBy {
  health_coach_id: string;
  health_coach_name: string;
  health_coach_email: string;
  health_coach_role: string;
}

interface Score {
  profile: number;
  metabolism: number;
  wellness: number;
  habit: number;
}

interface ProgressCard {
  assessment_id: number;
  score: Score;
  updated_at: string;
  updated_by: UpdatedBy;
}

export const useScoreProgress = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const patientId = localStorage.getItem(LOCAL_STORAGE.PATIENT_ID);
  const cardsPerPage = 4;
  const dispatch = useDispatch();
  const patientInfo = JSON.parse(localStorage.getItem("PatientInfo") || "{}");
  const { totalScores, scoringHistory } = useSelector((state: RootState) => state.scoring);
  const {
    mutate: getScoringHistory,
    response: getScoringHistoryResponse,
    error: getScoringHistoryError,
    isLoading: isGetScoringHistoryLoading,
  } = useApi(API.GetScoringHistory);

  useEffect(() => {
    if (getScoringHistoryResponse?.code?.toString() === "1") {
      dispatch(setScoring({data: getScoringHistoryResponse?.data, page: currentPage}));
    } else if (getScoringHistoryResponse?.code?.toString() === "0") {
      toast.error(getScoringHistoryResponse?.message || "Failed to get scoring history");
    }
  }, [getScoringHistoryResponse]);


  useEffect(() => {
    if (patientId) {
      getScoringHistoryData();
    }
  }, [patientId]);

  const getScoringHistoryData = () => {
    const payload = {
      patient_id: patientId,
      page: 1,
      per_page: cardsPerPage,
      disease: patientInfo?.disease || "",
    };
    try {
      getScoringHistory(payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadMoreData = async () => {
    if (isLoading) return;
    if (currentPage * cardsPerPage >= totalScores) return;
    
    setIsLoading(true);
    try {
      const payload = {
        patient_id: patientId,
        page: currentPage + 1,
        per_page: cardsPerPage,
        disease: patientInfo?.disease || "",
      };
      await getScoringHistory(payload);
      setCurrentPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isGetScoringHistoryLoading,
    loadMoreData,
    getScoringHistoryData
  };
}; 