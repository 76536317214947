export const formatDateTime = ({
  timestamp = "",
  format = 24,
  showTime = true,
}: {
  timestamp: string;
  format?: 12 | 24;
  showTime?: boolean;
}) => {
  // Ensure the timestamp is properly formatted for the Date constructor
  const date = new Date(timestamp.replace(" ", "T"));

  if (isNaN(date.getTime())) {
    return "Invalid Date"; // Handle invalid timestamps
  }

  // Extract individual date components
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  if (!showTime) {
    return `${day} ${month} ${year}`;
  }

  const hours = format === 24 ? date.getHours() : date.getHours() % 12 || 12;
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const ampm = format === 12 ? (date.getHours() >= 12 ? "PM" : "AM") : "";

  // Construct the formatted date string
  return `${day} ${month} ${year}  |  ${String(hours).padStart(
    2,
    "0"
  )}:${minutes}:${seconds} ${ampm}`.trim();
};

// Example Usage
// const input = "2025-01-16 14:58:29";
// console.log(formatDateTime(input, 12)); // Output: "16 Jan 2025 02:58:29 PM"
// console.log(formatDateTime(input, 24)); // Output: "16 Jan 2025 14:58:29"

export const getAgeByDob = (dob: string | null) => {
  if (!dob) return null;
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
