import React from "react";
import "./HealthProgress.scss";
import { HealthProgressData } from "../../../../../types/goal";

interface HealthProgressProps {
  data: any;
}

const HealthProgress: React.FC<HealthProgressProps> = ({ data }) => {
  const scoreCard = [
    {
      title: "Wellness Score",
      value: data.wellness,
      trend: data.wellnessTrend,
    },
    {
      title: "Habit Score",
      value: data.habit,
      trend: data.habitTrend,
    },
    {
      title: "Profile Score",
      value: data.profile,
      trend: data.profileTrend,
    },
  ];
  const renderHealthProgress = (data: any) => (
    <div className="progress-section">
      <div className="progress-cards">
        <div className="progress-card">
          <div className="gauge-container">
            <div
              className="gauge"
              style={
                {
                  "--value": `${(data.metabolism / data.maxScore) * 100}%`,
                } as React.CSSProperties
              }
            >
              <div className="score">{data.metabolism}</div>
            </div>
            <div className="range">
              <span>{data.minScore}</span>
              <span>{data.maxScore}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="score-cards">
        {scoreCard.map((card, index) => (
          <div key={index} className="score-card">
            <h2>{card?.value}</h2>
            <p>{card?.title}</p>
            <div
              className={`change 
                ${
                  card?.trend?.type === ""
                    ? ""
                    : card?.trend?.type === "+"
                    ? "increase"
                    : "decrease"
                }`}
            >
              {card?.trend?.factor > 0 ? (
                <span>
                  {card?.trend?.type === ""
                    ? ""
                    : card?.trend?.type === "+"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by {card?.trend?.factor}
                </span>
              ) : (
                <span>{data?.dataIndex !== 0 ? "No change" : ""}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="health-progress">
      <div className="assessment-header">
        <h3>{data.id}</h3>
        <span className="date">{data.date}</span>
      </div>
      <div className="progress-section">{renderHealthProgress(data)}</div>
    </div>
  );
};

export default HealthProgress;
