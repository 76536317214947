import React from "react";
import AssessmentGoal from "../../../molecules/Progress/AssessmentGoal/AssessmentGoal";

const SuccessCoach = ({
  mode = "view",
  isPrimary,
}: {
  mode?: "view" | "edit";
  isPrimary?: boolean;
}) => {
  return (
    <div>
      <AssessmentGoal activeTab="success" mode={mode} isPrimary={isPrimary} />
    </div>
  );
};

export default SuccessCoach;
