import { FC, useEffect, useState } from "react";
import { Checkbox, Modal, Progress } from "antd";
import moment from "moment";

import API from "../../services/rest/api";
import { BloodPressureIcon, BmiIcons, BodyCompositionIcon, CarbIcon, DecreasedIcon, DoctorNotesIcon, FatIcon, FoodDiaryIcon, HealthCoachIcon, HipIcons, IncreasedIcon, LDLIcon, LungsIcon, OtherLifeIcon, ProteinIcon, PusleIcon, SGPTIcons, SPO2Icon, TemperatureIcon, VitalsIcon, WaistIcons, WaistToHipIcons, WeightIcons } from "../../assets/icons";
import PatientReportView from "../PatientReportView/PatientReportView";
import Button from "../../atoms/Button/Button";

import MyTatvaLogo from '../../assets/icons/MyTatva_logo.svg'
import LungIcon from '../../assets/icons/lung.png'
import WalkIcon from '../../assets/icons/six_min_walk.png'
import LiverIcon from '../../assets/icons/liver.png'
import KidneyIcon from '../../assets/icons/kidney.png'
import CardioIcon from '../../assets/icons/cardiology.png'
import CaloriesBurnIcon from '../../assets/icons/calories_burned.png'
import AccessbilityIcon from '../../assets/icons/accessibility.png'
import { ProfileIcon } from "../../assets/icons";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import './PatientReport.scss';

type PatientReportProps = {
    patient_id?: any,
    health_coach_id?: any,
    reportPeiodDates?: any,
    fetchDietData?: any,
    selectedDietPlan?: any,
    userInfo?: any,
    setSelectedDietPlan?: any,
    setReportPeriodDates?: any
}

const PatientReport: FC<PatientReportProps> = ({
    patient_id,
    health_coach_id,
    reportPeiodDates,
    fetchDietData,
    selectedDietPlan,
    userInfo,
    setSelectedDietPlan,
    setReportPeriodDates
}) => {
    const [patientDetails, setPatientDetails] = useState<any>(null)
    const [reportDetails, setReportDetails] = useState<any>(null)
    const [selectedOptions, setSelectedOptions] = useState<any>({
        patient_profile_notes: 'Congratulations on completing your health diagnosis for the month! This significant milestone demonstrates your commitment to maintaining optimal health and identifying areas for improvement. Well done!'
    });
    const [reportModal, setReportModal] = useState<any>(false)

    useEffect(() => {
        patientData()
    }, [patient_id, health_coach_id, reportPeiodDates])

    useEffect(() => {
        getReportDetails()
    }, [patient_id, health_coach_id, reportPeiodDates?.start_date, reportPeiodDates?.end_date])

    //funtion to get generate report data
    const getReportDetails = async () => {
        try {
            let payload = {
                patient_id: patient_id,
                start_date: reportPeiodDates?.start_date,
                end_date: reportPeiodDates?.end_date,
                report_type: 'diet'
            }
            const reportData = await API.GetPatientReportData(payload)
            if (reportData?.code === '1') {
                setReportDetails(reportData?.data)
            }
            else {
                console.log(reportData?.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    //funtion to get patient data
    const patientData = async () => {
        try {
            let payload = {
                patient_id: patient_id,
                health_coach_id: health_coach_id
            }
            const patientData = await API.GetPatientDetails(payload)
            setPatientDetails(patientData?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (mainType: any, type: any) => {
        // Check if mainType exists in the initialData and if type exists within it
        if (reportDetails && reportDetails[mainType] && reportDetails[mainType][type]) {
            const updatedSelectedOptions = { ...selectedOptions };

            // Toggle the selection status of the option
            if (!updatedSelectedOptions[mainType]) {
                updatedSelectedOptions[mainType] = {};
            }

            const isOptionSelected = updatedSelectedOptions[mainType][type];

            if (!isOptionSelected) {
                updatedSelectedOptions[mainType][type] = reportDetails[mainType][type];
            } else {
                delete updatedSelectedOptions[mainType][type];
                if (Object.keys(updatedSelectedOptions[mainType]).length === 0) {
                    delete updatedSelectedOptions[mainType];
                }
            }
            setSelectedOptions(updatedSelectedOptions);
        }
    };

    const onChangeFirstObj = (mainType: any) => {
        const updatedSelectedOptions = { ...selectedOptions };

        if (!updatedSelectedOptions[mainType]) {
            updatedSelectedOptions[mainType] = reportDetails[mainType];
        } else {
            delete updatedSelectedOptions[mainType];
        }
        setSelectedOptions(updatedSelectedOptions);
    };

    const onChangeForArrayVitals = (data: any) => {
        const updatedSelectedOptions = { ...selectedOptions };

        if (!updatedSelectedOptions.vitals_summary) {
            updatedSelectedOptions.vitals_summary = {
                other_vitals: [data],
            };
            setSelectedOptions(updatedSelectedOptions);
        } else {
            const otherVitals = updatedSelectedOptions.vitals_summary.other_vitals || [];
            const index = otherVitals.findIndex((v: any) => v.reading_name === data?.reading_name);

            if (index === -1) {
                updatedSelectedOptions.vitals_summary.other_vitals = [...otherVitals, data];
                setSelectedOptions(updatedSelectedOptions);
            } else {
                otherVitals.splice(index, 1);
                if (otherVitals.length === 0) {
                    delete updatedSelectedOptions.vitals_summary.other_vitals;

                    if (Object.keys(updatedSelectedOptions.vitals_summary).length === 0) {
                        delete updatedSelectedOptions.vitals_summary;
                    }
                }
                setSelectedOptions(updatedSelectedOptions);
            }
        }
    };

    const renderInItData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : <span style={{ fontSize: '8px', alignSelf: 'center' }}>Data Not Provided</span>;
    };

    const renderAchievedData = (num: any) => {
        return (typeof num === 'number' && num !== 0) ? Number(num).toFixed(2) : '-';
    };

    return (
        <>
            {reportPeiodDates?.start_date && reportPeiodDates?.end_date &&
                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px', fontFamily: 'Poppins' }}>
                    <div className="patient-report-container">
                        <div className="report-header">
                            <div className="report-title">
                                Smart Health Report
                            </div>
                            <div>
                                <img src={MyTatvaLogo} />
                            </div>
                        </div>

                        <div className="patient-details-report">
                            <div className="patient-details-first-row">
                                <div className="patient-profile-sec">
                                    <div className="patient-profile-pic">
                                        {/* <img src={patientDetails?.profile_pic} alt="Profile" /> */}
                                        <ProfileIcon />
                                    </div>
                                    <div className="patient-name-age-container">
                                        <span style={{ fontSize: '14px', fontWeight: '600' }}>{patientDetails?.name}</span>
                                        <span style={{ fontSize: '10px', fontWeight: '400' }}>{patientDetails?.gender === 'M' ? 'Male' : 'Female'} • {patientDetails?.patient_age} yrs</span>
                                    </div>
                                </div>

                                <div className="hc-profile-sec">
                                    <HealthCoachIcon />
                                    <div className="hc-profile-name">
                                        <span style={{ fontWeight: '400', fontSize: '9px' }}>Health Coach</span>
                                        <span style={{ fontWeight: '600', fontSize: '10px' }}>{userInfo?.first_name} {userInfo?.last_name}</span>
                                    </div>
                                    <div style={{ border: '1px solid #4B4AD5', marginLeft: '20px' }}></div>
                                    <div className="hc-profile-name" style={{ marginLeft: '10px' }}>
                                        <span style={{ fontWeight: '600', fontSize: '12px', color: '#4B4AD5', lineHeight: 'normal', fontFamily: 'Poppins' }}>{patientDetails?.plan_name}</span>
                                        <span style={{ fontWeight: '400', fontSize: '9px' }}>End date - {moment(patientDetails?.expiry_date, 'YYYY-MM-DD').format('DD MMM YYYY')}</span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ border: '1px solid #E2E2EA', margin: '20px auto 0px auto', width: '97.5%' }}></div>

                            <div className="patient-details-second-row">
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Height</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.height || '-'} {patientDetails?.height_unit}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Weight</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.weight || '-'} {patientDetails?.weight_unit}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Disease</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{patientDetails?.medical_condition_name || '-'}</span>
                                </div>
                                <div className="patient-details-second">
                                    <span style={{ fontSize: '9px', fontWeight: '400' }}>Report Period</span>
                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>{reportPeiodDates?.start_date} - {reportPeiodDates?.end_date}</span>
                                </div>
                            </div>

                            <div className="hc-month-content">
                                <textarea onChange={(e) => {
                                    let temp = selectedOptions;
                                    temp.patient_profile_notes = e.target.value;
                                    setSelectedOptions(temp)
                                }} style={{ resize: 'none', width: '100%', border: 'none', borderRadius: '10px', fontSize: '9px', fontWeight: '500', padding: '8px' }}>
                                    {selectedOptions?.patient_profile_notes}
                                </textarea>
                            </div>
                        </div>

                        <div className="reports-all-tables">

                            {/*Body Composition*/}
                            <div className="table-container">
                                <div className="table-heading">
                                    <BodyCompositionIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Body Composition</span>
                                </div>

                                <div className="table-header">
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Parameters
                                    </div>
                                    <div style={{ width: '33%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    {/* <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Goals</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                        </div>
                                    </div> */}
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Achieved
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'weight')} />
                                        <WeightIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Weight (Kg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.weight?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.weight?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.weight?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.weight?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.weight?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.weight?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'bmi')} />
                                        <BmiIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>BMI (kg/m2 )</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.bmi?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.bmi?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.bmi?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.bmi?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.bmi?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.bmi?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'waist')} />
                                        <WaistIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.waist?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.waist?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.waist?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.waist?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.waist?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.waist?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'hip')} />
                                        <HipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Hip (cm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.hip?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.hip?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.hip?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.hip?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.hip?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.hip?.achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('body_composition', 'waist_hip_ratio')} />
                                        <WaistToHipIcons />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Waist to Hip Ratio</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.body_composition?.waist_hip_ratio?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.body_composition?.waist_hip_ratio?.initial_value)}
                                    </div>
                                    {/* <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {reportDetails?.body_composition?.waist_hip_ratio?.goal_value || 0}
                                    </div> */}
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        {/* <Progress type="circle" percent={reportDetails?.body_composition?.waist_hip_ratio?.achieved_value} size={20} /> */}
                                        {/* {Number(reportDetails?.body_composition?.waist_hip_ratio?.achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.body_composition?.waist_hip_ratio?.achieved_value)}
                                    </div>
                                </div>
                            </div>

                            {/*Vitals Summary*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <VitalsIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Vitals Summary</span>
                                </div>

                                <div className="table-header">
                                    <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Parameters
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Initials</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Final</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '30%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        %Change
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'temperature')} />
                                        <TemperatureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Temperature (C)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.temperature?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.temperature?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.temperature?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.temperature?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.vitals_summary?.temperature?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.vitals_summary?.temperature?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.vitals_summary?.temperature?.percentage_changed}% {reportDetails?.vitals_summary?.temperature?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.vitals_summary?.temperature?.percentage_changed}% {reportDetails?.vitals_summary?.temperature?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'pulse')} />
                                        <PusleIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Pulse (bpm)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.pulse?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.pulse?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.pulse?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.pulse?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.vitals_summary?.pulse?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.vitals_summary?.pulse?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.vitals_summary?.pulse?.percentage_changed}% {reportDetails?.vitals_summary?.pulse?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.vitals_summary?.pulse?.percentage_changed}% {reportDetails?.vitals_summary?.pulse?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'resp_rate')} />
                                        <LungsIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Resp. Rate (/min)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.resp_rate?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.resp_rate?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.resp_rate?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.temperature?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.vitals_summary?.resp_rate?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.vitals_summary?.resp_rate?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.vitals_summary?.resp_rate?.percentage_changed}% {reportDetails?.vitals_summary?.resp_rate?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.vitals_summary?.resp_rate?.percentage_changed}% {reportDetails?.vitals_summary?.resp_rate?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'blood_pressure')} />
                                        <BloodPressureIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Blood Pressure (mmHg)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {(!reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic &&
                                            !reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic)
                                            ? <span style={{ fontSize: '8px' }}>Data Not Provided</span>
                                            : <>{reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic || '-'}</>
                                        }
                                        {/* {reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.initial_reading_value_data?.diastolic || '-'} */}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {(!reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic &&
                                            !reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic)
                                            ? <>-</>
                                            : <>{reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic || '-'}</>
                                        }
                                        {/* {reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.systolic || '-'} / {reportDetails?.vitals_summary?.blood_pressure?.final_reading_value_data?.diastolic || '-'} */}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.vitals_summary?.blood_pressure?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.vitals_summary?.blood_pressure?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.vitals_summary?.blood_pressure?.percentage_changed}% {reportDetails?.vitals_summary?.blood_pressure?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.vitals_summary?.blood_pressure?.percentage_changed}% {reportDetails?.vitals_summary?.blood_pressure?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                        <Checkbox onChange={() => onChange('vitals_summary', 'spo2')} />
                                        <SPO2Icon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>SPO2</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.spo2?.initial_value).toFixed(2) || '-'} */}
                                        {renderInItData(reportDetails?.vitals_summary?.spo2?.initial_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.vitals_summary?.spo2?.final_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.vitals_summary?.spo2?.final_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.vitals_summary?.spo2?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.vitals_summary?.spo2?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.vitals_summary?.spo2?.percentage_changed}% {reportDetails?.vitals_summary?.spo2?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.vitals_summary?.spo2?.percentage_changed}% {reportDetails?.vitals_summary?.spo2?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>

                                {
                                    reportDetails?.vitals_summary?.other_vitals.map((vital: any) => {
                                        return (
                                            <div className="table-data">
                                                <div style={{ display: 'flex', width: '30%', alignItems: 'center', gap: '5px' }}>
                                                    <Checkbox onChange={() => onChangeForArrayVitals(vital)} />
                                                    {(vital?.keys == 'lung' || vital?.keys == 'pef' || vital?.keys == 'fvc' || vital?.keys == 'aqi' || vital?.keys == 'humidity' || vital?.keys == 'fev1_fvc_ratio') &&
                                                        <img
                                                            src={LungIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'six_min_walk') &&
                                                        <img
                                                            src={WalkIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'fibro_scan' || vital?.keys == 'fatty_liver_ugs_grade' || vital?.keys == 'fib4') &&
                                                        <img
                                                            src={LiverIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }

                                                    {(vital?.keys == 'egfr' || vital?.keys == 'acr' || vital?.keys == 'serum_creatinine') &&
                                                        <img
                                                            src={KidneyIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }

                                                    {(vital?.keys == 'triglycerides' || vital?.keys == 'blood_glucose' || vital?.keys == 'basel_metabolic_rate' || vital?.keys == 'random_blood_glucose' || vital?.keys == 'platelet') &&
                                                        <img
                                                            src={CardioIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'calories_burned') &&
                                                        <img
                                                            src={CaloriesBurnIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    {(vital?.keys == 'sedentary_time' || vital?.keys == 'hydration' || vital?.keys == 'metabolic_age' || vital?.keys == 'muscle_mass' || vital?.keys == 'subcutaneous_fat' || vital?.keys == 'protein' || vital?.keys == 'visceral_fat' || vital?.keys == 'skeletal_muscle' || vital?.keys == 'bone_mass' || vital?.keys == 'body_fat') &&
                                                        <img
                                                            src={AccessbilityIcon}
                                                            alt='vital_icon'
                                                            style={{
                                                                height: '13px',
                                                                width: '13px',
                                                                //filter: 'saturate(100%) hue-rotate(180deg)', // Apply CSS filters for desired color effects
                                                            }}
                                                        />
                                                    }
                                                    <span style={{ fontSize: '9px', fontWeight: '500' }}>{vital?.reading_name}</span>
                                                </div>
                                                {vital?.keys == 'blood_glucose' ?
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {/* {vital?.initial_reading_value_data?.fast || '-'} / {vital?.initial_reading_value_data?.pp || '-'} */}
                                                        {(!vital?.initial_reading_value_data?.fast && !vital?.initial_reading_value_data?.pp)
                                                            ? <span style={{ fontSize: '8px' }}>Data Not Provided</span>
                                                            : <>{vital?.initial_reading_value_data?.fast || '-'} / {vital?.initial_reading_value_data?.pp || '-'}</>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {/* {Number(vital?.initial_value)?.toFixed(2) || '-'} */}
                                                        {renderInItData(vital?.initial_value)}
                                                    </div>
                                                }
                                                {vital?.keys == 'blood_glucose' ?
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {vital?.final_reading_value_data?.fast || '-'} / {vital?.final_reading_value_data?.pp || '-'}
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', width: '20%', fontSize: '9px', fontWeight: '500' }}>
                                                        {/* {Number(vital?.final_value)?.toFixed(2) || '-'} */}
                                                        {renderAchievedData(vital?.final_value)}
                                                    </div>
                                                }
                                                <div style={{ display: 'flex', width: '30%', fontSize: '9px', fontWeight: '500' }}>
                                                    {
                                                        ((vital?.change_type === 'unchanged')) ?
                                                            <div>-</div>
                                                            :
                                                            (
                                                                <>
                                                                    {
                                                                        vital?.change_type === 'Increased' ?
                                                                            <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {vital?.percentage_changed}% {vital?.change_type}</div>
                                                                            :
                                                                            <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {vital?.percentage_changed}% {vital?.change_type}</div>
                                                                    }
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            {/* SGPT Table */}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <SGPTIcons />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>SGPT</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.sgpt?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('sgpt')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgpt?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.sgpt?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgpt?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.sgpt?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.sgpt?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.sgpt?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.sgpt?.percentage_changed}% {reportDetails?.sgpt?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.sgpt?.percentage_changed}% {reportDetails?.sgpt?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* SGOT Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <SGPTIcons />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>SGOT</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.sgot?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('sgot')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgot?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.sgot?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.sgot?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.sgot?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.sgot?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.sgot?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.sgot?.percentage_changed}% {reportDetails?.sgot?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.sgot?.percentage_changed}% {reportDetails?.sgot?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <span style={{ fontWeight: '600' }}>Note :</span><span>SGPT and SGOT are liver enzymes that indicate liver health; elevated levels may suggest liver damage or disease.</span>
                            </div> */}
                            <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <textarea
                                    onChange={(e) => {
                                        let temp = selectedOptions;
                                        temp.sgpt_notes = e.target.value;
                                        setSelectedOptions(temp)
                                    }}
                                    style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    placeholder="notes..."
                                />
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                            {/* LDL Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>LDL</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.ldl?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('ldl')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.ldl?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.ldl?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.ldl?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.ldl?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.ldl?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.ldl?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.ldl?.percentage_changed}% {reportDetails?.ldl?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.ldl?.percentage_changed}% {reportDetails?.ldl?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* HDL Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>HDL</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.hdl?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('hdl')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hdl?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.hdl?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hdl?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.hdl?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.hdl?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.hdl?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.hdl?.percentage_changed}% {reportDetails?.hdl?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.hdl?.percentage_changed}% {reportDetails?.hdl?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*Total Cholesterol*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>Total Cholesterol</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>{reportDetails?.total_cholesterol?.default_reading}</span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('total_cholesterol')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.total_cholesterol?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.total_cholesterol?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.total_cholesterol?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.total_cholesterol?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.total_cholesterol?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.total_cholesterol?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.total_cholesterol?.percentage_changed}% {reportDetails?.total_cholesterol?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.total_cholesterol?.percentage_changed}% {reportDetails?.total_cholesterol?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <span style={{ fontWeight: '600' }}>Note:</span><span> HDL (high-density lipoprotein) is 'good' cholesterol, with normal levels ideally above 40 mg/dL for men and 50 mg/dL for women; LDL (low-density lipoprotein) is 'bad' cholesterol, with optimal levels below 100 mg/dL; and total cholesterol levels should ideally be below 200 mg/dL.</span>
                            </div> */}
                            <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '20px', gap: '8px' }}>
                                <textarea
                                    onChange={(e) => {
                                        let temp = selectedOptions;
                                        temp.ldl_notes = e.target.value;
                                        setSelectedOptions(temp)
                                    }}
                                    style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    placeholder="notes..."
                                />
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '20px' }}></div>

                            {/*HbA1c Table*/}
                            <div className="second-table-container">
                                <div className="second-table-header">
                                    <div className="second-table-header-left">
                                        <LDLIcon />
                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>HbA1c</span>
                                    </div>
                                    <div className="second-table-header-right">
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>Normal <b>below 5.7 %</b></span>
                                    </div>
                                </div>

                                <div className="second-table-data">
                                    <Checkbox onChange={() => onChangeFirstObj('hba1c')} />
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Initial {`(${moment(reportPeiodDates?.start_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hba1c?.initial_value).toFixed(2) || '-'} */}
                                            {renderInItData(reportDetails?.hba1c?.initial_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '8px', fontWeight: '500' }}>Final {`(${moment(reportPeiodDates?.end_date).format('ll')})`}</span>
                                        <span style={{ fontSize: '9px', fontWeight: '600' }}>
                                            {/* {Number(reportDetails?.hba1c?.final_value).toFixed(2) || 0} */}
                                            {renderAchievedData(reportDetails?.hba1c?.final_value)}
                                        </span>
                                    </div>
                                    <div style={{ width: '30%', display: 'flex', flexDirection: 'column', fontSize: '9px', fontWeight: '500' }}>
                                        {
                                            ((reportDetails?.hba1c?.change_type === 'unchanged')) ?
                                                <div>-</div>
                                                :
                                                (
                                                    <>
                                                        {
                                                            reportDetails?.hba1c?.change_type === 'Increased' ?
                                                                <div style={{ display: 'flex', gap: '5px' }}><IncreasedIcon /> {reportDetails?.hba1c?.percentage_changed}% {reportDetails?.hba1c?.change_type}</div>
                                                                :
                                                                <div style={{ display: 'flex', gap: '5px' }}><DecreasedIcon /> {reportDetails?.hba1c?.percentage_changed}% {reportDetails?.hba1c?.change_type}</div>
                                                        }
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/*Food Diary Summary*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <Checkbox onChange={() => onChangeFirstObj('diet_details')} />
                                    <FoodDiaryIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Food Diary</span>
                                </div>

                                <div className="table-header">
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        Parameters
                                    </div>
                                    <div style={{ width: '33%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Goal</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.start_date).format('ll')}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '33%', fontSize: '9px', fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ fontSize: '9px', fontWeight: '600' }}>Achieved</span>
                                            <span style={{ fontSize: '8px', fontWeight: '500' }}>{moment(reportPeiodDates?.end_date).format('ll')}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <ProteinIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Protein(%)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.diet_details?.protein_goal_value).toFixed(2) || 0} */}
                                        {renderInItData(reportDetails?.diet_details?.protein_goal_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        <Progress type="circle" percent={!reportDetails?.diet_details?.protein_goal_value ? 0 : ((reportDetails?.diet_details?.protein_achieved_value / reportDetails?.diet_details?.protein_goal_value) * 100)} size={20} />
                                        {/* {Number(reportDetails?.diet_details?.protein_achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.diet_details?.protein_achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <FatIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Fat(%)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.diet_details?.fat_goal_value).toFixed(2) || 0} */}
                                        {renderInItData(reportDetails?.diet_details?.fat_goal_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        <Progress type="circle" percent={!reportDetails?.diet_details?.fat_goal_value ? 0 : ((reportDetails?.diet_details?.fat_achieved_value / reportDetails?.diet_details?.fat_goal_value) * 100)} size={20} />
                                        {/* {Number(reportDetails?.diet_details?.fat_achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.diet_details?.fat_achieved_value)}
                                    </div>
                                </div>

                                <div className="table-data">
                                    <div style={{ display: 'flex', width: '33%', alignItems: 'center', gap: '5px' }}>
                                        <CarbIcon />
                                        <span style={{ fontSize: '9px', fontWeight: '500' }}>Carb(%)</span>
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500' }}>
                                        {/* {Number(reportDetails?.diet_details?.carb_goal_value).toFixed(2) || 0} */}
                                        {renderInItData(reportDetails?.diet_details?.carb_goal_value)}
                                    </div>
                                    <div style={{ display: 'flex', width: '33%', fontSize: '9px', fontWeight: '500', alignItems: 'center', gap: '8px' }}>
                                        <Progress type="circle" percent={!reportDetails?.diet_details?.carb_goal_value ? 0 : ((reportDetails?.diet_details?.carb_achieved_value / reportDetails?.diet_details?.carb_goal_value) * 100)} size={20} />
                                        {/* {Number(reportDetails?.diet_details?.carb_achieved_value).toFixed(2) || 0} */}
                                        {renderAchievedData(reportDetails?.diet_details?.carb_achieved_value)}
                                    </div>
                                </div>
                            </div>

                            {/*Diet Parameters Summary*/}
                            {!!reportDetails?.food_frequency?.length &&
                                <div className="table-container" style={{ marginTop: '30px' }}>
                                    <div className="table-heading">
                                        <Checkbox onChange={() => onChangeFirstObj('food_frequency')} />
                                        <FoodDiaryIcon />
                                        <div className="heading-content">
                                            <span className="title">Diet Parameters</span>
                                            <div className="adherence-status">
                                                {[{ label: 'Good', color: 'good' }, { label: 'Needs Improvement', color: 'needs-improvement' }].map((item, index) => (
                                                    <div key={index} className="status-item">
                                                        <div className={`status-icon ${item.color}`}></div>
                                                        {item.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-container">
                                        <div className="box-rows">
                                            {reportDetails.food_frequency.slice(0, 3).map((data: any) => (
                                                <div className="box">
                                                    <div className="sub-box-1">{data.key}</div>
                                                    <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                        {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                        {(data.status !== 'Data Not Provided')
                                                            ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                    <div className="sub-box-3">
                                                        {(data.init_value && data.final_value)
                                                            ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="box-rows">
                                            {reportDetails.food_frequency.slice(3, 6).map((data: any) => (
                                                <div className="box">
                                                    <div className="sub-box-1">{data.key}</div>
                                                    <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                        {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                        {(data.status !== 'Data Not Provided')
                                                            ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                    <div className="sub-box-3">
                                                        {(data.init_value && data.final_value)
                                                            ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="box-rows">
                                            {reportDetails.food_frequency.slice(6, 10).map((data: any) => (
                                                <div className="box">
                                                    <div className="sub-box-1">{data.key}</div>
                                                    <div className="sub-box-2" style={{ backgroundColor: (data.color === 'Good') ? '#C6E0B4' : '#F8CBAD' }}>
                                                        {(!!data.percentage && data.percentage !== '-') ? `${data.percentage} %` : data.percentage === 0 ? '' : data.status !== 'Data Not Provided' ? data.percentage : ''}
                                                        {(data.status !== 'Data Not Provided')
                                                            ? ((data.status === 'Same') ? data.status : data.status === 'Increased' ? <ArrowUpOutlined /> : <ArrowDownOutlined />)
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                    <div className="sub-box-3">
                                                        {(data.init_value && data.final_value)
                                                            ? <>{`${data.init_value || '-'} times to ${data.final_value || '-'} Times in a month`}</>
                                                            : <span style={{ fontSize: '10px', fontWeight: '500' }}>{data.status}</span>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ fontWeight: '400', fontSize: '8px', display: 'flex', marginTop: '15px', marginLeft: '20px' }}>
                                            <textarea
                                                onChange={(e) => {
                                                    const newSelectedOptions = { ...selectedOptions };
                                                    newSelectedOptions['food_frequency_notes'] = e.target.value;
                                                    setSelectedOptions(newSelectedOptions);
                                                }}
                                                style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                                placeholder="notes..."
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                            {/*Suggested lifestyle goals input*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <OtherLifeIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Suggested lifestyle goals</span>
                                </div>
                                <div>
                                    <textarea
                                        onChange={(e) => {
                                            let temp = selectedOptions;
                                            temp.life_style_notes = e.target.value;
                                            setSelectedOptions(temp)
                                        }}
                                        style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    />
                                </div>
                            </div>

                            <div style={{ border: '1px solid #A2A2A8', marginTop: '30px' }}></div>

                            {/*Doctor Notes*/}
                            <div className="table-container" style={{ marginTop: '30px' }}>
                                <div className="table-heading">
                                    <DoctorNotesIcon />
                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>Doctor Notes</span>
                                </div>
                                <div>
                                    <textarea
                                        onChange={(e) => {
                                            let temp = selectedOptions;
                                            temp.doctor_notes = e.target.value;
                                            setSelectedOptions(temp)
                                        }}
                                        style={{ resize: 'none', width: '100%', border: '1px solid lightgray', borderRadius: '4px', minHeight: '50px', outline: 'none' }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="btn-wrapper">
                        <Button variant="primary" onClick={() => setReportModal(true)}>Generate Report</Button>
                    </div>

                    <Modal
                        centered
                        open={reportModal}
                        footer={null}
                        onCancel={() => setReportModal(false)}
                        width={'50%'}
                        style={{}}
                        zIndex={10000}
                    >
                        <PatientReportView
                            selectedReportData={selectedOptions}
                            userInfo={userInfo}
                            patientDetails={patientDetails}
                            reportPeiodDates={reportPeiodDates}
                            selectedDietPlan={selectedDietPlan}
                            setReportModal={setReportModal}
                            fetchDietData={fetchDietData}
                            setSelectedDietPlan={setSelectedDietPlan}
                            setReportPeriodDates={setReportPeriodDates}
                        />
                    </Modal>
                </div>
            }
        </>
    )
}

export default PatientReport;