import React, { useRef } from "react";
import { useSelector } from "react-redux";

import "./UserProfileCard.scss";
import { Avatar } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Button from "../../../atoms/Button/Button";
import PatientProfileModal from "../PatientProfileModal/PatientProfileModal";

import { RootState } from "../../../store/store";
import { getAgeByDob } from "../../../utils/date-and-time";

const UserProfileCard: React.FC = () => {
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const patientDetailsRef = useRef<any>(null);

  const patientName = patientDetails?.data?.name;
  const patientGender =
    patientDetails?.data?.gender === "M"
      ? "Male"
      : patientDetails?.data?.gender === "F"
      ? "Female"
      : "Others";
  const patientAge = getAgeByDob(patientDetails?.data?.dob ?? null);
  const patientProfileImage = patientDetails?.data?.profile_pic;

  const patientPlanName = patientDetails?.data?.plan_name || "--";
  // const patientPlanDuration = patientDetails?.data?.plan_duraion || "--"
  const getPlanDuration = (days: number) => {
    if (days >= 30) {
      const months = Math.floor(days / 30);
      return `${months} Month${months > 1 ? "s" : ""}`;
    }
    return `${days} Day${days > 1 ? "s" : ""}`;
  };

  const patientPlanDuration = patientDetails?.data?.plan_duration
    ? getPlanDuration(patientDetails.data.plan_duration)
    : "--";
  const patientPlanStatus =
    patientDetails?.data?.plan_expiry_datetime &&
    new Date(patientDetails.data.plan_expiry_datetime) > new Date()
      ? "ACTIVE"
      : "EXPIRED";

  return (
    <div className="patient-details-card-container">
      <div className="patient-avatar-and-info">
        {patientProfileImage ? (
          <Avatar size={48} src={patientProfileImage} />
        ) : (
          <Avatar size={48} icon={<UserOutlined />} />
        )}
        <div>
          <div className="patient-info">
            <div className="patient-name">{patientName}</div>
            <div className="patient-gender">{patientGender}</div>
            <div className="minus-symbol">-</div>
            <div className="patient-age">{patientAge} Years</div>
          </div>
        </div>
      </div>

      <div className="features-wrapper">
        <div className="feature">
          <div className="feature-label">Assigned Plan</div>
          <div className="feature-value feature-plan-name">
            {patientPlanName}
          </div>
        </div>
        <div className="feature">
          <div className="feature-label">Plan Duration</div>
          <div className="feature-value">{patientPlanDuration}</div>
        </div>
        <div className="feature">
          <div className="feature-label">Plan Status</div>
          <div
            className={`feature-value ${
              patientPlanStatus === "ACTIVE" ? "checked" : "unchecked"
            }`}
          >
            {patientPlanStatus === "ACTIVE" ? (
              <>
                <CheckCircleOutlined className="checked" /> Active
              </>
            ) : (
              <>
                <CloseCircleOutlined className="unchecked" /> Expired
              </>
            )}
          </div>
        </div>

        {/* <div className="feature feature-service">
          <div className="feature-label">Optional Services</div>
          <div className="feature-services-value">
            <div>
              Supplements <CheckCircleOutlined className="checked" />
            </div>
            <div>
              GSM Monitor <CloseCircleOutlined className="unchecked" />
            </div>
          </div>
        </div> */}
      </div>
      <div className="action-button-wrapper">
        <Button
          label={"View User Details"}
          variant="secondary"
          onClick={() => {
            patientDetailsRef?.current?.showPatientDetails();
          }}
        />
        <Button
          label={"Chat with User"}
          variant="primary"
          onClick={() => {
            window.parent.postMessage(
              {
                messageType: "goToChat",
              },
              "*"
            );
          }}
        />
      </div>

      <PatientProfileModal ref={patientDetailsRef} />
    </div>
  );
};

export default UserProfileCard;
