import React from "react";
import { useState, useEffect } from "react";
import { message, Table, Radio, Modal } from "antd";
import type { TablePaginationConfig } from "antd";

import AddDietContainer from "../../AddDietPlan/AddDietPlan";
import ViewDietPlanTemplate from "../../../molecules/DiatPlanTemplate/ViewDietPlanTemplate";
import Button from "../../../atoms/Button/Button";
import Spinner from "../../../atoms/Spinner/Spinner";

import API from "../../../services/rest/api";

import "./DietTemplatesList.scss";

const DEFAULT_PAGE_SIZE = 10;

type DietTemplatesListProps = {
  setIsButtonVisible?: any;
  setScreen?: any;
  selectedAssessment?: any;
  userDetails?: any;
  setEnableSwitch?: any;
  setToggle?: any;
  onSelectTemplateBtnClick?: any;
  screenType?: any;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const DietTemplatesList: React.FC<DietTemplatesListProps> = ({
  setIsButtonVisible,
  setScreen,
  selectedAssessment,
  userDetails,
  setEnableSwitch,
  setToggle,
  onSelectTemplateBtnClick,
  screenType,
  onCancel,
  onSuccess,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<Array<any>>([]);
  const [addDietShowTemplate, setAddDietShowTemplate] = useState<any>(false);
  const [openDietModal, setOpenDietModal] = useState<boolean>(false);

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );

  const [selectedRadioIndex, setSelectedRadioIndex] = useState<number | null>(
    null
  );
  const [selectedTemplateData, setSelectedTemplateData] = useState<any | null>(
    null
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  useEffect(() => {
    console.log(screenType);
    if (screenType === "CHOOSE_TEMPLATE") {
      setAddDietShowTemplate(false);
    }
  }, [screenType]);

  const columns = [
    {
      title: "Select",
      width: 100,
      render: (text: any, record: any, index: number) => (
        <Radio.Group
          onChange={(e) => handleRadioChange(e, index)}
          value={selectedRadioIndex}
          className="custom-radio"
        >
          <Radio value={index}></Radio>
        </Radio.Group>
      ),
    },
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
      render: (name: string, record: any) => (
        <a
          style={{ color: "#760fb2", cursor: "pointer" }}
          onClick={() => handleViewTemplate(record.id, "view")}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Total Calories Goal",
      dataIndex: "total_calories",
      key: "total_calories",
    },
    {
      title: "Actual Calories",
      dataIndex: "actual_calories",
      key: "actual_calories",
    },
    {
      title: "Created By",
      dataIndex: "coach_name",
      key: "coach_name",
    },
    {
      title: "Actions",
      dataIndex: "template_name",
      render: (name: string, record: any) => (
        <Button
          onClick={async () => {
            if (selectedRadioIndex !== null && selectedTemplateData) {
              setAddDietShowTemplate(true);
              setIsButtonVisible && setIsButtonVisible(false);
              onSelectTemplateBtnClick && onSelectTemplateBtnClick();
            }
          }}
          // className={`buttons select-button ${
          //   selectedRadioIndex === null ? "disabled" : ""
          // }`}
          disabled={selectedTemplateData?._id !== record?._id}
          // onClick={() => console.log("select template", record)}
        >
          Select Template
        </Button>
      ),
    },
  ];

  console.log("selectedTemplateData", selectedRadioIndex, selectedTemplateData);

  const modifyPlanResponseData = (response: any[]) => {
    return response.map((resp: any, index: number) => ({
      ...resp,
      key: resp._id || index,
      id: resp._id || "",
    }));
  };

  const fetchTemplateData = async (page = 1, pageSize = DEFAULT_PAGE_SIZE) => {
    try {
      setLoading(true);
      const response = await API.GetDietTemplateList({ page, limit: pageSize });
      if (response?.code === 1) {
        const modifiedPlanList = modifyPlanResponseData(response?.data || []);
        setTemplateList(modifiedPlanList);
        setPagination({
          current: response?.current_page || 1,
          pageSize: response?.limit || DEFAULT_PAGE_SIZE,
          total: response?.total_records || 0,
        });
      }
    } catch (error) {
      console.log("Error fetching template list:", error);
      message.error({ content: "Failed to fetch template list!" });
    } finally {
      setLoading(false);
    }
  };

  const handleViewTemplate = async (templateId: any, type = "view") => {
    setSelectedTemplateId(templateId);
    setOpenDietModal(true);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchTemplateData(pagination.current, pagination.pageSize);
  };
  useEffect(() => {
    fetchTemplateData();
  }, []);

  const handleRadioChange = (e: any, index: number) => {
    setSelectedRadioIndex(index);
    setSelectedTemplateData(templateList[index]);
  };

  const onCloseViewModal = () => {
    setSelectedTemplateId(null);
    setOpenDietModal(false);
  };

  return (
    <div className="container-wrapper-coach">
      {!addDietShowTemplate && (
        <div>
          {loading ? (
            <div className="diet-template-container-loader">
              <Spinner />
          </div>
          ) : (
            <>
              <div className="table-wrapper coach-table">
                <Table
                  className="custom-table-diet"
                  dataSource={templateList}
                  columns={columns}
                  loading={loading}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </>
          )}
        </div>
      )}
      {openDietModal && (
        <Modal
          className="view-assessment-score-modal"
          title="Diet Template"
          centered
          open={openDietModal}
          onOk={onCloseViewModal}
          onCancel={onCloseViewModal}
          width={"85vw"}
          footer={false}
        >
          <ViewDietPlanTemplate templateId={selectedTemplateId ?? null} />
        </Modal>
      )}

      {addDietShowTemplate && (
        <AddDietContainer
          showTitle={false}
          templateId={selectedTemplateData?.id}
          editModal={true}
          setAddDietShow={setAddDietShowTemplate}
          setScreen={setScreen}
          selectedAssessment={selectedAssessment}
          userDetails={userDetails}
          setEnableSwitch={setEnableSwitch}
          setToggle={setToggle}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default DietTemplatesList;
