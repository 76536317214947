import { all, call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../slices/HealthCoachSlice";

import { constants } from "../../constants/common";
import { common } from "../../types";

import API from "../../services/rest/api";

const GetHealthCoachDetailsRequest = function* GetHealthCoachDetailsRequest({
  payload: { resolve, reject, payload },
}: {
  payload: any;
}) {
  try {
    const response: common.ResponseGenerator = yield call(
      API.GetProfileDetails
    );
    if (Number(response.code) === 1) {
      yield put(actions.getHealthCoachDetailsSuccess(response));
      resolve(response);
    } else if (Number(response.code) === -1) {
      throw new Error("Session Expire");
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(
      actions.getHealthCoachDetailsError(
        error instanceof Error
          ? error?.message
          : constants.ERROR_MESSAGES.SOMETHING_WENT_WRONG
      )
    );
    // reject(error);
  }
};

export default function* dietSaga() {
  yield all([
    takeEvery(
      actions.getHealthCoachDetailsRequest,
      GetHealthCoachDetailsRequest
    ),
  ]);
}
