import React, { useEffect, useState } from "react";
import { Button, Card, Row, Col, Modal, Spin, Pagination } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./ManagePatientList.scss";
import CreateNewList from "./CreateNewList/CreateNewList";
import useApi from "../../hooks/useApi";
import API from "../../services/rest/api";
import { toast } from "react-toastify";
import Loader from "../../atoms/Loader";

const ManagePatientList = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [mpatientList, setMpatientList] = useState([]); // You'll need to populate this with your data
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [pendingListData, setPendingListData] = useState<any>(null);

  const {
    mutate: fetchManagePatientList,
    response: fetchManagePatientListResponse,
    error: fetchManagePatientListError,
    isLoading: isFetchManagePatientListLoading,
  } = useApi(API.GetManagePatientList);

  const {
    mutate: addPatientList,
    response: addPatientListResponse,
    error: addPatientListError,
    isLoading: isAddPatientListLoading,
  } = useApi(API.AddPatientList);

  const {
    mutate: deletePatientList,
    response: deletePatientListResponse,
    error: deletePatientListError,
    isLoading: isDeletePatientListLoading,
  } = useApi(API.DeletePatientList);

  const showCriteria = (item: any) => {
    setSelectedId(item.healthcoach_patient_list_id);
  };

  useEffect(() => {
    if (addPatientListResponse?.code?.toString() === "1") {
      setIsCreateModalVisible(false);
      fetchManagePatientListHandler();
      toast.success(
        addPatientListResponse?.message || "List created successfully"
      );
    } else if (addPatientListResponse?.code?.toString() === "0") {
      toast.error(addPatientListResponse?.message || "Failed to create list");
    }
  }, [addPatientListResponse]);

  useEffect(() => {
    if (deletePatientListResponse?.code?.toString() === "1") {
      fetchManagePatientListHandler();
      toast.success(
        deletePatientListResponse?.message || "List deleted successfully"
      );
    } else if (deletePatientListResponse?.code?.toString() === "0") {
      toast.error(
        deletePatientListResponse?.message || "Failed to delete list"
      );
    }
  }, [deletePatientListResponse]);

  useEffect(() => {
    console.log(
      "fetchManagePatientListResponse--111",
      fetchManagePatientListResponse
    );
    if (fetchManagePatientListResponse?.code?.toString() === "1") {
      setMpatientList(fetchManagePatientListResponse.data);
    } else if (fetchManagePatientListResponse?.code?.toString() === "0") {
      toast.error(
        fetchManagePatientListResponse?.message || "Failed to fetch list"
      );
    } else if (!fetchManagePatientListResponse) {
      console.log(
        "fetchManagePatientListResponse",
        fetchManagePatientListResponse
      );
      setMpatientList([]);
    }
  }, [fetchManagePatientListResponse]);

  useEffect(() => {
    fetchManagePatientListHandler();
  }, [currentPage]);

  const fetchManagePatientListHandler = async () => {
    fetchManagePatientList({
      page: "1",
      per_page: "5000",
    });
  };

  const hideCriteria = () => {
    setSelectedId(null);
  };

  const handleCreateList = async (data: any) => {
    setPendingListData(data);
    setConfirmModalVisible(true);
  };

  const handleConfirmCreate = async () => {
    console.log("confirmationText", confirmationText);
    if (pendingListData) {
      await addPatientList({ ...pendingListData, list_name: confirmationText });
      setConfirmModalVisible(false);
      setConfirmationText("");
      setPendingListData(null);
    } else {
      toast.error('Please type "confirm" to proceed');
    }
  };

  const handleDeleteList = async (item: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this list?",
      centered: true,
      onOk: () => {
        deletePatientList({
          healthcoach_patient_list_id: item.healthcoach_patient_list_id,
        });
        Modal.destroyAll();
      },
    });
  };

  const sendMessageToParent = (type: string, item?: any) => {
    switch (type) {
      case "MANAGE_PATIENT_LIST_CLICKED":
        window.parent.postMessage({ messageType: type, item: item }, "*");
        break;
    }
  };

  const goDetailList = (item: any) => {
    sendMessageToParent("MANAGE_PATIENT_LIST_CLICKED", item);
    // Implement navigation to detail list
  };

  return (
    <div className="manage-patient-list">
      <Row className="header-row">
        <Col span={16}>
          <h2 className="text-black">
            <b>Manage Patient List</b>
          </h2>
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            onClick={() => setIsCreateModalVisible(true)}
            className="create-button"
          >
            Create New List
          </Button>
        </Col>
      </Row>

      {isFetchManagePatientListLoading ? (
        <div
          className="empty-state"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          <Loader noBackground={true} />
        </div>
      ) : !mpatientList || mpatientList.length === 0 ? (
        <div
          className="empty-state"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
          }}
        >
          No records found
        </div>
      ) : (
        <>
          {mpatientList.map((item: any, index: any) => (
            <Card key={index} className="patient-list-card">
              <Row>
                <Col span={5}>
                  <div className="text-label">Patient List:</div>
                  <div className="list-name" onClick={() => goDetailList(item)}>
                    {item.list_name}
                  </div>
                </Col>
                <Col span={5}>
                  <div className="text-label">Number of Patient:</div>
                  <div className="bold-text">{item.no_of_patient}</div>
                </Col>
                <Col span={10}>
                  <div className="text-label">Criteria:</div>
                  <div className="criteria-content">
                    <div
                      className={`criteria-text ${
                        item.healthcoach_patient_list_id === selectedId
                          ? "expanded"
                          : "collapsed"
                      }`}
                    >
                      {item.age_to && (
                        <span>
                          Age:{" "}
                          <b>
                            {item.age_from}-{item.age_to}
                          </b>
                          ,{" "}
                        </span>
                      )}
                      {item.activity_to && (
                        <span>
                          Last Active:{" "}
                          <b>
                            {item.activity_from}-{item.activity_to} Days
                          </b>
                          ,{" "}
                        </span>
                      )}
                      {item.joined_to && (
                        <span>
                          Last Joined:{" "}
                          <b>
                            {item.joined_from}-{item.joined_to} Days
                          </b>
                          ,{" "}
                        </span>
                      )}
                      {item.city_name && (
                        <span>
                          Location: <b>{item.city_name}</b>,{" "}
                        </span>
                      )}
                      {item.indication && (
                        <span>
                          Indication: <b>{item.indication}</b>,{" "}
                        </span>
                      )}
                      {item.medication_adherence && (
                        <span>
                          Medication Adherence:{" "}
                          <b>{item.medication_adherence}</b>,{" "}
                        </span>
                      )}
                      {item.diet_adherence && (
                        <span>
                          Diet Adherence: <b>{item.diet_adherence}</b>,{" "}
                        </span>
                      )}
                      {item.pranayam_adherence && (
                        <span>
                          Breathing Adherence: <b>{item.pranayam_adherence}</b>,{" "}
                        </span>
                      )}
                      {item.exercise_adherence && (
                        <span>
                          Exercise Adherence: <b>{item.exercise_adherence}</b>,{" "}
                        </span>
                      )}
                      {item.gender && (
                        <span>
                          Gender:{" "}
                          {item.gender.map((gender: string) => (
                            <b key={gender}>
                              {gender === "M"
                                ? "Male"
                                : gender === "F"
                                ? "Female"
                                : gender === "P"
                                ? "Preferred not to say"
                                : gender}
                              ,
                            </b>
                          ))}
                        </span>
                      )}
                    </div>
                    <Button
                      type="text"
                      className="toggle-button"
                      onClick={() =>
                        item.healthcoach_patient_list_id === selectedId
                          ? hideCriteria()
                          : showCriteria(item)
                      }
                    >
                      {item.healthcoach_patient_list_id === selectedId
                        ? "▼"
                        : "▶"}
                    </Button>
                  </div>
                </Col>
                <Col span={4}>
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteList(item)}
                  >
                    Delete List
                  </Button>
                </Col>
              </Row>
            </Card>
          ))}
        </>
      )}
      {isCreateModalVisible && (
        <CreateNewList
          isVisible={isCreateModalVisible}
          onClose={() => setIsCreateModalVisible(false)}
          onCreateList={handleCreateList}
        />
      )}

      <Modal
        open={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleConfirmCreate}>
            Create List
          </Button>,
        ]}
        centered
        style={{ textAlign: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>Enter List Name:</p>
          <input
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            style={{
              width: "60%",
              padding: "8px 12px",
              marginTop: "8px",
              textAlign: "left",
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              fontSize: "14px",
              outline: "none",
              transition: "border-color 0.3s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#1890ff")}
            onBlur={(e) => (e.target.style.borderColor = "#d9d9d9")}
          />
        </div>
      </Modal>
      <Pagination
        current={currentPage}
        total={mpatientList.length}
        pageSize={10}
        onChange={(page) => setCurrentPage(page)}
        className="pagination-container"
        showSizeChanger={false}
        style={{ marginTop: "20px", textAlign: "right" }}
      />
    </div>
  );
};

export default ManagePatientList;
