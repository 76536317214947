import React from "react";
import ReactSelect from "react-select";

import "./ReactSelectWrapper.scss";

interface Props {
  label?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  onChange?: any;
  value?: any;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}

const ReactSelectWrapper: React.FC<Props> = ({
  label,
  options,
  onChange,
  value,
  placeholder,
  error,
  helperText,
}) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "100%",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  return (
    <div className="react-select-wrapper">
      {label && <label className="react-select-label">{label}</label>}
      <ReactSelect
        classNamePrefix="react-select"
        name="color"
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary25: "#F0E7F7",
            primary: "#760fb2",
          },
        })}
        options={options}
        onChange={onChange}
        value={value}
        styles={customStyles}
        placeholder={placeholder}
      />
      {helperText && (
        <div className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default ReactSelectWrapper;
