import React from "react";

import RedirectComponent from "../../../../atoms/RedirectComponent/RedirectComponent";

const StepsTaskPage: React.FC<any> = () => {
  return (
    <div>
      <RedirectComponent moduleName="steps" />
    </div>
  );
};

export default StepsTaskPage;
