import React from "react";

import "./UserView.scss";
import environment from "../../../constants/environments";

// import IphoneImg from "../../../assets/images/phone/iphone-15-pro-max.jpg"

interface Props {
  mobileNumber: string | null | undefined;
  name: string | null | undefined;
  moduleName: "diet" | "exercise" | "progress";
}

const UserView: React.FC<Props> = ({ mobileNumber, name, moduleName }) => {
  // TODO: add a flag, to disable to the add, mark food as consumed and add goal.
  const iframeData = {
    uuid: "",
    mobileNumber: mobileNumber,
    name: name,
    clientSource: "MyT",
    moduleName: moduleName,
    environment: "uat",
    mode: "view_only",
  };

  const iframeSrc = `${environment.REACT_APP_GOODFLIP_URL}/?data=${encodeURIComponent(
    JSON.stringify(iframeData)
  )}`;

  return (
    <div className="user-view-container">
      {/* <img src={IphoneImg} alt="i-phone-15" className="i-phone-img"/> */}
      <iframe className="user-view-iframe" src={iframeSrc}></iframe>
    </div>
  );
};

export default UserView;
