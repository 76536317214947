import React, { useEffect } from "react";
import "./SideDrawer.scss";

interface SideDrawerProps {
  isOpen: boolean;
  showHeader?: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
  position?: "left" | "right"; // Determines the side of the drawer
}

const SideDrawer: React.FC<SideDrawerProps> = ({
  isOpen,
  showHeader = true,
  title,
  onClose,
  children,
  position = "right",
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = ""; // Restore background scroll
    }
    return () => {
      document.body.style.overflow = ""; // Cleanup
    };
  }, [isOpen]);

  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`} onClick={onClose}>
      <div
        className={`side-drawer-content ${position}`}
        onClick={(e) => e.stopPropagation()} // Prevent click propagation
      >
        {showHeader && (
          <div className="header-container">
            <div className="title">{title}</div>
            <button className="close-btn" onClick={onClose}>
              &times;
            </button>
          </div>
        )}
        <div className="side-drawer-main-content">{children}</div>
      </div>
    </div>
  );
};

export default SideDrawer;
