import React, { useEffect, useState } from "react";
import ChangeDietBtn from "../../atoms/ChangeDiet-btn/ChangeDiet-btn";
import DietAndAdherence from "../../molecules/Diet&Adherence/Diet&Adherence";
import DietPreference from "../../molecules/DietPreference/DietPreference";
import AdherenceGraph from "../../atoms/Adherence-Graph/Adherence-Graph";
import "./HomeScreen.scss";
import BarChart from "../../atoms/Adherence-Graph/Adherence-Graph";
import { useParams } from "react-router-dom";
import { DatePicker, Switch } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import DietAssessmentView from "../../molecules/DietAssessmentView/DietAssessmentView";
import AddDietContainer from "../AddDietPlan/AddDietPlan";
import API from "../../services/rest/api";
import PatientReport from "../PatientReport/PatientReport";
import DietPlanList from "../../molecules/DietPlanList/DietPlanList";
import DietTemplateCoach from "./DietTemplatesList/DietTemplatesList";
const { RangePicker } = DatePicker;

const HomeScreen = () => {
  const params = useParams();
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [selectedFilter, setSelectedFilter] = useState<any>("all");

  const [screen, setScreen] = useState(1);
  const [dietPlanListOptions, setDietPlanListOptions] = useState<any>([]);
  const [addDietShow, setAddDietShow] = useState<any>(false);
  const [enableSwitch, setEnableSwitch] = useState<any>(true);
  const [latestDietPlan, setLatestDietPlan] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [toggle, setToggle] = useState(true);
  const [reportPeiodDates, setReportPeriodDates] = useState<any>({
    start_date: null,
    end_date: null,
  });
  const [selectedDietPlan, setSelectedDietPlan] = useState<any>(null);

  //assessment states
  const [patientAssessments, setPatientAssessments] = useState<any>([]);
  const [assessmentOptions, setAssessmentOptions] = useState<any>([]);
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);
  const [diseaseOptions, setDiseaseOptions] = useState<any>([]);
  const [selectedDisease, setSelectedDisease] = useState<any>(null);
  const [patientData, setPatientData] = useState<any>(null);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);

  console.log(selectedDietPlan);

  useEffect(() => {
    // let localtoken = localStorage.getItem('token')
    // if (localtoken) {
    //     const patient_id: any = params.id || params?.patientId
    //     localStorage.setItem('patient_id', patient_id)
    // }
    // else {
    const token: any = params.token;
    const patient_id: any = params.id || params?.patientId;
    localStorage.setItem("patient_id", patient_id);
    localStorage.setItem("token", token);
    getProfileDetails();
    fetchDietData();
    // }
  }, [params]);

  useEffect(() => {
    getPatientData();
  }, []);

  useEffect(() => {
    getAllDiseases();
  }, [patientData]);

  useEffect(() => {
    getPatientAssessments();
  }, [selectedDisease]);

  // useEffect(() => {
  //     if (latestDietPlan?.diet_plan_id) {
  //         if (latestDietPlan?.is_report_generated === 1) {
  //             setScreen(1)
  //             setAddDietShow(false)
  //             setEnableSwitch(false)
  //             setLatestDietPlan(null)
  //             setToggle(false)
  //         }
  //         else {
  //             const approvedOn = latestDietPlan?.approved_on;
  //             const ApprovedOnDate: any = new Date(approvedOn);
  //             const currentDate: any = new Date();
  //             const differenceInMilliseconds = currentDate - ApprovedOnDate;
  //             const hoursDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  //             if (hoursDifference >= 24 && latestDietPlan?.status === 'Published') {
  //                 setEnableSwitch(true)
  //                 setScreen(1)
  //                 setToggle(true)
  //             }
  //             else {
  //                 setEnableSwitch(false)
  //                 setScreen(2)
  //             }
  //         }
  //     }
  //     else {
  //         setScreen(1)
  //     }
  // }, [latestDietPlan])

  const fetchDietData = async () => {
    let payload = {
      patient_id: params.id || params?.patientId,
      diet_type: "all",
    };
    try {
      const data = await API.GetDietPlan(payload);
      if (data.code == 1) {
        let updatedData = data?.data
          .filter((d: any) => d.status === "approved")
          .map((d: any) => ({
            ...d,
            label: d?.plan_name,
            value: d?.diet_plans_id,
          }));
        setDietPlanListOptions(updatedData);
        setLatestDietPlan(data?.data[0]);
      }
      if (data.code == 2) {
        console.log(data?.message);
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails();
      if (userInfo?.code === "1") {
        localStorage.setItem("userInfo", JSON.stringify(userInfo?.data));
        localStorage.setItem(
          "health_coach_id",
          userInfo?.data?.health_coach_id
        );
        setUserDetails(userInfo?.data);
      } else {
        setUserDetails(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPatientAssessments = async () => {
    try {
      let assessmentData = await API.GetPatientAssessments({
        patient_id: params.id || params?.patientId,
        read_only: true,
        disease_type: selectedDisease,
        assessment_type: "diet",
      });
      if (assessmentData?.code === "1") {
        setPatientAssessments(assessmentData?.data || []);
        // if (assessmentData?.data.length > 1) {
        let tempAssessmentData = assessmentData?.data?.map((data: any) => {
          return {
            ...data,
            label: data?.assessment_name,
            value: data?.patient_assessment_id,
          };
        });
        setAssessmentOptions(tempAssessmentData);
        setSelectedAssessment(
          assessmentData?.data[assessmentData?.data.length - 1]
        );
        // }
        // else {
        //     setSelectedAssessment((assessmentData?.data[assessmentData?.data.length - 1]))
        // }
      } else {
        setAssessmentOptions([]);
        setPatientAssessments([]);
        setSelectedAssessment(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //funtion to get patient data
  const getPatientData = async () => {
    try {
      let payload = {
        patient_id: params.id || params?.patientId,
      };
      const data = await API.GetPatientData(payload);
      if (data?.code === "1") {
        setPatientData(data?.data);
        setSelectedDisease(data?.data?.disease);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to get all diseases
  const getAllDiseases = async () => {
    try {
      const data = await API.GetDiseasesData();
      if (data?.code === "1") {
        setDiseaseOptions(data?.data);
      } else {
        setDiseaseOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "170px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  const customStyles2 = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "230px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  const onChange = (checked: any) => {
    if (checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
          {enableSwitch && (
            <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
              <Switch onChange={onChange} checked={toggle} />
              <button
                className="generate-report-btn"
                onClick={() => {
                  setAddDietShow(false);
                  setEnableSwitch(false);
                  setLatestDietPlan(null);
                  setToggle(false);
                  setIsButtonVisible(true);
                }}
              >
                Add New Diet
              </button>
            </div>
          )}

          {screen === 1 && !toggle && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "200px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#760fb2",
                  }}
                >
                  Select Disease
                </span>
                <ReactSelect
                  classNamePrefix="select"
                  name="color"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: "#F0E7F7",
                      primary: "#760fb2",
                    },
                  })}
                  options={diseaseOptions}
                  onChange={(selectedOption: any) => {
                    setSelectedDisease(selectedOption?.value);
                  }}
                  value={diseaseOptions.find(
                    (val: any) => val.value == selectedDisease
                  )}
                  styles={customStyles}
                  placeholder="Select Disease"
                />
              </div>
              {
                // patientAssessments.length > 1 &&
                <div style={{ width: "230px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#760fb2",
                    }}
                  >
                    Select Assessment
                  </span>
                  <ReactSelect
                    classNamePrefix="select"
                    name="color"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      colors: {
                        ...theme.colors,
                        primary25: "#F0E7F7",
                        primary: "#760fb2",
                      },
                    })}
                    options={assessmentOptions}
                    onChange={(selectedOption: any) => {
                      setSelectedAssessment(selectedOption);
                    }}
                    value={assessmentOptions.find(
                      (val: any) =>
                        val.value == selectedAssessment?.patient_assessment_id
                    )}
                    styles={customStyles2}
                    placeholder="Select Assessment"
                    isClearable={true}
                  />
                </div>
              }
            </div>
          )}
        </div>

        {!enableSwitch && (
          <button
            className="generate-report-btn"
            style={{ marginLeft: "auto", height: "35px", width: "100px" }}
            onClick={() => {
              setAddDietShow(true);
              setEnableSwitch(true);
              // setLatestDietPlan(null)
              setToggle(true);
            }}
          >
            Back
          </button>
        )}
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          {enableSwitch && screen === 1 && (
            <ReactSelect
              classNamePrefix="select"
              name="color"
              theme={(theme) => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#F0E7F7",
                  primary: "#760fb2",
                },
              })}
              options={dietPlanListOptions}
              onChange={(selectedOption: any) => {
                setSelectedDietPlan(selectedOption);
                setReportPeriodDates({
                  start_date: selectedOption?.start_date,
                  end_date: selectedOption?.end_date,
                });
              }}
              value={
                dietPlanListOptions.find(
                  (val: any) => val.value == selectedDietPlan?.value
                ) ?? null
              }
              styles={customStyles}
              placeholder="Select Diet Plan"
            />
          )}

          {enableSwitch && screen === 1 && (
            <RangePicker
              onChange={(moment: any, dates: any) => {
                setReportPeriodDates({
                  start_date: dates[0],
                  end_date: dates[1],
                });
              }}
              value={[
                reportPeiodDates.start_date
                  ? dayjs(reportPeiodDates.start_date)
                  : null,
                reportPeiodDates.end_date
                  ? dayjs(reportPeiodDates.end_date)
                  : null,
              ]}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                if (selectedDietPlan) {
                  return (
                    (selectedDietPlan.start_date &&
                      current <
                        moment(
                          selectedDietPlan.start_date,
                          "YYYY-MM-DD"
                        ).startOf("day")) ||
                    (selectedDietPlan.end_date &&
                      current >
                        moment(selectedDietPlan.end_date, "YYYY-MM-DD").endOf(
                          "day"
                        ))
                  );
                } else {
                  return (
                    current &&
                    current > moment(customDate, "YYYY-MM-DD").endOf("day")
                  );
                }
              }}
            />
          )}
          {/* {screen === 1 &&
                        <button className="generate-report-btn" onClick={() => setScreen(2)}>
                            View All Plans
                        </button>
                    } */}
        </div>
      </div>

      {screen === 1 && (
        <div className="add-diet-assessment-container">
          {!toggle && (
            <div
              style={{
                width: `${enableSwitch ? "48%" : "39%"}`,
                overflow: "auto",
                boxShadow: "1px 1px 10px 5px rgb(241, 239, 239)",
                height: "1000px",
              }}
            >
              <DietAssessmentView
                patient_id={params.id || params?.patientId}
                selectedAssessment={selectedAssessment}
                // getPatientAssessments={getPatientAssessments}
                ifHomeScreen={"homeScreen"}
              />
            </div>
          )}

          {toggle && (
            <div
              style={{ width: "60%", display: "flex", flexDirection: "column" }}
            >
              <ReactSelect
                classNamePrefix="select"
                name="color"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary25: "#F0E7F7",
                    primary: "#760fb2",
                  },
                })}
                options={[
                  { label: "Pending", value: "pending" },
                  { label: "Rejected", value: "rejected" },
                  { label: "Assigned", value: "assigned" },
                  { label: "Drafts", value: "draft" },
                  { label: "All", value: "all" },
                ]}
                onChange={(selectedOption: any) => {
                  setSelectedFilter(selectedOption?.value);
                }}
                value={[
                  { label: "Pending", value: "pending" },
                  { label: "Rejected", value: "rejected" },
                  { label: "Assigned", value: "assigned" },
                  { label: "Drafts", value: "draft" },
                  { label: "All", value: "all" },
                ].find((val) => val.value == selectedFilter)}
                styles={customStyles}
                placeholder="Select Diet Type"
              />
              <div style={{ width: "90%", marginTop: "20px" }}>
                <DietPlanList
                  patient_id={params.id || params?.patientId}
                  selectedDate={selectedDate}
                  selectedFilter={selectedFilter}
                  downView={true}
                  userDetails={userDetails}
                />
              </div>
            </div>
          )}

          {/* {(enableSwitch ? true : dietListShow) ?
                        <>
                            {(enableSwitch ? toggle : true) &&
                                <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
                                    <ReactSelect
                                        classNamePrefix="select"
                                        name="color"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 6,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#F0E7F7',
                                                primary: '#760fb2',
                                            },
                                        })}
                                        options={[{ label: 'Pending', value: 'pending' }, { label: 'Rejected', value: 'rejected' }, { label: 'Assigned', value: 'assigned' }, { label: 'Drafts', value: 'draft' }, { label: 'All', value: 'all' }]}
                                        onChange={(selectedOption: any) => {
                                            setSelectedFilter(selectedOption?.value)
                                        }}
                                        value={[{ label: 'Pending', value: 'pending' }, { label: 'Rejected', value: 'rejected' }, { label: 'Assigned', value: 'assigned' }, { label: 'Drafts', value: 'draft' }, { label: 'All', value: 'all' }].find((val) => val.value == selectedFilter)}
                                        styles={customStyles}
                                        placeholder='Select Diet Type'
                                    />
                                    <div style={{ width: '90%', marginTop: '20px' }}>
                                        <DietPlanList patient_id={params.id || params?.patientId} selectedDate={selectedDate} selectedFilter={selectedFilter} />
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                            {enableSwitch ? false : true &&
                                <div style={{ width: '60%' }}>
                                    {!addDietShow ?
                                        <ChangeDietBtn setAddDietShow={setAddDietShow} />
                                        :
                                        <AddDietContainer setAddDietShow={setAddDietShow} setScreen={setScreen} setDietListShow={setDietListShow} />
                                    }
                                </div>
                            }
                        </>
                    } */}

          {!enableSwitch && (
            <div style={{ width: "60%" }}>
              {!addDietShow ? (
                <>
                  {isButtonVisible && (
                    <ChangeDietBtn setAddDietShow={setAddDietShow} />
                  )}
                  <DietTemplateCoach
                    setIsButtonVisible={setIsButtonVisible}
                    setScreen={setScreen}
                    selectedAssessment={selectedAssessment}
                    userDetails={userDetails}
                    setEnableSwitch={setEnableSwitch}
                    setToggle={setToggle}
                  />
                </>
              ) : (
                <AddDietContainer
                  setAddDietShow={setAddDietShow}
                  setScreen={setScreen}
                  selectedAssessment={selectedAssessment}
                  userDetails={userDetails}
                  setEnableSwitch={setEnableSwitch}
                  setToggle={setToggle}
                />
              )}
            </div>
          )}

          {enableSwitch && (
            // <div style={{ width: '50%', overflowX: 'auto', boxShadow: '1px 1px 10px 5px rgb(241, 239, 239)' }}>
            //     <button className="generate-report-btn">Generate Report</button>
            // </div>
            <div
              style={{
                width: "50%",
                overflow: "auto",
                boxShadow: "1px 1px 10px 5px rgb(241, 239, 239)",
                height: "1000px",
              }}
            >
              <div
                style={{
                  color: "#8129B9",
                  fontSize: "18px",
                  marginTop: "10px",
                  fontWeight: "700",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Generate Report
              </div>
              <PatientReport
                patient_id={params.id || params?.patientId}
                userInfo={userDetails}
                reportPeiodDates={reportPeiodDates}
                selectedDietPlan={selectedDietPlan}
                fetchDietData={fetchDietData}
                setSelectedDietPlan={setSelectedDietPlan}
                setReportPeriodDates={setReportPeriodDates}
              />
            </div>
          )}
        </div>
      )}

      {screen === 2 && (
        <div className="diet-main-component">
          <div className="diet-component">
            <div className="page-header">
              <div style={{ display: "flex", gap: "10px" }}>
                {/* <ChangeDietBtn /> */}
                <ReactSelect
                  classNamePrefix="select"
                  name="color"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: "#F0E7F7",
                      primary: "#760fb2",
                    },
                  })}
                  options={[
                    { label: "Pending", value: "pending" },
                    { label: "Rejected", value: "rejected" },
                    { label: "Assigned", value: "assigned" },
                    { label: "Drafts", value: "draft" },
                    { label: "All", value: "all" },
                  ]}
                  onChange={(selectedOption: any) => {
                    setSelectedFilter(selectedOption?.value);
                  }}
                  value={[
                    { label: "Pending", value: "pending" },
                    { label: "Rejected", value: "rejected" },
                    { label: "Assigned", value: "assigned" },
                    { label: "Drafts", value: "draft" },
                    { label: "All", value: "all" },
                  ].find((val) => val.value == selectedFilter)}
                  styles={customStyles}
                  placeholder="Select Diet Type"
                />
              </div>

              <DatePicker
                //value={dayjs(selectedDate, 'YYYY-MM-DD')}
                format={"YYYY-MM-DD"}
                onChange={(moment, date) => {
                  setSelectedDate(date);
                }}
              />
            </div>

            {/* <div className="diet-grid-component">
                            <DietAndAdherence patient_id={params.id || params?.patientId} selectedDate={selectedDate} selectedFilter={selectedFilter} />
                            <DietPreference />
                            <div>
                                <BarChart patient_id={params.id || params?.patientId} />
                            </div>
                        </div> */}
            <div style={{ width: "97%", marginTop: "20px" }}>
              <DietPlanList
                patient_id={params.id || params?.patientId}
                selectedDate={selectedDate}
                selectedFilter={selectedFilter}
                userDetails={userDetails}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeScreen;
