import React, { useEffect, useRef } from "react";
import { Select } from "antd"; // Importing AntD Select component for dropdown
import "./Table.scss";

import Button from "../Button/Button";
import Loader from "../Loader";

import { TABLE } from "../../constants/common";

interface Column {
  label?: string;
  header?: (column: Column) => React.ReactNode;
  accessor: string;
  render?: (value: any, row: any, rowIndex: number) => React.ReactNode;
}

interface Props {
  data: Array<Record<string, any>>;
  columns: Array<Column>;
  showPagination?: boolean;
  totalRecords?: number; // Total records for pagination
  pageSize?: number; // How many records per page
  currentPage?: number; // Current page
  onPageChange?: (page: number) => void; // Callback for page change
  onPageSizeChange?: (pageSize: number) => void; // Callback for page size change
  isLoading?: boolean; // Is data being fetched
  border?: boolean;
  errorMessage?: string;
  onScroll?: (scrollData: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
  }) => void;
  onRowClick?: (row: any) => void;
}

const Table: React.FC<Props> = ({
  data,
  columns,
  showPagination = false,
  totalRecords = 0,
  pageSize = 10,
  currentPage = 1,
  onPageChange = () => {},
  onPageSizeChange = () => {},
  isLoading = false,
  border = false,
  onScroll,
  errorMessage = "",
  onRowClick,
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePageSizeChange = (value: number) => {
    onPageSizeChange(value); // Notify parent component about page size change
  };

  const pageNumbers = [1];
  for (let i = 2; i <= Math.min(totalPages, 10); i++) {
    // Show maximum of 5 page numbers
    pageNumbers.push(i);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleGoToPage = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handleScroll = () => {
    if (containerRef.current && onScroll) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      onScroll({ scrollLeft, scrollWidth, clientWidth });
    }
  };

  return (
    <div
      className="table-container-custom"
      ref={containerRef}
      onScroll={handleScroll}
    >
      <table className="table" border={border ? 1 : 0}>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.header ? col.header(col) : col.label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data?.length > 0 ? (
            data.map((rowData, rowIndex) => (
              <tr key={rowIndex} onClick={() => onRowClick?.(rowData)}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {col.render
                      ? col.render(
                          rowData[col.accessor],
                          {
                            index: rowIndex,
                            ...rowData,
                          },
                          rowIndex
                        )
                      : rowData[col.accessor] || "-"}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="no-data">
                {errorMessage || "No data available"}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls (Modified for Image Matching) */}
      {/* {totalPages > 1 && ( */}
      {showPagination && (
        <div className="pagination">
          <div>
            <div className="records-selection">
              <div>Items per page</div>
              <div>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  options={TABLE.PAGINATION_OPTIONS}
                  style={{ width: 80 }}
                  disabled={data?.length === 0}
                />
              </div>

              <div className="page-indicator">
                {/* Items per page  25  1-25 of 500 items */}
                <span>
                  showing{" "}
                  {Math.min((currentPage - 1) * pageSize + 1, totalRecords)} -{" "}
                  {Math.min(currentPage * pageSize, totalRecords)} of{" "}
                  {totalRecords} items
                </span>
              </div>
            </div>
          </div>
          <div className="btn-container">
            <Button
              className="btn-next"
              variant="outlined"
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
            >
              Prev
            </Button>
            {pageNumbers.map((pageNumber) => (
              <Button
                variant={currentPage === pageNumber ? "primary" : "outlined"}
                key={pageNumber}
                className={`btn-page-number`}
                onClick={() => handleGoToPage(pageNumber)}
              >
                {pageNumber}
              </Button>
            ))}
            <Button
              className="btn-next"
              variant="outlined"
              disabled={currentPage === totalPages || data?.length === 0}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </div>
        </div>
      )}
      {/* )} */}

      {/* Loader overlay */}
      {isLoading && (
        <div className="loading-overlay">
          <Loader noBackground />
        </div>
      )}
    </div>
  );
};

export default Table;
