import React, { useState, useImperativeHandle, forwardRef } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./PatientProfileModal.scss";

import Popup from "../../../atoms/Popup/Popup";
import CurrentOfferings from "../CurrentOfferings/CurrentOfferings";

import { RootState } from "../../../store/store";
import { formatDateTime, getAgeByDob } from "../../../utils/date-and-time";
import { formatPrice } from "../../../utils/common";

interface Props {}

const PatientProfileModal = forwardRef<any, Props>((props, ref) => {
  const [showModal, setShowModal] = useState(false);
  const { patientDetails } = useSelector((state: RootState) => state.patient);

  useImperativeHandle(ref, () => ({
    showPatientDetails() {
      setShowModal(true);
    },

    hidePatientDetails() {
      setShowModal(false);
    },
  }));

  if (!showModal) return null;

  const patientGender =
    patientDetails?.data?.gender === "M"
      ? "Male"
      : patientDetails?.data?.gender === "F"
      ? "Female"
      : "Others";
  const patientAge = getAgeByDob(patientDetails?.data?.dob ?? null);

  return (
    <Popup
      show={true}
      headerText="Patient Profile"
      onClose={() => setShowModal(false)}
    >
      <div className="patient-profile-modal-container">
        <div className="custom-card">
          <div className="card-title">Patient Details</div>
          <div className="field-wrapper">
            <div className="field">
              <div className="field-label">User Name</div>
              <div className="field-value">{patientDetails?.data?.name}</div>
            </div>
            <div className="field">
              <div className="field-label">Mobile Number</div>
              <div className="field-value">
                {patientDetails?.data?.contact_no}
              </div>
            </div>
            <div className="field">
              <div className="field-label">Gender</div>
              <div className="field-value">{patientGender}</div>
            </div>
            <div className="field">
              <div className="field-label">Age</div>
              <div className="field-value">{patientAge} Years</div>
            </div>
            <div className="field">
              <div className="field-label">User Type</div>
              <div className="field-value">
                --
                {/* {patientDetails?.data?.user_type || "Corporate"} */}
              </div>
            </div>
          </div>
        </div>

        <div className="custom-card">
          <div className="card-title">Plan Details</div>
          <div className="field-wrapper">
            <div className="field">
              <div className="field-label">Plan Name</div>
              <div className="field-value">
                {patientDetails?.data?.plan_name}
              </div>
            </div>
            <div className="field">
              <div className="field-label">Plan Price</div>
              <div className="field-value">
                {formatPrice(patientDetails?.data?.plan_purchase_price)}
              </div>
            </div>
            <div className="field">
              <div className="field-label">Plan Start Date</div>
              <div className="field-value">
                {patientDetails?.data?.plan_start_datetime
                  ? formatDateTime({
                      timestamp:
                        patientDetails?.data?.plan_start_datetime ?? "",
                      format: 12,
                      showTime: false,
                    })
                  : ""}
              </div>
            </div>
            <div className="field">
              <div className="field-label">Plan End Date</div>
              <div className="field-value">
                {patientDetails?.data?.plan_expiry_datetime
                  ? formatDateTime({
                      timestamp:
                        patientDetails?.data?.plan_expiry_datetime ?? "",
                      format: 12,
                      showTime: false,
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>

        {patientDetails?.data?.current_offering?.offerings &&
          patientDetails.data.current_offering.offerings.length > 0 && (
            <div className="custom-card">
              <div className="card-title">Our Offerings</div>
              <CurrentOfferings
                offerings={patientDetails?.data?.current_offering?.offerings}
              />
            </div>
          )}
      </div>
    </Popup>
  );
});

export default React.memo(PatientProfileModal);
