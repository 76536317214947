import React from "react";
import "./CurrentOfferings.scss";
import Table from "../../../atoms/Table/Table";

// TEST: uncomment the following and remove the "offerings" from Props.
// const offerings = require("../../../db/offerings.json");

interface Props {
  offerings: Array<any>;
}

const CurrentOfferings: React.FC<Props> = ({ offerings }) => {
  const getOfferings = (offering: any) => {
    if (offering?.type === "Nutraceutical") {
      const offerings = offering.items.map(
        (item: any) =>
          item.quantity > 0 &&
          "product_name" in item.meta_data &&
          item.meta_data.product_name
      );

      return offerings?.filter(Boolean).join(", ");
    } else if (offering?.type === "Devices") {
      const devices = offering?.items?.map(
        (item: any) => item?.quantity > 0 && item?.meta_data?.device_name
      );

      return devices?.filter(Boolean).join(", ");
    } else if (offering?.type === "HealthCoach Session") {
      const healthCoaches = offering?.items?.map(
        (item: any) => item?.quantity > 0 && item?.meta_data?.healthCoachType
      );

      return healthCoaches?.filter(Boolean).join(", ");
    } else if (offering?.type === "Diagnostic") {
      const diagnostics = offering?.items?.map(
        (item: any) => item?.quantity > 0 && item?.meta_data?.labTestName
      );

      return diagnostics?.filter(Boolean).join(", ");
    } else if (offering?.type === "Doctor Consultation") {
      const doctorConsultaions =
        offering?.meta_data?.bottom_sheet?.offering_breakdown?.map(
          (item: any) => item?.title
        );

      return doctorConsultaions?.filter(Boolean).join(", ");
    }
  };

  const offeringsWrapper = offerings
    ?.filter((offering: any) => offering?.type !== "Plan Meta Info")
    ?.map((offering: any) => ({
      name: offering?.type,
      offerings: getOfferings(offering),
    }));

  const columns = [
    {
      label: "Services",
      accessor: "name",
    },
    {
      label: "Details",
      accessor: "offerings",
    },
  ];

  return (
    <div className="offerings-container">
      <Table columns={columns} data={offeringsWrapper} />
    </div>
  );
};

export default CurrentOfferings;
