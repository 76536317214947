import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AssessmentWrapper.scss";

import ReactSelectWrapper from "../../../atoms/Select/ReactSelect/ReactSelectWrapper";
import { RootState } from "../../../store/store";
import {
  getAssessmentDiseaseRequest,
  getAssessmentsRequest,
  resetAssessmentDisease,
  resetAssessments,
} from "../../../store/slices/AssessmentSlice";
import DietAssessmentView from "../../DietAssessmentView/DietAssessmentView";
import Spinner from "../../../atoms/Spinner/Spinner";

const AssessmentWrapper: React.FC<any> = () => {
  const [selectedDisease, setSelectedDisease] = useState<any>(null);
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);

  const { assessmentDisease, assessments } = useSelector(
    (state: RootState) => state.assessment
  );
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const dispatch = useDispatch();

  useEffect(() => {
    getAssessmentDetails();

    return () => {
      dispatch(resetAssessmentDisease());
      dispatch(resetAssessments());
    };
  }, []);

  useEffect(() => {
    if (assessmentDisease?.data?.length) {
      const selectedDisease = assessmentDisease.data.find(
        (disease) => disease.label === patientDetails?.data?.disease
      );
      setSelectedDisease(selectedDisease);
    }
  }, [patientDetails?.data, assessmentDisease]);

  useEffect(() => {
    if (selectedDisease && patientDetails?.data) {
      const payload = {
        assessment_type: "diet",
        disease_type: selectedDisease.value,
        patient_id: patientDetails?.data?.patient_id,
        // disease_type: "Breast Cancer",
        // patient_id: "1a3fd713-8b9b-11ef-aaea-0022486e34ba",
        read_only: true,
      };

      new Promise((resolve, reject) => {
        dispatch(
          getAssessmentsRequest({
            payload,
            resolve,
            reject,
          })
        );
      });
    }
  }, [selectedDisease, patientDetails?.data]);

  useEffect(() => {
    if (patientDetails?.data) {
      setSelectedDisease(patientDetails?.data?.disease);
    }
  }, [patientDetails?.data]);

  useEffect(() => {
    if ((assessments?.data?.length ?? 0) > 0) {
      const selectedAssessment =
        assessments?.data && assessments.data[assessments.data.length - 1];
      setSelectedAssessment({
        ...selectedAssessment,
        label: selectedAssessment?.assessment_name,
        value: selectedAssessment?.patient_assessment_id,
        // value: `${index}_${selectedAssessment?.assessment_name}`,
      });
    }
  }, [assessments?.data]);

  const getAssessmentDetails = () => {
    new Promise((resolve, reject) => {
      dispatch(
        getAssessmentDiseaseRequest({
          resolve,
          reject,
        })
      );
    });
  };

  return (
    <div className="assessment-container">
      <div className="title">Assessment</div>
      <div className="filters-wrapper">
        <ReactSelectWrapper
          label="Select Disease"
          options={assessmentDisease?.data?.map((disease) => ({
            label: disease?.label ?? "",
            value: disease?.value ?? "",
          }))}
          value={selectedDisease}
          onChange={(selectedDisease: any) =>
            setSelectedDisease(selectedDisease)
          }
          // disabled={assessmentDisease.isLoading}
          // loading={assessmentDisease.isLoading}
        />

        <ReactSelectWrapper
          label="Select Assessment"
          options={assessments?.data?.map((assessment, index) => ({
            ...assessment,
            label: assessment?.assessment_name,
            value: assessment.patient_assessment_id,
            // value: `${index}_${assessment?.assessment_name}`,
          }))}
          onChange={(selectedAssessment: any) =>
            setSelectedAssessment(selectedAssessment)
          }
          value={selectedAssessment}
          // disabled={assessments.isLoading}
          // loading={assessments.isLoading}
        />
      </div>

      {assessments.isLoading ? (
        <div className="assessment-container-loader">
          <Spinner />
        </div>
      ) : assessments.errors ? (
        <div className="assessment-container-error">
          <div className="error-msg">{assessments.errors}</div>
        </div>
      ) : (
        <div>
          <DietAssessmentView
            ifHomeScreen={"assessmentForm"}
            showCloseButton={false}
            selectedAssessment={selectedAssessment}
            patient_id={patientDetails?.data?.patient_id}
            enableEdit={false}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentWrapper;
