import React from "react";
import ScoreProgress from "../../../molecules/Progress/ScoreProgress/ScoreProgress";
import ProgressTabs from "../../../molecules/Progress/ProgressTabs/ProgressTabs";

const tabs = ["Exercise", "Diet", "Success"];

const MainGoalsPage = () => {
  return (
    <div>
      {/* <ScoreProgress /> */}
      {/* <AssessmentGoal tabs={tabs} /> */}
      <ProgressTabs tabs={tabs} isPrimary={false} />
    </div>
  );
};

export default MainGoalsPage;
