import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./PatientLayout.scss";

import UserProfileCard from "../../../molecules/PatientDetailsV2/UserProfileCard/UserProfileCard";
import Spinner from "../../../atoms/Spinner/Spinner";
import Button from "../../../atoms/Button/Button";

import { RootState } from "../../../store/store";
import {
  getPatientDetailsRequest,
  resetPatientDetails,
} from "../../../store/slices/PatientSlice";
import {
  getHealthCoachDetailsRequest,
  resetHealthCoachDetails,
} from "../../../store/slices/HealthCoachSlice";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

const PatientLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { patientDetails } = useSelector((state: RootState) => state.patient);
  const { healthCoachDetails } = useSelector(
    (state: RootState) => state.healthCoach
  );

  const tabs = [
    { label: "Progress", path: "progress" },
    { label: "Assign Tasks", path: "assign-tasks" },
    { label: "Vitals", path: "vitals" },
    { label: "Records", path: "records" },
  ];

  const activeTab =
    tabs.find((tab) => location.pathname.includes(tab.path))?.path ||
    "progress";

  const leftMenu = {
    progress: [
      { label: "Main Goals", path: "progress/main-goals" },
      // { label: "Success", path: "progress/success-coach" },
      { label: "Custom Goals", path: "progress/custom-goals" },
    ],
    "assign-tasks": [
      ...(healthCoachDetails?.data?.role === "Physiotherapist"
        ? [
            { label: "Exercise", path: "assign-tasks/exercise" },
            // {
            //   label: "Exercise",
            //   path: "assign-tasks/exercise",
            //   submenu: [
            //     {
            //       label: "Exercise Plan",
            //       path: "assign-tasks/exercise/exercise-plan",
            //     },
            //     {
            //       label: "Custom Plan",
            //       path: "assign-tasks/exercise/custom-plan",
            //     },
            //   ],
            // },
          ]
        : []),
      ...(healthCoachDetails?.data?.role === "Nutritionist"
        ? [
            {
              label: "Diet",
              path: "assign-tasks/diet",
              submenu: [
                {
                  label: "List of Diet Plans",
                  path: "assign-tasks/diet/diet-plans",
                },
                {
                  label: "Reports",
                  path: "assign-tasks/diet/reports",
                },
              ],
            },
          ]
        : []),
      { label: "Steps", path: "assign-tasks/steps" },
      { label: "Water Intake", path: "assign-tasks/water-intake" },
      { label: "Sleep", path: "assign-tasks/sleep" },
      {
        label: "Incentive Spirometer",
        path: "assign-tasks/incentive-spirometer",
      },
    ],
  };

  useEffect(() => {
    console.log("Mounting");
    // setIsLoading(true);
    if (params.token) {
      const patient_id: any = params.id || params?.patientId;
      localStorage.setItem("patient_id", patient_id);
      const health_coach_id: any = params.id || params?.healthCoachId;
      localStorage.setItem("health_coach_id", health_coach_id);
      const token: any = params.token;
      localStorage.setItem("token", token);

      console.log("Navigating to progress/main-goals", location, params);
      navigate(
        `/patient-details-v2/${patient_id}/${health_coach_id}/progress/main-goals`
      );
    } else {
      if (location.pathname) {
        const activeTab =
          tabs.find((tab) => location.pathname.includes(tab.path))?.path ||
          "progress";

        const activeMenuFromPath = Object.keys(leftMenu).find((key) =>
          location.pathname.includes(key)
        );
        if (activeMenuFromPath) {
          // setActiveLeftMenu(activeMenuFromPath);
          // Find the active submenu based on the path
          // @ts-ignore
          const activeSubMenu = leftMenu[activeMenuFromPath].find((menu) =>
            location.pathname.includes(menu.path)
          );
          if (activeSubMenu?.submenu) {
            setSubMenuOpen(activeSubMenu.path); // Open the submenu for the active menu
          } else {
            setSubMenuOpen(null); // No submenu, close it
          }
        }
      }
    }
  }, [location.pathname, params]);

  useEffect(() => {
    if (params?.id || params?.patientId) {
      if (!patientDetails?.data && !patientDetails?.errors) {
        getPatientDetails();
        getHealthCoachDetails();
      } else if (patientDetails?.errors) {
        console.error(
          "Error while fetching patient details",
          patientDetails?.errors
        );
      }
    }

    return () => {
      dispatch(resetPatientDetails());
      dispatch(resetHealthCoachDetails());
    };
  }, []);

  useEffect(() => {
    if (patientDetails?.data) {
      localStorage.setItem(
        "PatientInfo",
        JSON.stringify({
          ...patientDetails?.data,
          disease: (patientDetails?.data as any)?.disease || "",
          medicalCondition: patientDetails?.data?.medical_condition_name || "",
        })
      );
    }
  }, [patientDetails]);

  useEffect(() => {
    if (patientDetails?.data && healthCoachDetails?.data) {
      setIsLoading(false);
    }
  }, [patientDetails?.data, healthCoachDetails?.data]);

  const getPatientDetails = () => {
    const patient_id: any = params.id || params?.patientId;
    const health_coach_id: any = params.id || params?.healthCoachId;
    const payload = {
      patient_id: patient_id,
      health_coach_id: health_coach_id,
    };
    new Promise((resolve, reject) => {
      dispatch(
        getPatientDetailsRequest({
          payload,
          resolve,
          reject,
        })
      );
    });
  };

  const getHealthCoachDetails = () => {
    new Promise((resolve, reject) => {
      dispatch(
        getHealthCoachDetailsRequest({
          // payload,
          resolve,
          reject,
        })
      );
    });
  };

  if (patientDetails?.errors || healthCoachDetails?.errors) {
    return (
      <div className="layout-container-error">
        <div className="error-msg flex-container">
          Error loading details. Please try again.
          <Button
            variant="primary"
            onClick={() => {
              // setIsLoading(true);
              // getPatientDetails();
              // getHealthCoachDetails();
              window.location.reload();
            }}
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="layout-container-loader">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="layout-container">
      <UserProfileCard />
      <div>
        <div className="tabs-wrapper">
          <ul className="tabs-container">
            {tabs.map((tab) => (
              <li key={tab.path}>
                <button
                  className={`tab ${activeTab === tab.path && "active-tab"}`}
                  onClick={() => {
                    // @ts-ignore
                    if (leftMenu[tab.path]?.length > 0) {
                      // @ts-ignore
                      if (leftMenu[tab.path][0]?.submenu?.length > 0) {
                        // @ts-ignore
                        navigate(leftMenu[tab.path][0].submenu[0].path);
                      } else {
                        // @ts-ignore
                        navigate(leftMenu[tab.path][0].path);
                      }
                    } else {
                      navigate(tab.path);
                    }
                  }}
                >
                  {tab.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="content-container">
        {/* Sidebar */}
        {/* @ts-ignore */}
        {leftMenu[activeTab]?.length > 0 && (
          <div className="sidebar">
            <ul className="menu-container">
              {/* @ts-ignore */}
              {leftMenu[activeTab]?.map((menu: any) => (
                <li className="menu-item" key={menu.path}>
                  <button
                    className={`menu-item-button ${
                      (location.pathname.includes(menu.path) ||
                        subMenuOpen === menu.path) &&
                      "active-menu"
                    }`}
                    onClick={() => {
                      if (menu?.submenu?.length > 0) {
                        setSubMenuOpen(
                          subMenuOpen === menu?.path ? null : menu.path
                        );
                      } else {
                        navigate(menu.path);
                      }
                    }}
                  >
                    {menu.label}{" "}
                    {menu?.submenu &&
                      (subMenuOpen === menu?.path ? (
                        <UpOutlined />
                      ) : (
                        <DownOutlined />
                      ))}
                  </button>
                  {subMenuOpen === menu?.path &&
                    menu?.submenu &&
                    menu?.submenu?.length > 0 && (
                      <ul className="submenu-container">
                        {menu?.submenu?.map((subMenu: any) => (
                          <li className="submenu-item" key={subMenu.path}>
                            <button
                              className={`submenu-item-button ${
                                location.pathname.includes(subMenu.path) &&
                                "active-submenu"
                              }`}
                              onClick={() => {
                                navigate(subMenu.path);
                              }}
                            >
                              {subMenu.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PatientLayout;
