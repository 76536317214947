// src/store/slices/assessmentSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { assessment } from "../../types/index";

// Initial state for the assessment slice
const initialState: assessment.AssessmentStateType = {
  assessments: {
    isLoading: false,
    data: null,
    errors: null,
  },
  assessmentDisease: {
    isLoading: false,
    data: null,
    errors: null,
  },
};

// Define the assessment slice
const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    getAssessmentDiseaseRequest: (
      state: assessment.AssessmentStateType,
      action: PayloadAction<any>
    ) => {
      state.assessmentDisease.isLoading = true;
      state.assessmentDisease.data = null;
      state.assessmentDisease.errors = "";
    },
    getAssessmentDiseaseSuccess: (
      state: assessment.AssessmentStateType,
      action: PayloadAction<any>
    ) => {
      state.assessmentDisease.isLoading = false;
      state.assessmentDisease.data = action.payload.data;
      state.assessmentDisease.errors = null;
    },
    getAssessmentDiseaseError: (
      state: assessment.AssessmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.assessmentDisease.isLoading = false;
      state.assessmentDisease.data = null;
      state.assessmentDisease.errors = error;
    },
    resetAssessmentDisease: (
      state: assessment.AssessmentStateType,
    ) => {
      state.assessmentDisease.isLoading = false;
      state.assessmentDisease.data = null;
      state.assessmentDisease.errors = null;
    },

    getAssessmentsRequest: (
      state: assessment.AssessmentStateType,
      action: PayloadAction<any>
    ) => {
      state.assessments.isLoading = true;
      state.assessments.data = null;
      state.assessments.errors = "";
    },
    getAssessmentsSuccess: (
      state: assessment.AssessmentStateType,
      action: PayloadAction<any>
    ) => {
      state.assessments.isLoading = false;
      state.assessments.data = action.payload.data;
      state.assessments.errors = null;
    },
    getAssessmentsError: (
      state: assessment.AssessmentStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      state.assessments.isLoading = false;
      state.assessments.data = null;
      state.assessments.errors = error;
    },
    resetAssessments: (
      state: assessment.AssessmentStateType,
    ) => {
      state.assessments.isLoading = false;
      state.assessments.data = null;
      state.assessments.errors = null;
    },
  },
});

// Export actions and reducer
export const {
  getAssessmentDiseaseRequest,
  getAssessmentDiseaseSuccess,
  getAssessmentDiseaseError,
  resetAssessmentDisease,

  getAssessmentsRequest,
  getAssessmentsSuccess,
  getAssessmentsError,
  resetAssessments,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
