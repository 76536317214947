import React, { useEffect, useState } from "react";
import ChangeDietBtn from "../../atoms/ChangeDiet-btn/ChangeDiet-btn";
import DietAndAdherence from "../../molecules/Diet&Adherence/Diet&Adherence";
import DietPreference from "../../molecules/DietPreference/DietPreference";
import AdherenceGraph from "../../atoms/Adherence-Graph/Adherence-Graph";
import "./PatientListScreen.scss";
import BarChart from "../../atoms/Adherence-Graph/Adherence-Graph";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Modal, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import Loader from "../../atoms/Loader";
import HealthCoachListing from "../../molecules/HealthCoachListing/HealthCoachListing";
import DashboardAppointments from "../../molecules/DashboardAppointments/DashboardAppointments";
import { BackIcon } from "../../assets/icons";
import DietIcon from "../../assets/icons/diet-plan.png";
import AssessmentIcon from "../../assets/icons/assessment.png";
import ReportIcon from "../../assets/icons/report.png";

import API from "../../services/rest/api";
import AddDietContainer from "../AddDietPlan/AddDietPlan";
import PendingDietReportApprovalList from "../../molecules/PendingDietReportApprovalList/PendingDietReportApprovalList";
import DietAssessmentView from "../../molecules/DietAssessmentView/DietAssessmentView";
import SearchIcon from "../../assets/icons/search_icon.png";
import ExerciseAssessmentView from "../../molecules/ExerciseAssessmentView/ExerciseAssessmentView";
import PendingExerciseReportApprovalList from "../../molecules/PendingExerciseReportApprovalList/PendingExerciseReportApprovalList";

type PatientType = {
  patient_id: string;
  name: string;
  age: number;
  gender: string;
  medical_condition_name: string;
  last_active: string;
  days_to_plan_expiry: number;
  plan_name: string;
  expiry_date: string;
  last_appointment: string;
};

const PatientListScreen = () => {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientList, setPatientList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(1);

  const [showDietModal, setShowDietModal] = useState<any>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
  const [latestDietForApprove, setLatestDietForApprove] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [showReportModal, setShowReportModal] = useState<any>(false);

  const [showAssessmentModal, setShowAssessmentModal] = useState<any>(false);
  const [patientDetails, setPatientDetails] = useState<any>(null);
  const [assessmentOptions, setAssessmentOptions] = useState<any>([]);
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);
  const [patientAssessments, setPatientAssessments] = useState<any>([]);
  const [searchPatient, setSearchPatient] = useState<any>(null);

  const [diseaseOptions, setDiseaseOptions] = useState<any>([]);
  const [selectedDisease, setSelectedDisease] = useState<any>(null);
  const [disableUpdateButton, setDisableUpdateButton] = useState<any>(false);

  // console.log(location)

  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  useEffect(() => {
    let hcId: any = params?.hcId;
    localStorage.setItem("health_coach_id", hcId);
  }, [params]);

  useEffect(() => {
    getPatientList();
  }, [activeTab, searchPatient]);

  useEffect(() => {
    if (location?.state) {
      setActiveTab(
        location?.state?.type === "active"
          ? 1
          : location?.state?.type === "inactive"
          ? 2
          : location?.state?.type === "pending_plan"
          ? 3
          : 4
      );
    }
  }, [location?.state]);

  useEffect(() => {
    if (patientDetails) {
      getAllDiseases();
      getPatientDietPlans();
    }
  }, [patientDetails]);

  useEffect(() => {
    if (showDietModal || showReportModal || showAssessmentModal) {
      getPatientAssessments();
    }
  }, [selectedDisease, showDietModal, showReportModal, showAssessmentModal]);

  const onGoBack = () => {
    navigate(-1);
  };

  const redirectToPatientDetails = (patient: PatientType) => {
    // navigate(`/patient-details/${patient.patient_id}`)
    const urlParams = {
      messageType: "goToPatientDetails",
      patientId: patient.patient_id,
      health_coach_id: params?.hcId,
    };

    window.parent.postMessage(urlParams, "*");
  };

  const getPatientList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        per_page: "5000",
        page: "1",
        health_coach_id: params?.hcId,
        filter:
          activeTab === 1
            ? "active"
            : activeTab === 2
            ? "inactive"
            : activeTab === 3
            ? "pending_plan"
            : "pending_report",
        ...(searchPatient ? { search: searchPatient } : {}),
      };
      const patientsData = await API.GetPatientList(payload);
      if (patientsData?.code === "1") {
        setPatientList(patientsData?.data);
        setIsLoading(false);
      } else {
        setPatientList([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getPatientDietPlans = async () => {
    try {
      let payload = {
        patient_id: selectedPatientId,
        diet_type: "pending",
      };
      const patientDiets = await API.GetDietPlan(payload);
      if (patientDiets?.code === "1") {
        setLatestDietForApprove(patientDiets?.data[0]);
        setError(null);
      } else {
        setError("No any plan available for Approve!");
      }
    } catch (error) {
      setError("No any plan available for Approve!");
    }
  };

  const getPatientAssessments = async () => {
    try {
      let assessmentData = await API.GetPatientAssessments({
        patient_id: selectedPatientId,
        read_only: true,
        disease_type: selectedDisease,
        ...(location?.state?.hc_role === "Physiotherapist"
          ? { assessment_type: "exercise" }
          : { assessment_type: "diet" }),
      });
      if (assessmentData?.code === "1") {
        setPatientAssessments(assessmentData?.data);
        if (assessmentData?.data.length > 1) {
          let tempAssessmentData = assessmentData?.data?.map((data: any) => {
            return {
              ...data,
              label: data?.assessment_name,
              value: data?.patient_assessment_id,
            };
          });
          setAssessmentOptions(tempAssessmentData);
          setSelectedAssessment(
            assessmentData?.data[assessmentData?.data.length - 1]
          );
        } else {
          setSelectedAssessment(
            assessmentData?.data[assessmentData?.data.length - 1]
          );
        }
      } else {
        setPatientAssessments([]);
        setAssessmentOptions([]);
        setSelectedAssessment(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to get all diseases
  const getAllDiseases = async () => {
    try {
      const data = await API.GetDiseasesData();
      if (data?.code === "1") {
        setDiseaseOptions(data?.data);
      } else {
        setDiseaseOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openDietModal = (patient: any) => {
    localStorage.setItem("patient_id", patient?.patient_id);
    setShowDietModal(true);
    setSelectedPatientId(patient?.patient_id);
    setSelectedDisease(patient?.disease);
    setPatientDetails(patient);
  };

  const openReportModal = (patient: any) => {
    localStorage.setItem("patient_id", patient?.patient_id);
    setShowReportModal(true);
    setSelectedPatientId(patient?.patient_id);
    setSelectedDisease(patient?.disease);
    setPatientDetails(patient);
  };

  const openAssessmentModal = (patient: any) => {
    localStorage.setItem("patient_id", patient?.patient_id);
    setShowAssessmentModal(true);
    setSelectedPatientId(patient?.patient_id);
    setSelectedDisease(patient?.disease);
    setPatientDetails(patient);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "170px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  return (
    <>
      {isLoading && (
        <div className="loaderAbsolute">
          <Loader noBackground={true} />
        </div>
      )}
      <div className="patient-list-component">
        <div className="page-header">
          <div
            onClick={onGoBack}
            style={{ display: "flex", alignItems: "center" }}
          >
            <BackIcon className="back-icon" />
          </div>
          <span className="header-text">All Patients</span>

          <div
            style={{
              border: "1px solid lightgray",
              borderRadius: "5px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              width: "250px",
              justifyContent: "space-between",
              marginLeft: "auto",
            }}
          >
            <input
              name="patient_search"
              onChange={(e) => setSearchPatient(e.target.value)}
              style={{
                border: "none",
                height: "22px",
                outline: "none",
                paddingLeft: "5px",
                width: "200px",
              }}
              placeholder="search patient..."
              value={searchPatient}
            />
            <img
              src={SearchIcon}
              style={{ marginRight: "10px", width: "20px", height: "20px" }}
            />
          </div>
        </div>

        <div className="patient-tab">
          <div
            className={activeTab === 1 ? "active-tab" : "tab"}
            onClick={() => setActiveTab(1)}
          >
            Active
          </div>
          <div
            className={activeTab === 2 ? "active-tab" : "tab"}
            onClick={() => setActiveTab(2)}
          >
            InActive
          </div>
          <div
            className={activeTab === 3 ? "active-tab" : "tab"}
            onClick={() => setActiveTab(3)}
          >
            Pending Plan Approvals
          </div>
          <div
            className={activeTab === 4 ? "active-tab" : "tab"}
            onClick={() => setActiveTab(4)}
          >
            Pending Report Approvals
          </div>
        </div>

        <table className="table">
          <thead>
            <tr className="table-header-row">
              <th className="table-header-data">No.#</th>
              <th className="table-header-data">Name</th>
              <th className="table-header-data">Patient Id</th>
              <th className="table-header-data">Disease</th>
              <th className="table-header-data">Comorbidity</th>
              <th className="table-header-data">Last activity</th>
              <th className="table-header-data">Days to plan expiry</th>
              <th className="table-header-data">Current Plan</th>
              <th className="table-header-data">Weekly Adherence</th>
              <th className="table-header-data">Last Appointment</th>
              <th className="table-header-data">Action</th>
            </tr>
          </thead>
          <tbody>
            {patientList.map((patient: any, idx: any) => {
              return (
                <tr key={patient.patient_id || idx}>
                  <td className="table-body-data">{idx + 1}</td>
                  <td className="table-body-data">
                    <div className="patient-name-container">
                      <span
                        onClick={() => {
                          redirectToPatientDetails(patient);
                        }}
                        className="patient-name"
                      >
                        {patient.name}
                      </span>
                      <span className="patient-age-gender">
                        {patient.age}, {patient.gender}
                      </span>
                    </div>
                  </td>
                  <td className="table-body-data">{patient.patient_id}</td>
                  <td className="table-body-data">{patient?.disease}</td>
                  <td className="table-body-data">
                    {patient.medical_condition_name}
                  </td>
                  <td className="table-body-data">{patient.last_active}</td>
                  <td className="table-body-data">
                    {patient.days_to_plan_expiry}
                  </td>
                  <td className="table-body-data">{patient.plan_name}</td>
                  <td className="table-body-data">
                    <div className="adherence-details-container">
                      <span>Medication: {patient?.medication_adherence}%</span>
                      <span>Steps: {patient?.steps_adherence}%</span>
                      <span>Breathing: 0%</span>
                      <span>Exercise: {patient?.exercise_adherence}%</span>
                      <span>Diet: {patient?.diet_adherence}%</span>
                    </div>
                  </td>
                  {/* <td className="table-body-data">
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <button className="take-assessment-btn" onClick={() => navigate(`${location?.pathname}/assessment/${patient.id}/${token}`)}>
                                                    + Take Assessment
                                                </button>  
                                            </div>
                                        </td> */}
                  <td className="table-body-data">
                    {patient.last_appointment}
                  </td>
                  <td className="table-body-data">
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip placement="top" title={"View Assessment"}>
                        <img
                          src={AssessmentIcon}
                          alt="assessment_icon"
                          height={25}
                          width={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => openAssessmentModal(patient)}
                        />
                      </Tooltip>
                      {location?.state?.hc_role === "Nutritionist" && (
                        <Tooltip
                          placement="top"
                          title={"Pending Approval Diet"}
                        >
                          <img
                            src={DietIcon}
                            alt="diet_icon"
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            onClick={() => openDietModal(patient)}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        placement="top"
                        title={"Pending Approval Report"}
                      >
                        <img
                          src={ReportIcon}
                          alt="report_icon"
                          height={25}
                          width={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => openReportModal(patient)}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal
        width={"100%"}
        centered
        open={showDietModal}
        onOk={() => {
          setShowDietModal(false);
          getPatientList();
        }}
        onCancel={() => {
          setShowDietModal(false);
          setDisableUpdateButton(false);
          getPatientList();
        }}
        footer={null}
      >
        <div
          style={{
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {error ? (
            <div style={{ color: "red", fontSize: "20px" }}>{error}</div>
          ) : (
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "40%" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "200px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#760fb2",
                      }}
                    >
                      Select Disease
                    </span>
                    <ReactSelect
                      classNamePrefix="select"
                      name="color"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 6,
                        colors: {
                          ...theme.colors,
                          primary25: "#F0E7F7",
                          primary: "#760fb2",
                        },
                      })}
                      options={diseaseOptions}
                      onChange={(selectedOption: any) => {
                        setSelectedDisease(selectedOption?.value);
                      }}
                      value={diseaseOptions.find(
                        (val: any) => val.value == selectedDisease
                      )}
                      styles={customStyles}
                      placeholder="Select Disease"
                    />
                  </div>
                  {/* {patientAssessments?.length > 1 && */}
                  <div style={{ width: "300px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#760fb2",
                      }}
                    >
                      Select Assessment
                    </span>
                    <ReactSelect
                      classNamePrefix="select"
                      name="color"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 6,
                        colors: {
                          ...theme.colors,
                          primary25: "#F0E7F7",
                          primary: "#760fb2",
                        },
                      })}
                      options={assessmentOptions}
                      onChange={(selectedOption: any) => {
                        setSelectedAssessment(selectedOption);
                      }}
                      value={assessmentOptions.find(
                        (val: any) =>
                          val.value == selectedAssessment?.patient_assessment_id
                      )}
                      styles={customStyles}
                      placeholder="Select Assessment"
                    />
                  </div>
                  {/* } */}
                </div>
                <DietAssessmentView
                  selectedAssessment={selectedAssessment}
                  chief_hc={true}
                  patient_id={selectedPatientId}
                />
              </div>
              <div style={{ width: "60%" }}>
                <AddDietContainer
                  chief_hc={true}
                  latestDietForApprove={latestDietForApprove}
                  getPatientList={getPatientList}
                  setShowDietModal={setShowDietModal}
                  disableUpdateButton={disableUpdateButton}
                  setDisableUpdateButton={setDisableUpdateButton}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        width={"60%"}
        centered
        open={showReportModal}
        onOk={() => {
          setShowReportModal(false);
          getPatientList();
        }}
        onCancel={() => {
          setShowReportModal(false);
          getPatientList();
        }}
        footer={null}
      >
        <div
          style={{
            overflowY: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {location?.state?.hc_role === "Physiotherapist" ? (
            <PendingExerciseReportApprovalList
              selectedPatientId={selectedPatientId}
            />
          ) : (
            <PendingDietReportApprovalList
              selectedPatientId={selectedPatientId}
            />
          )}
        </div>
      </Modal>

      <Modal
        width={"60%"}
        centered
        open={showAssessmentModal}
        onOk={() => {
          setShowAssessmentModal(false);
        }}
        onCancel={() => {
          setShowAssessmentModal(false);
        }}
        footer={null}
      >
        <div
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#760FB2",
              fontSize: "24px",
              fontWeight: "700",
              marginBottom: "20px",
            }}
          >
            Patient Assessments
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "200px" }}>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#760fb2",
                }}
              >
                Select Disease
              </span>
              <ReactSelect
                classNamePrefix="select"
                name="color"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary25: "#F0E7F7",
                    primary: "#760fb2",
                  },
                })}
                options={diseaseOptions}
                onChange={(selectedOption: any) => {
                  setSelectedDisease(selectedOption?.value);
                }}
                value={diseaseOptions.find(
                  (val: any) => val.value == selectedDisease
                )}
                styles={customStyles}
                placeholder="Select Disease"
              />
            </div>
            {patientAssessments?.length > 1 && (
              <div style={{ width: "300px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#760fb2",
                  }}
                >
                  Select Assessment
                </span>
                <ReactSelect
                  classNamePrefix="select"
                  name="color"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: "#F0E7F7",
                      primary: "#760fb2",
                    },
                  })}
                  options={assessmentOptions}
                  onChange={(selectedOption: any) => {
                    setSelectedAssessment(selectedOption);
                  }}
                  value={assessmentOptions.find(
                    (val: any) =>
                      val.value == selectedAssessment?.patient_assessment_id
                  )}
                  styles={customStyles}
                  placeholder="Select Assessment"
                />
              </div>
            )}
          </div>

          {location?.state?.hc_role === "Physiotherapist" ? (
            <ExerciseAssessmentView
              selectedAssessment={selectedAssessment}
              chief_hc={true}
              screen={"chief_hc_screen"}
              patientId={selectedPatientId}
              selectedDisease={selectedDisease}
            />
          ) : (
            <DietAssessmentView
              selectedAssessment={selectedAssessment}
              chief_hc={true}
              patient_id={selectedPatientId}
              selectedDisease={selectedDisease}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default PatientListScreen;
