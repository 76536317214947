import { all } from "redux-saga/effects";

import patientSaga from "./patientSaga";
import healthCoachSaga from "./healthCoachSaga";
import assessmentSaga from "./assessmentSaga";
import dietSaga from "./dietSaga";

function* rootSaga() {
  yield all([
    patientSaga(),
    healthCoachSaga(),
    assessmentSaga(),
    dietSaga()
  ]);
}

export default rootSaga;
