import React, { useState } from "react";
import "./VitalsPage.scss";

import Tabs from "../../../atoms/Tabs/Tabs";
import UserView from "../../../molecules/DietV2/DietViews/UserView";
import RedirectComponent from "../../../atoms/RedirectComponent/RedirectComponent";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const tabs = [
  {
    label: "Coach View",
    value: "MARKER_HISTORY",
  },
  {
    label: "User View",
    value: "USER_VIEW",
  },
];

const VitalsPage: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const { patientDetails } = useSelector((state: RootState) => state.patient);

  const handleTabChange = (activeTab: any) => {
    console.log(activeTab);
    setActiveTab(activeTab);
  };

  return (
    <div className="exercise-plan-page-container">
      <h2 className="page-title">Vitals/Marker History</h2>

      <div>
        <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={activeTab} />
      </div>

      <div className="exercise-plan-content">
        {activeTab === "USER_VIEW" ? (
          <UserView
            mobileNumber={patientDetails?.data?.contact_no}
            name={patientDetails?.data?.name}
            moduleName="progress"
          />
        ) : activeTab === "MARKER_HISTORY" ? (
          <RedirectComponent moduleName="vitals" />
        ) : null}
      </div>
    </div>
  );
};

export default VitalsPage;
