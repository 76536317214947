import React from "react";
import { format } from "date-fns";
import Table from "../../atoms/Table/Table";
import ChatActiveIcon from "../../assets/icons/chat_active.png";
import AssessmentIcon from "../../assets/icons/assessment.png";
import "./PatientTable.scss";

const PatientTable = ({ patients, onPatientClick }: any) => {
  const sendMessageToParent = (type: string, patient?: any) => {
    switch (type) {
      case "CHAT_CLICKED":
        window.parent.postMessage({ messageType: type }, "*");
        break;
      case "ASSESSMENT_CLICKED":
        window.parent.postMessage(
          { messageType: type, assessmentData: { patient } },
          "*"
        );
        break;
    }
  };

  const columns = [
    {
      label: "No.",
      accessor: "index",
      render: (_: any, row: any) => row.index + 1,
    },
    {
      label: "Patient Details",
      accessor: "name",
      render: (_: any, row: any) => (
        <div className="patient-info">
          <div className="name">{row.name}</div>
          <div className="details">
            {row.age} years, {row.gender === "M" ? "Male" : "Female"}
          </div>
        </div>
      ),
    },
    {
      label: "Phone No.",
      accessor: "contact_no",
    },
    {
      label: "Disease",
      accessor: "disease",
    },
    {
      label: "Indication",
      accessor: "medical_condition_name",
    },
    {
      label: "Success",
      accessor: "psychologist",
      render: (value: string) => value || "N/A",
    },
    {
      label: "Diet",
      accessor: "nutritionist",
      render: (value: string) => value || "N/A",
    },
    {
      label: "Exercise",
      accessor: "physiotherapist",
      render: (value: string) => value || "N/A",
    },
    {
      label: "Doctor",
      accessor: "doctor_name",
      render: (value: string) => value || "N/A",
    },
    {
      label: "Last Activity",
      accessor: "last_active",
      render: (value: string) => (
        <div className="last-active-info">
          <div>{format(new Date(value), "dd MMM yyyy")}</div>
          <div className="timestamp">{format(new Date(value), "HH:mm")}</div>
        </div>
      ),
    },
    {
      label: "Plan Expiry",
      accessor: "days_to_plan_expiry",
    },
    {
      label: "Current Plan",
      accessor: "plan_name",
      render: (_: any, row: any) => (
        <div className="plan-info">
          <div>{row.plan_name}</div>
          <div className="expiry">
            Expires: {format(new Date(row.expiry_date), "dd MMM yyyy")}
          </div>
        </div>
      ),
    },
    {
      label: "Actions",
      accessor: "actions",
      render: (_: any, row: any) => (
        <div className="action-buttons">
          <button
            className="action-btn chat"
            onClick={(e) => {
              e.stopPropagation();
              sendMessageToParent("CHAT_CLICKED");
            }}
          >
            <img
              src={ChatActiveIcon}
              style={{ width: "20px", height: "20px" }}
              alt="Chat"
              title="Chat"
            />
          </button>
          <button
            className="action-btn video"
            onClick={(e) => {
              e.stopPropagation();
              sendMessageToParent("ASSESSMENT_CLICKED", row);
            }}
          >
            <img
              src={AssessmentIcon}
              style={{ width: "20px", height: "20px" }}
              alt="Assessment"
              title="Assessment"
            />
          </button>
        </div>
      ),
    },
  ];

  const enhancedData = patients.map((patient: any, index: number) => ({
    ...patient,
    index,
  }));

  return (
    <div className="patient-table-wrapper">
      <Table
        data={enhancedData}
        columns={columns}
        border={false}
        onRowClick={(row: any) => {
          // Store patient info in localStorage
          localStorage.setItem(
            "PatientInfo",
            JSON.stringify({
              ...row,
              disease: row.disease,
              medicalCondition: row.medical_condition_name,
            })
          );
          onPatientClick(row.patient_id);
        }}
      />
    </div>
  );
};

export default PatientTable;
