export const TABLE = {
  DEFAULT_PAGINATION: 10,
  PAGINATION_OPTIONS: [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    // {
    //   label: "100",
    //   value: 100,
    // },
  ],
};

export const constants = {
  ERROR_MESSAGES: {
    SOMETHING_WENT_WRONG: "Something went wrong!",
  },
};
