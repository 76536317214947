import React from "react";
import { Route } from "react-router-dom";

import PatientLayout from "./PatientLayout/PatientLayout";
import MainGoalsPage from "./ProgressPages/MainGoalsPage";
import RecordsPage from "./RecordsPage/RecordsPage";
import PsychologistPage from "./ProgressPages/PsychologistPage";
import NutritionistPage from "./ProgressPages/NutritionistPage";
import SuccessCoachPage from "./ProgressPages/SuccessCoachPage";
import ExercisePlanPage from "./AssignTasks/ExercisePlanPage/ExercisePlanPage";
import CustomPlanPage from "./AssignTasks/CustomPlanPage/CustomPlanPage";
import VitalsPage from "./VitalsPage/VitalsPage";
import DietTaskPage from "./AssignTasks/DietTaskPage/DietTaskPage";
import DietReportPage from "./AssignTasks/DietReportPage/DietReportPage";
import StepsTaskPage from "./AssignTasks/StepsTaskPage/StepsTaskPage";
import WaterIntakeTaskPage from "./AssignTasks/WaterIntakeTaskPage/WaterIntakeTaskPage";
import SleepTaskPage from "./AssignTasks/SleepTaskPage/SleepTaskPage";
import CustomGoalsPage from "./ProgressPages/CustomGoalsPage";
import IncentiveSpirometerPage from "./AssignTasks/IncentiveSpirometerPage/IncentiveSpirometerPage";

const PatientDetailsRoutes = () => (
  <Route
    path="/patient-details-v2/:patientId/:healthCoachId"
    element={<PatientLayout />}
  >
    <Route path=":token" element={<PatientLayout />}></Route>
    <Route path="progress">
      <Route path="main-goals" element={<MainGoalsPage />} />
      <Route path="psychologist" element={<PsychologistPage />} />
      <Route path="nutritionist" element={<NutritionistPage />} />
      <Route path="success-coach" element={<SuccessCoachPage />} />
      <Route path="custom-goals" element={<CustomGoalsPage />} />
    </Route>

    <Route path="assign-tasks">
      <Route path="exercise" element={<ExercisePlanPage />} />
      <Route path="diet">
        <Route path="diet-plans" element={<DietTaskPage />} />
        <Route path="reports" element={<DietReportPage />} />
      </Route>
      {/* <Route path="diet" element={<DietTaskPage />} /> */}
      <Route path="steps" element={<StepsTaskPage />} />
      <Route path="water-intake" element={<WaterIntakeTaskPage />} />
      <Route path="sleep" element={<SleepTaskPage />} />
      <Route path="incentive-spirometer" element={<IncentiveSpirometerPage />} />
    </Route>

    <Route path="vitals" element={<VitalsPage />} />
    <Route path="records" element={<RecordsPage />} />
  </Route>
);

export default PatientDetailsRoutes;
