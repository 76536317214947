import React, { useEffect, useState } from "react";
import "./CustomGoalModal.scss";
import { Modal } from "antd";
import Input from "../../../../../atoms/Input/Input";
import Button from "../../../../../atoms/Button/Button";
import usePatientCustomGoals from "../../../../../hooks/usePatientCustomGoals";
import { useGoals } from "../../../../../hooks/useGoals";
// import usePatientCustomGoals from "../../../hooks/usePatientCustomGoals";

interface CustomGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  patientId: string;
  activeTab: string;
  isPrimary: boolean;
  fetchGoalsHandler?: any;
}

const CustomGoalModal: React.FC<CustomGoalModalProps> = ({
  isOpen,
  onClose,
  patientId,
  activeTab,
  isPrimary,
  fetchGoalsHandler,
}) => {
  const {
    loading,
    error,
    searchQuery,
    setSearchQuery,
    filteredGoals,
    selectedGoals,
    handleGoalSelect,
    addPatientCustomGoals,
    isSuccess,
  } = usePatientCustomGoals(
    patientId,
    activeTab,
    onClose,
    isPrimary,
    fetchGoalsHandler
  );

  const handleAddGoals = () => {
    try {
      addPatientCustomGoals(selectedGoals);
      //   onClose();
    } catch (error) {
      console.error("Error adding goals:", error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} footer={null} onCancel={onClose}>
      <div className="custom-goal-modal">
        <h2>Add Custom Goal</h2>

        {loading && <div>Loading goals...</div>}
        {error && <div className="error">{error}</div>}

        {!loading && !error && (
          <>
            <Input
              type="text"
              id="goal-search"
              name="goal-search"
              placeholder="Search goals..."
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchQuery(e.target.value)
              }
            />
            <div className="goals-list">
              {filteredGoals.map((goal) => (
                <div key={goal.id} className="goal-item">
                  <label className="goal-checkbox-label">
                    <input
                      type="checkbox"
                      checked={selectedGoals.includes(goal.id.toString())}
                      onChange={() => handleGoalSelect(goal.id.toString())}
                    />
                    <span>{goal.name}</span>
                  </label>
                  {/* {goal.subGoals && goal.subGoals.length > 0 && (
                    <div className="sub-goals-list">
                      {goal.subGoals.map((subGoal) => (
                        <div key={subGoal.id} className="sub-goal-item">
                          <label className="sub-goal-checkbox-label">
                            <input
                              type="checkbox"
                              checked={selectedGoals.includes(
                                subGoal.id.toString()
                              )}
                              onChange={() =>
                                handleGoalSelect(subGoal.id.toString())
                              }
                            />
                            <span>{subGoal.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  )} */}
                </div>
              ))}
              {filteredGoals.length === 0 && !loading && <p>No goals found</p>}
            </div>
            {isSuccess && <p>Goals added successfully</p>}
            <div className="modal-actions">
              <Button
                variant="primary"
                onClick={handleAddGoals}
                disabled={selectedGoals.length === 0}
              >
                Add Goals
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CustomGoalModal;
