import React from "react";
import "./CompletionModal.scss";
import { Modal } from "antd";
import Button from "../../../../../atoms/Button/Button";

interface CompletionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const CompletionModal: React.FC<CompletionModalProps> = ({
  isOpen,
  onClose,
  onComplete,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} footer={null} onCancel={onClose}>
      <div className="completion-modal">
        <h2>Mark Goal as Complete Goal</h2>
        <p>
          Are you sure you want to mark this goal as completed. This action is
          not reversible and will be shown to the user once the next assessment
          is published. You can still provide tasks to the user related to this
          goal. If tracking is needed on the goal then a custom task be created
          for the same
        </p>
        <div className="modal-actions">
          <Button variant="primary" onClick={onComplete}>
            Mark as Completed
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompletionModal;
