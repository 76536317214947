import React, { useEffect, useState } from "react";
import "./App.css";
import HomeScreen from "./organisms/HomeScreen/HomeScreen";
import { Route, Routes, useParams } from "react-router-dom";
import AddDietContainer from "./organisms/AddDietPlan/AddDietPlan";
import PatientDetails from "./organisms/PatientDetails/PatientDetails";
import Dashboard from "./organisms/Dashboard/Dashboard";
import PatientListScreen from "./organisms/PatientListScreen/PatientListScreen";
import DietAssessmentForm from "./organisms/DietAssessmentForm/DietAssessmentForm";
import PatientReport from "./organisms/PatientReport/PatientReport";
import PatientReportView from "./organisms/PatientReportView/PatientReportView";
import PendingDietApprovalList from "./molecules/PendingDietApprovalList/PendingDietApprovalList";
import DietReportList from "./organisms/DietReportList/DietReportList";
import PendingDietReportApprovalList from "./molecules/PendingDietReportApprovalList/PendingDietReportApprovalList";
import ExerciseAssessmentForm from "./organisms/ExerciseAssessmentForm/ExerciseAssessmentForm";
import ExerciseAssessmentView from "./molecules/ExerciseAssessmentView/ExerciseAssessmentView";
import PatientExerciseReport from "./organisms/PatientExerciseReport/PatientExerciseReport";
import PageNotFound from "./organisms/PageNotFound/PageNotFound";
import PendingExerciseReportApprovalList from "./molecules/PendingExerciseReportApprovalList/PendingExerciseReportApprovalList";
import ExerciseReportList from "./organisms/ExerciseReportList/ExerciseReportList";
import NewDashboard from "./organisms/NewDashboard/NewDashboard";
import AllPatients from "./organisms/CgAllPatients/AllPatients";
import VerifyDocuments from "./organisms/CgVerifyDocuments/VerifyDocuments";
// import JourneyTable from './organisms/CgJourney/Journey';
import PatientSummary from "./organisms/CgPatientSummary/PatientSummary";
import CycleDetails from "./organisms/CgCycleDetails/CycleDetails";
import ViewDetials from "./organisms/CgViewDetails/ViewDetails";
import { ToastContainer } from "react-toastify";
import DietTemplateNew from "./molecules/DiatPlanTemplate";
import PatientScreen from "./organisms/Patient/PatientScreen";
import PatientDetailScreen from "./organisms/PatientDetail/PatientDetailScreen";
import { Provider } from "react-redux";
import { store } from "./store/store";
import PatientDetailsRoutes from "./organisms/PatientDetailsV2/patientDetailsRoutes";
import RecordsPageWrapper from "./organisms/PatientDetailsV2/RecordsPage/RecordsPageWrapper";
// import { SocketProvider } from "./context/socket/SocketContext";

function App() {
  return (
    <Provider store={store}>
      {/* <SocketProvider> */}
      <div className="App">
        <Routes>
          {/* <Route path="/:token" element={<Dashboard />} /> */}
          <Route path="/homescreen/:id/:token" element={<HomeScreen />} />
          <Route path="/patients/:hcId" element={<PatientListScreen />} />
          <Route path="/add-change-diet" element={<AddDietContainer />} />
          <Route
            path="/patient-details/:patientId"
            element={<PatientDetails />}
          />
          <Route
            path="/patient-detail/:patientId"
            element={<PatientDetailScreen />}
          />
          <Route path="/patient/:token" element={<PatientScreen />} />
          <Route
            path="/patients/:hcId/assessment/:patientId/:token"
            element={<DietAssessmentForm />}
          />
          <Route path="/report" element={<PatientReport />} />
          <Route path="/report/view" element={<PatientReportView />} />
          <Route
            path="/pending-diet-approvals/:token"
            element={<PendingDietApprovalList />}
          />
          <Route
            path="/pending-diet-report-approvals/:token"
            element={<PendingDietReportApprovalList />}
          />
          <Route
            path="/diet-report-list/:patientId/:token"
            element={<DietReportList />}
          />

          {/*Exercise Components*/}
          <Route
            path="/patients/:hcId/exercise-assessment/:patientId/:token"
            element={<ExerciseAssessmentForm />}
          />
          <Route
            path="/patients/:hcId/exercise-assessment-view/:patientId/:token"
            element={<ExerciseAssessmentView />}
          />
          <Route
            path="/patients/:hcId/exercise-report/:patientId/:token"
            element={<PatientExerciseReport />}
          />
          <Route
            path="/pending-exercise-report-approvals/:token"
            element={<PendingExerciseReportApprovalList />}
          />
          <Route
            path="/exercise-report-list/:patientId/:token"
            element={<ExerciseReportList />}
          />

          <Route path="/pagenotfound/error" element={<PageNotFound />} />

          <Route path="/newdashboard/:token" element={<NewDashboard />} />

          {/* Care Navigator */}
          <Route path="/all-patients/:token" element={<AllPatients />} />
          <Route
            path="/verify-documents/:token"
            element={<VerifyDocuments />}
          />
          <Route path="/patient-summary/:token" element={<PatientSummary />} />
          <Route path="/cycle-details/:token" element={<CycleDetails />} />
          <Route path="/view-details/:token" element={<ViewDetials />} />
          {/* <Route path="/create-journey" element={<CreateJourney journeys={journeys} setJourneys={setJourneys}/>} /> */}
          {/* <Route path="/journey/:token" element={<JourneyTable />} /> */}

          <Route
            path="/diet-template/:health_coach_id/:token"
            element={<DietTemplateNew />}
          />

          {/* TODO: Delete this route post goodlfip launch */}
          <Route
            path="/patient-details-v2/:patient_id/records/:token"
            element={<RecordsPageWrapper />}
          />

          {PatientDetailsRoutes()}
        </Routes>
        <ToastContainer />
      </div>
      {/* </SocketProvider> */}
    </Provider>
  );
}

export default App;
